import React from 'react';

function ContactAddress() {
  return (
    <div className="col-xl-5 col-lg-6 col-md-8">
      <div className="contact-info-wrap">
        <div className="contact-img">
          <img src="img/images/contact_img.png" alt="" />
        </div>
        <div className="contact-info-list">
          <ul>
            <li>
              <div className="icon"><i className="fas fa-map-marker-alt" /></div>
              <div className="content">
                <p>206/207 Heeranagar-A Near 200ft bypass, Service Lane, Jaipur, RJ</p>
              </div>
            </li>
            <li>
              <div className="icon"><i className="fas fa-phone-alt" /></div>
              <div className="content">
                <p>+91 94140-45241</p>
              </div>
            </li>
            <li>
              <div className="icon"><i className="fas fa-envelope-open" /></div>
              <div className="content">
                <p>maheshwarifurniturejpr@gmail.com</p>
              </div>
            </li>
          </ul>
        </div>

        <div className="contact-social">
          <ul>
            <li><a href="https://www.facebook.com/maheshwarifurniture" aria-label="facebook"><i className="fab fa-facebook-f" /></a></li>
            <li><a href="https://www.facebook.com/Maheshwari2019?mibextid=qWsEUC" aria-label="fa-flag"><i className="fas fa-flag" /></a></li>
            <li><a href="https://www.instagram.com/maheshwarifurniture.online" aria-label="instagram"><i className="fab fa-instagram" /></a></li>
            <li><a href="https://www.youtube.com/@maheshwarifurniture" aria-label="youtube"><i className="fab fa-youtube" /></a></li>
            <li><a href="https://wa.me/919414045241" aria-label="whatsapp"><i className="fab fa-whatsapp" /></a></li>
          </ul>
        </div>
      </div>
    </div>

  )
}

export default ContactAddress;
