import React from 'react';
import OfficeDeskBreadcumb from '../components/ourshop/OfficeFurnitureshopdetails/OfficeDeskBreadcumb'
import OfficeDeskArea from '../components/ourshop/OfficeFurnitureshopdetails/OfficeDeskArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function OfficeDeskPages() {
    return (
        <>
            <Header />
            <main>
                <OfficeDeskBreadcumb />
                <OfficeDeskArea />
            </main>
            <Footer />
        </>
    )
}

export default OfficeDeskPages; 