import React from 'react';
import RockingChairBreadcumb from '../components/ourshop/Comfortersshopdetails/RockingChairBreadcumb'
import RockingChairArea from '../components/ourshop/Comfortersshopdetails/RockingChairArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function RockingChairPages() {
    return (
        <>
            <Header />
            <main>
                <RockingChairBreadcumb />
                <RockingChairArea />
            </main>
            <Footer />
        </>
    )
}

export default RockingChairPages; 