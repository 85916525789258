import React from 'react';
import ShopBreadcumb from '../components/ourshop/ShopBreadcumb'
import DiningChairArea from '../components/ourshop/DiningChairArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function DiningChairPages() {
	return (
		<>
			<Header />
			<main>
				<ShopBreadcumb />
				<DiningChairArea />
			</main>
			<Footer />
		</>
	)
}

export default DiningChairPages;
