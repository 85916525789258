import React from 'react';
import SideAndEndTableBreadcumb from '../components/ourshop/SideTableshopdetails/SideAndEndTableBreadcumb'
import SideAndEndTableArea from '../components/ourshop/SideTableshopdetails/SideAndEndTableArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function SideAndEndTablePages() {
    return (
        <>
            <Header />
            <main>
                <SideAndEndTableBreadcumb />
                <SideAndEndTableArea />
            </main>
            <Footer />
        </>
    )
}

export default SideAndEndTablePages; 