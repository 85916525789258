import React from 'react';
import CushionedDiningChairDetailsArea from '../components/ourshop/Diningchair/CushionedDiningChairDetailsArea'
import CushionedDiningChairArea from '../components/ourshop/Diningchair/CushionedDiningChairArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function CushionedDiningChair() {
	return (
		<>
			<Header />
			<main>
				<CushionedDiningChairDetailsArea />
				<CushionedDiningChairArea />
			</main>
			<Footer />
		</>
	)
}

export default CushionedDiningChair;
