import React from 'react';
import OfficeTableWithThreeDrawersBreadcumb from '../components/ourshop/OfficeCountershopdetails/OfficeTableWithThreeDrawersBreadcumb'
import OfficeTableWithThreeDrawersArea from '../components/ourshop/OfficeCountershopdetails/OfficeTableWithThreeDrawersArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function OfficeTableWithThreeDrawersPages() {
    return (
        <>
            <Header />
            <main>
                <OfficeTableWithThreeDrawersBreadcumb />
                <OfficeTableWithThreeDrawersArea />
            </main>
            <Footer />
        </>
    )
}

export default OfficeTableWithThreeDrawersPages; 