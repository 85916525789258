import React from 'react';
import WoodenTablewithGlassattopHero from '../components/ourshop/centertable/WoodenTablewithGlassattopHero'
import WoodenTablewithGlassattopHome from '../components/ourshop/centertable/WoodenTablewithGlassattopHome'
import Header from "../components/Header";
import Footer from "../components/Footer";

function WoodenTablewithGlassattop() {
	return (
		<>
			<Header />
			<main>
				<WoodenTablewithGlassattopHome />
				<WoodenTablewithGlassattopHero />
			</main>
			<Footer />
		</>
	)
}

export default WoodenTablewithGlassattop;
