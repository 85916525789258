import React from 'react';
import StudyTablewithfourDrawersinery from '../components/ourshop/studytable/StudyTablewithfourDrawersinery'
import StudyTablewithfourDrawersaraey from '../components/ourshop/studytable/StudyTablewithfourDrawersaraey'
import Header from "../components/Header";
import Footer from "../components/Footer";

function StudyTablewithfourDrawers() {
	return (
		<>
			<Header />
			<main>
				<StudyTablewithfourDrawersaraey />
				<StudyTablewithfourDrawersinery />
			</main>
			<Footer />
		</>
	)
}

export default StudyTablewithfourDrawers;
