import React from 'react';
import HomeTempleBreadcumb from '../components/ourshop/Mandirshopdetails/HomeTempleBreadcumb'
import HomeTempleArea from '../components/ourshop/Mandirshopdetails/HomeTempleArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function HomeTemplePages() {
    return (
        <>
            <Header />
            <main>
                <HomeTempleBreadcumb />
                <HomeTempleArea />
            </main>
            <Footer />
        </>
    )
}

export default HomeTemplePages; 