import React from 'react';
import DiningChairBlueDetailsArea from '../components/ourshop/Diningchair/DiningChairBlueDetailsArea'
import DiningChairBlueArea from '../components/ourshop/Diningchair/DiningChairBlueArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function DiningChairBlue() {
	return (
		<>
			<Header />
			<main>
				<DiningChairBlueDetailsArea />
				<DiningChairBlueArea />
			</main>
			<Footer />
		</>
	)
}

export default DiningChairBlue;
