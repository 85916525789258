import React, { useEffect } from 'react';
import AdoptionBreadcumb from '../components/adoptions/AdoptionBreadcumb';
import AdoptionSlider from '../components/adoptions/AdoptionSlider'
import AdoptionGallery from '../components/adoptions/AdoptionGallery'
import AdoptionFaq from '../components/adoptions/AdoptionFaq'
import AdoptionPuppies from '../components/adoptions/AdoptionPuppies'
import Header from "../components/Header";
import Footer from "../components/Footer";

function AdoptionsPages() {
	useEffect(() => {
        document.title = 'About Maheshwari Furniture: Crafting Quality Furnishings for Your Home';
    }, []);
	return (
		<>
			<Header />
			<main>
				<AdoptionBreadcumb />
				<AdoptionSlider />
				<AdoptionGallery />
				<AdoptionFaq />
				<AdoptionPuppies />
			</main>
			<Footer />
		</>
	)
}

export default AdoptionsPages;
