import React from 'react';
import WoodenDividerShapesDetailsArea from '../components/ourshop/Partitions/WoodenDividerShapesDetailsArea'
import WoodenDividerShapesArea from '../components/ourshop/Partitions/WoodenDividerShapesArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function WoodenDividerShapes() {
	return (
		<>
			<Header />
			<main>
				<WoodenDividerShapesDetailsArea />
				<WoodenDividerShapesArea/>
			</main>
			<Footer />
		</>
	)
}

export default WoodenDividerShapes;
