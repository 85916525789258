import React from 'react';
import RevolvingLoungeChairBreadcumb from '../components/ourshop/Comfortersshopdetails/RevolvingLoungeChairBreadcumb'
import RevolvingLoungeChairArea from '../components/ourshop/Comfortersshopdetails/RevolvingLoungeChairArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function RevolvingLoungeChairPages() {
    return (
        <>
            <Header />
            <main>
                <RevolvingLoungeChairBreadcumb />
                <RevolvingLoungeChairArea />
            </main>
            <Footer />
        </>
    )
}

export default RevolvingLoungeChairPages; 