import React from 'react';
import SixSeaterDiningDetailsBreadcumb from '../components/ourshop/Diningshopdetails/SixSeaterDiningDetailsBreadcumb'
import SixSeaterDiningDetailsArea from '../components/ourshop/Diningshopdetails/SixSeaterDiningDetailsArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function SixSeaterDiningDetailsPage() {
    return (
        <>
            <Header />
            <main>
                <SixSeaterDiningDetailsBreadcumb />
                <SixSeaterDiningDetailsArea />
            </main>
            <Footer />
        </>
    )
}

export default SixSeaterDiningDetailsPage;