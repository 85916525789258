import React from 'react';
import MetalMasterTableBreadcumb from '../components/ourshop/IronFurnitureshopdetails/MetalMasterTableBreadcumb'
import MetalMasterTableArea from '../components/ourshop/IronFurnitureshopdetails/MetalMasterTableArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function MetalMasterTablePages() {
    return (
        <>
            <Header />
            <main>
                <MetalMasterTableBreadcumb />
                <MetalMasterTableArea />
            </main>
            <Footer />
        </>
    )
}

export default MetalMasterTablePages; 