import React from 'react';


function AboutUs() {
  return (
        
    <section className="container pb-100">
        <div className="row margin-bottom-shopify-development breeder-seo-tow-margin-top">
          <div className="col-lg-3 col-md-6 pt-0">
              <div
                  className="appie-single-counter fashion-trend mt-30 wow animated fadeInUp appie-single-counter-margin"
                  data-wow-duration="2000ms"
                  data-wow-delay="200ms"
                  >
                  <div className="counter-content">
                      <div className="icon">
                          <img src="img/icons/projects.png" alt="" />
                      </div>
                      <p className="breeder-seo-tow-font-weigh-color">Discuss your vision</p>
                  </div>
              </div>
          </div>
          <div className="col-lg-3 col-md-6 pt-0">
              <div
                  className="appie-single-counter fashion-trend mt-30 item-2 wow animated fadeInUp appie-single-counter-margin"
                  data-wow-duration="2000ms"
                  data-wow-delay="400ms"
                  >
                  <div className="counter-content">
                      <div className="icon">
                      <img src="img/icons/franchise.png" alt="" />
                      </div>
                      <p className="breeder-seo-tow-font-weigh-color">Choose the design</p>
                  </div>
              </div>
          </div>
          <div className="col-lg-3 col-md-6 pt-0">
              <div
                  className="appie-single-counter fashion-trend mt-30 item-5 wow animated fadeInUp appie-single-counter-margin"
                  data-wow-duration="2000ms"
                  data-wow-delay="600ms"
                  >
                  <div className="counter-content pt-40">
                      <div className="icon">
                      <img src="img/icons/team_members.png" alt="" />
                      </div>
                      <p className="breeder-seo-tow-font-weigh-color">Select the material</p>
                  </div>
              </div>
          </div>
          <div className="col-lg-3 col-md-6 pt-0">
              <div
                  className="appie-single-counter fashion-trend mt-30 item-4 wow animated fadeInUp appie-single-counter-margin"
                  data-wow-duration="2000ms"
                  data-wow-delay="800ms"
                  >
                  <div className="counter-content">
                      <div className="icon">
                      <img src="img/icons/happy_clients.png" alt="" />
                      </div>
                      <p className="breeder-seo-tow-font-weigh-color">Experience the look</p>
                  </div>
              </div>
          </div>
        </div>
    </section>
  )
}

export default AboutUs;
