import React from 'react';
import DressingTableWithDrawersDetailsArea from '../components/ourshop/Dressingtable/DressingTableWithDrawersDetailsArea'
import DressingTableWithDrawersArea from '../components/ourshop/Dressingtable/DressingTableWithDrawersArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function DressingTableWithDrawers() {
	return (
		<>
			<Header />
			<main>
				<DressingTableWithDrawersDetailsArea />
				<DressingTableWithDrawersArea />
			</main>
			<Footer />
		</>
	)
}

export default DressingTableWithDrawers;
