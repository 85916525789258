import React from 'react';
import ThreeSeaterSofaDetailsBreadcumb from '../components/ourshop/Sofashopdetails/ThreeSeaterSofaDetailsBreadcumb'
import ThreeSeaterSofaDetailsArea from '../components/ourshop/Sofashopdetails/ThreeSeaterSofaDetailsArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function ThreeSeaterSofaDetailsPage() {
    return (
        <>
            <Header />
            <main>
                <ThreeSeaterSofaDetailsBreadcumb />
                <ThreeSeaterSofaDetailsArea />
            </main>
            <Footer />
        </>
    )
}

export default ThreeSeaterSofaDetailsPage;