import React, { useEffect } from 'react';
import ContactBreadCumb from '../components/contacts/ContactBreadCumb'
import NewsLetter from '../components/NewsLetter'
import ContactForm from '../components/contacts/ContactForm'
import Header from "../components/Header";
import Footer from "../components/Footer";


function ContactPage() {
	useEffect(() => {
        document.title = 'Get in Touch with Maheshwari Furniture | Contact Information';
    }, []);
	return (
		<>
			<Header />
			<main>
				<ContactBreadCumb />
				<ContactForm />
				<NewsLetter />

			</main>
			<Footer />
		</>
	)
}

export default ContactPage;
