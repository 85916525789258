import React from 'react';
import DressingTableDetailsArea from '../components/ourshop/Dressingtable/DressingTableDetailsArea'
import DressingTableArea from '../components/ourshop/Dressingtable/DressingTableArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function DressingTable() {
	return (
		<>
			<Header />
			<main>
				<DressingTableDetailsArea />
				<DressingTableArea/>
			</main>
			<Footer />
		</>
	)
}

export default DressingTable;
