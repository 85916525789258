import React from "react";
import Slider from 'react-slick';
import { Link } from 'react-router-dom';

function Hero() {
  const settings = {
    autoplay: false,
    autoplaySpeed: 10000,
    dots: false,
    fade: true,
    arrows: true,

    responsive: [
      { breakpoint: 767, settings: { dots: false, arrows: false } }
    ]
  }

  return (
    <section className="slider-area">
      <Slider className="slider-active" {...settings}>
        <div>
          <div className="single-slider slider-bg d-flex align-items-center single-slider-height home-pg-background-position" style={{ backgroundImage: "url('img/mf/slider/banner3.png')" }}>
            <div className="container custom-container">
              <div className="row">
                <div className="col-xl-5 col-lg-7 col-md-10">
                  <div className="slider-content">
                    <div className="slider-title">
                      <h5 className="sub-title main-slider-font-weight maheshwari-furnitur-font-size">MF’S INTERIORS</h5>
                      <h2 className="title font-size Interiors-font-size text-color-theme main-slider-font-weight" data-animation="fadeInUpBig" data-delay=".2s" data-duration="1.2s">Bring Home Beautiful <span className="Vision-color vision-color-text">Interiors</span> That Fit Your Budget</h2>
                    </div>
                    <div className="slider-desc">
                      <p className="desc text-color-theme" data-animation="fadeInUpBig" data-delay=".4s" data-duration="1.2s">We’re bringing the Maheshwari Furniture’s experience into your home in 
                          a way that is designed and crafted in excellence.
                     </p>
                    </div>
                    <Link to="/contacts" aria-label="" className="btn main-slider-font-weight" data-animation="fadeInUpBig" data-delay=".6s" data-duration="1.2s">Reach Us </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Slider>
      <div className="slider-shape home-image-pg"><img src="img/slider/icon.png" alt="" /></div>
      <div className="slider-shape shape-two"><img src="img/slider/home-icon2.png" alt="" /></div>
    </section>
  )
}

export default Hero;
