import React from 'react';
import DiningTableDetailsBreadcumb from '../components/ourshop/Diningshopdetails/DiningTableDetailsBreadcumb'
import DiningTableDetailsArea from '../components/ourshop/Diningshopdetails/DiningTableDetailsArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function DiningTableDetailsPage() {
    return (
        <>
            <Header />
            <main>
                <DiningTableDetailsBreadcumb />
                <DiningTableDetailsArea />
            </main>
            <Footer />
        </>
    )
}

export default DiningTableDetailsPage;