import React from 'react';
import RectangularSolidWoodTableHome from '../components/ourshop/centertable/RectangularSolidWoodTableHome'
import RectangularSolidWoodTableHero from '../components/ourshop/centertable/RectangularSolidWoodTableHero'
import Header from "../components/Header";
import Footer from "../components/Footer";

function RectangularSolidWoodTable() {
	return (
		<>
			<Header />
			<main>
				<RectangularSolidWoodTableHome />
				<RectangularSolidWoodTableHero />
			</main>
			<Footer />
		</>
	)
}

export default RectangularSolidWoodTable;
