import React from 'react';
import RoundNestTableBreadcumb from '../components/ourshop/SideTableshopdetails/RoundNestTableBreadcumb'
import RoundNestTableArea from '../components/ourshop/SideTableshopdetails/RoundNestTableArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function RoundNestTablePages() {
    return (
        <>
            <Header />
            <main>
                <RoundNestTableBreadcumb />
                <RoundNestTableArea />
            </main>
            <Footer />
        </>
    )
}

export default RoundNestTablePages; 