import React from 'react';


function Features() {
    return (
        <section className="py-md-5 py-3 text-dark bg-white">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-6 wrapper">
                        <div className="appie-single-service-2 wow animated mt-30 fadeInUp image"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms">
                            <img className="adoption-shop-thumb" src="img/moduler_banner/Discover.jpg" alt="" />
                          <div className="content-modular">
                            <p className="text-justify"><b>Discover a Spectrum of Colors and Finishes</b></p>
                            <p className="text-justify features-padding">Dive into a rich array of colors and finishes to find the perfect match for your style and space.</p>
                          </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 wrapper">
                        <div className="appie-single-service-2 wow mt-30 animated fadeInUp image"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms">
                            <img className="adoption-shop-thumb" src="img/moduler_banner/Presonalized.jpg" alt="" />
                          <div className="content-modular">
                            <p className="text-justify features-padding"><b>Enjoy Exclusive Savings with a Quick, Personalized Quote</b></p>
                            <p className="text-justify features-padding"> Get access to special discounts by requesting a fast, tailored quote that suits your needs and budget. </p>
                          </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 wrapper">
                        <div className="appie-single-service-2 appie-single-service-3 mt-30 item-3 wow animated fadeInUp image"
                            data-wow-duration="2000ms"
                            data-wow-delay="600ms">
                            <img className="adoption-shop-thumb" src="img/moduler_banner/Tailored.png" alt="" />
                          <div className="content-modular"> 
                            <p className="text-justify"><b>Tailored Designs for Your Unique Tastes</b></p>
                            <p className="text-justify features-padding"> Experience designs meticulously crafted to harmonize effortlessly with your preferences and vision. </p>
                          </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 wrapper">
                        <div className="appie-single-service-2 mt-30 wow animated fadeInUp image"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms">
                            <img className="adoption-shop-thumb" src="img/moduler_banner/Flexible.jpg" alt="" />
                          <div className="content-modular"> 
                            <p className="text-justify features-padding"><b>Flexible Payment Solutions for Added Convenience</b></p>
                            <p className="text-justify features-padding"> Choose from a range of convenient and flexible payment options to suit your financial preferences and requirements. </p>
                          </div> 
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 wrapper">
                        <div className="appie-single-service-2 item-2 mt-30 wow animated fadeInUp image"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms">
                            <img className="adoption-shop-thumb" src="img/moduler_banner/Installation.jpg" alt="" />
                          <div className="content-modular"> 
                            <p className="text-justify"><b>Swift Delivery and Professional Installation</b></p>
                            <p className="text-justify features-padding"> Count on prompt delivery and expert installation services to ensure a seamless experience from start to finish. </p>
                           </div>  
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 wrapper">
                        <div className="appie-single-service-2 appie-single-service-3  item-3 mt-30 wow animated fadeInUp image"
                            data-wow-duration="2000ms"
                            data-wow-delay="600ms">
                            <img className="adoption-shop-thumb" src="img/moduler_banner/Comprehensive.jpg" alt="" />
                          <div className="content-modular">
                            <p className="text-justify"><b>Comprehensive Post-Installation Support</b></p>
                            <p className="text-justify features-padding"> We're here to support you every step of the way, offering assistance and guidance even after installation is complete. </p>
                         </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}


export default Features;
