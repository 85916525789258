import React from 'react';
import PlywoodPartitionsDetailsArea from '../components/ourshop/Partitions/PlywoodPartitionsDetailsArea'
import PlywoodPartitionsArea from '../components/ourshop/Partitions/PlywoodPartitionsArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function PlywoodPartitions() {
	return (
		<>
			<Header />
			<main>
				<PlywoodPartitionsDetailsArea />
				<PlywoodPartitionsArea/>
			</main>
			<Footer />
		</>
	)
}

export default PlywoodPartitions;
