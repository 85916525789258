import React from 'react';
import StudyTablewitOpenStorageinery from '../components/ourshop/studytable/StudyTablewitOpenStorageinery'
import StudyTablewitOpenStoragearaey from '../components/ourshop/studytable/StudyTablewitOpenStoragearaey'
import Header from "../components/Header";
import Footer from "../components/Footer";

function StudyTablewitOpenStorage() {
	return (
		<>
			<Header />
			<main>
				<StudyTablewitOpenStoragearaey />
				<StudyTablewitOpenStorageinery />
			</main>
			<Footer />
		</>
	)
}

export default StudyTablewitOpenStorage;
