import React from 'react';
import WallMountedStudytableinery from '../components/ourshop/studytable/WallMountedStudytableinery'
import WallMountedStudytablearaey from '../components/ourshop/studytable/WallMountedStudytablearaey'
import Header from "../components/Header";
import Footer from "../components/Footer";

function WallMountedStudytable() {
	return (
		<>
			<Header />
			<main>
				<WallMountedStudytablearaey />
				<WallMountedStudytableinery />
			</main>
			<Footer />
		</>
	)
}

export default WallMountedStudytable;
