import React from 'react';
import StudyTablewithThreeDrawersinery from '../components/ourshop/studytable/StudyTablewithThreeDrawersinery'
import StudyTablewithThreeDrawersaraey from '../components/ourshop/studytable/StudyTablewithThreeDrawersaraey'
import Header from "../components/Header";
import Footer from "../components/Footer";

function StudyTablewithThreeDrawers() {
	return (
		<>
			<Header />
			<main>
				<StudyTablewithThreeDrawersaraey />
				<StudyTablewithThreeDrawersinery />
			</main>
			<Footer />
		</>
	)
}

export default StudyTablewithThreeDrawers;
