import React from 'react';
import DiningChairrDetailsArea from '../components/ourshop/Diningchair/DiningChairrDetailsArea'
import DiningChairrArea from '../components/ourshop/Diningchair/DiningChairrArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function DiningChairr() {
	return (
		<>
			<Header />
			<main>
				<DiningChairrDetailsArea />
				<DiningChairrArea />
			</main>
			<Footer />
		</>
	)
}

export default DiningChairr;
