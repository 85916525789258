import React from 'react';
import MeshChairInBlackBreadcumb from '../components/ourshop/OfficeChairshopdetails/MeshChairInBlackBreadcumb'
import MeshChairInBlackArea from '../components/ourshop/OfficeChairshopdetails/MeshChairInBlackArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function MeshChairInBlackPages() {
    return (
        <>
            <Header />
            <main>
                <MeshChairInBlackBreadcumb />
                <MeshChairInBlackArea />
            </main>
            <Footer />
        </>
    )
}

export default MeshChairInBlackPages; 