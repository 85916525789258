import React from 'react';
import Slider from 'react-slick'
import { Link } from 'react-router-dom'

function CounterArea() {
	const settings = {
		dots: false,
		infinite: true,
		speed: 1000,
		autoplay: true,
		arrows: false,
		autoplaySpeed: 4000,
		slidesToShow: 1,
		slidesToScroll: 1,
		vertical: true,
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					infinite: true,
				}
			},
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					speed: 1000,
				}
			},
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					arrows: false,
					speed: 1000,
				}
			},
			{
				breakpoint: 575,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					arrows: false,
					speed: 1000,
				}
			},
		]
	}
	return (
		<section className="breeder-gallery-area breeder-gallery-padding">
		  <div className="container">
			   <div className="row align-items-center">
					<div className="col-lg-6">
						<div className="appie-how-it-work-content">
							<div className="adoption-list">
								<img src="img/moduler_banner/google.png" alt="" />
								<img src="img/moduler_banner/Untitled-2.png" alt="" />
							  </div>
								<h2 className="title main-slider-font-weight">Experience that you’ll love</h2>
								<p className="text-justify"></p>
						 </div>
					 </div>
					<div className="col-lg-6 order-0 order-lg-2">
						<Slider className="breeder-gallery-active" {...settings}>
							<div className="breeder-gallery-item">
							   <img src="img/moduler_banner/interior2.png" alt="" />
							</div>
							<div className="breeder-gallery-item">
							  <img src="img/moduler_banner/interior3.png" alt="" />
							</div>
							<div className="breeder-gallery-item">
							   <img src="img/moduler_banner/interior4.png" alt="" />
							</div>
							<div className="breeder-gallery-item">
							   <img src="img/moduler_banner/interior5.png" alt="" />
							</div>
							<div className="breeder-gallery-item">
							   <img src="img/moduler_banner/interior1.png" alt="" />
							</div>
						</Slider>
					</div>
				</div>
			</div>
		</section>
	)
}

export default CounterArea;