import React from 'react';
import SixSeaterDiningSetHero from '../components/ourshop/WoodenCane/SixSeaterDiningSetHero'
import SixSeaterDiningSetHome from '../components/ourshop/WoodenCane/SixSeaterDiningSetHome'
import Header from "../components/Header";
import Footer from "../components/Footer";

function SixSeaterDiningSet() {
	return (
		<>
			<Header />
			<main>
				<SixSeaterDiningSetHome />
				<SixSeaterDiningSetHero />
			</main>
			<Footer />
		</>
	)
}

export default SixSeaterDiningSet;
