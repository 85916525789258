import React from 'react';
import ShopBreadcumb from '../components/ourshop/ShopBreadcumb'
import CornerShelfsArea from '../components/ourshop/CornerShelfsArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function CornerShelfsPages() {
	return (
		<>
			<Header />
			<main>
				<ShopBreadcumb />
				<CornerShelfsArea />
			</main>
			<Footer />
		</>
	)
}

export default CornerShelfsPages;
