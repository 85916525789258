import React from 'react';

function Projects() {
    return (

        <section className="customers-section py-md-5 py-3 bg-white back-layer-container">
            <div className="container">
                <div className="justify-content-center justify-content-md-start">
                    <div className="col-md-12 mb-md-4">
                        <h2 className="fw-bold mb-md-4 text-center main-slider-font-weight">How our customer feels</h2>
                    </div>
                    <div className="row align-items-stretch d-none d-md-flex">
                        <div className="col-md-4 p-2">
                            <div className="bg-light p-3 h-100">
                                <div className="flex flex-column">
                                    <div className="col-md-12 position-relative col-12 p-0 text-center mb-3">
                                        <img src="" className="position-absolute play-icon" alt="" />
                                        <img src="" className="w-100" alt="" />
                                    </div>
                                    <div className="col-md-12 col-12 p-0 text-center text-md-start">
                                        <img src="img/images/clients1.png" alt="" />
                                        <h1 className="font-size-18px main-slider-font-weight">Maya Sharma</h1>
                                        <p className="mb-2">"Maheshwari Furniture transformed my study area into productivity and style. It perfectly matched the designs that were shown to us and made in quick time. Perfectly on budget. Their attention to detail and quality craftsmanship exceeded my expectations! Highly Recommended"</p>
                                     </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 p-2">
                            <div className="bg-light p-3 h-100">
                                <div className="flex flex-column">
                                    <div className="col-md-12 position-relative col-12 p-0 text-center mb-3">
                                        <img src="" className="position-absolute play-icon" alt="" />
                                        <img src="" className="w-100" alt="" />
                                    </div>
                                    <div className="col-md-12 col-12 p-0 text-center text-md-start">
                                        <img src="img/images/clients2.png" alt="" />
                                        <h2 className="font-size-18px main-slider-font-weight">Bhawana Dhanwaria</h2>
                                        <p className="mb-2">"We really fell in love with the quality that Maheshwari Furniture assured and the multiple options that they were to offer to our kitchen area. The perfect blend of functionality and style, it has transformed my cooking space into a dream place of efficiency and elegance."</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 p-2">
                            <div className="bg-light p-3 h-100">
                                <div className="flex flex-column">
                                    <div className="col-md-12 position-relative col-12 p-0 text-center mb-3">
                                        <img src="" className="position-absolute play-icon" alt="" />
                                        <img className="w-100" alt="" />
                                    </div>
                                    <div className="col-md-12 col-12 p-0 text-center text-md-start">
                                        <img src="img/images/clients3.png" alt="" />
                                        <h3 className="font-size-18px main-slider-font-weight">Anita Mishra</h3>
                                        <p className="mb-2">I wanted our dream closet room to be unique, spacious, and elegant. And I think Maheshwari Furniture has captured that accurately. It revolutionized my storage space, offering both luxury and functionality in one elegant design. From the sleek finishes to the spacious layout.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Projects;
