import React from 'react';
import { Link } from 'react-router-dom'

function WardrobeArea() {
  return (
    <div className="shop-area pt-110 pb-110">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-12">
            <div className="shop-wrap">
              <h4 className="title main-slider-font-weight">All Wardrobe</h4>
              <div className="row justify-content-start">
                <div className="col-lg-3 col-sm-6">
                  <div className="shop-item mb-55">
                    <div className="shop-thumb">
                      <Link to="/3-door-multi-utility-wardrobe"><img src="img/product/wardrobe1.jpg" alt="" /></Link>
                    </div>
                    <div className="shop-content">
                      <span>MAHESHWARI FURNITURE</span>
                      <h4 className="title main-slider-font-weight"><Link to="/3-door-multi-utility-wardrobe">3 Door Multi Utility Wardrobe</Link></h4>
                      <div className="shop-content-bottom">
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="shop-item mb-55">
                    <div className="shop-thumb">
                      <Link to="/2-door-wardrobe-with-mirror"><img src="img/product/wardrobe2.jpg" alt="" /></Link>
                    </div>
                    <div className="shop-content">
                      <span>MAHESHWARI FURNITURE</span>
                      <h4 className="title main-slider-font-weight"><Link to="/2-door-wardrobe-with-mirror">2 Door Wardrobe with Mirror</Link></h4>
                      <div className="shop-content-bottom">
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="shop-item mb-55">
                    <div className="shop-thumb">
                      <Link to="/1-door-wardrobe"><img src="img/product/wardrobe3.jpg" alt="" /></Link>
                    </div>
                    <div className="shop-content">
                      <span>MAHESHWARI FURNITURE</span>
                      <h4 className="title main-slider-font-weight"><Link to="/1-door-wardrobe">1 Door Wardrobe</Link></h4>
                      <div className="shop-content-bottom">
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="shop-item mb-55">
                    <div className="shop-thumb">
                      <Link to="/boho-small-size-wardrobe"><img src="img/product/wardrobe4.jpg" alt="" /></Link>
                    </div>
                    <div className="shop-content">
                      <span>MAHESHWARI FURNITURE</span>
                      <h4 className="title main-slider-font-weight"><Link to="/boho-small-size-wardrobe">Boho Small Size Wardrobe</Link></h4>
                      <div className="shop-content-bottom">
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="shop-item mb-55">
                    <div className="shop-thumb">
                      <Link to="/2-door-multi-utility-wardrobe"><img src="img/product/wardrobe5.jpg" alt="" /></Link>
                    </div>
                    <div className="shop-content">
                      <span>MAHESHWARI FURNITURE</span>
                      <h4 className="title main-slider-font-weight"><Link to="/2-door-multi-utility-wardrobe">2 Door Multi Utility Wardrobe</Link></h4>
                      <div className="shop-content-bottom">
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="shop-item mb-55">
                    <div className="shop-thumb">
                      <Link to="/multi-storage-sheesham-wardrobe"><img src="img/product/wardrobe6.jpg" alt="" /></Link>
                    </div>
                    <div className="shop-content">
                      <span>MAHESHWARI FURNITURE</span>
                      <h4 className="title main-slider-font-weight"><Link to="/multi-storage-sheesham-wardrobe">Multi Storage Sheesham Wardrobe</Link></h4>
                      <div className="shop-content-bottom">
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="shop-item mb-55">
                    <div className="shop-thumb">
                      <Link to="/2-door-almirah"><img src="img/product/wardrobe7.jpg" alt="" /></Link>
                    </div>
                    <div className="shop-content">
                      <span>MAHESHWARI FURNITURE</span>
                      <h4 className="title main-slider-font-weight"><Link to="/2-door-almirah">2 Door Almirah</Link></h4>
                      <div className="shop-content-bottom">
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="shop-item mb-55">
                    <div className="shop-thumb">
                      <Link to="/3-door-wardrobe"><img src="img/product/wardrobe8b.png" alt="" /></Link>
                    </div>
                    <div className="shop-content">
                      <span>MAHESHWARI FURNITURE</span>
                      <h4 className="title main-slider-font-weight"><Link to="/3-door-wardrobe">3 Door Wardrobe</Link></h4>
                      <div className="shop-content-bottom">
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="shop-item mb-55">
                    <div className="shop-thumb">
                      <Link to="/4-door-wardrobe-with-mirror"><img src="img/product/wardrobe9b.png" alt="" /></Link>
                    </div>
                    <div className="shop-content">
                      <span>MAHESHWARI FURNITURE</span>
                      <h4 className="title main-slider-font-weight"><Link to="/4-door-wardrobe-with-mirror">4 Door Wardrobe with Mirror</Link></h4>
                      <div className="shop-content-bottom">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WardrobeArea;
