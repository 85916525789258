import React from 'react';
import StoolDarkBlueDetailsArea from '../components/ourshop/Puffy/StoolDarkBlueDetailsArea'
import StoolDarkBlueArea from '../components/ourshop/Puffy/StoolDarkBlueArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function StoolDarkBlue() {
	return (
		<>
			<Header />
			<main>
				<StoolDarkBlueDetailsArea />
				<StoolDarkBlueArea/>
			</main>
			<Footer />
		</>
	)
}

export default StoolDarkBlue;
