import React from 'react';
import WoodenSofaSetinSeatingHero from '../components/ourshop/sheeshamsofa/WoodenSofaSetinSeatingHero'
import WoodenSofaSetinSeatingHome from '../components/ourshop/sheeshamsofa/WoodenSofaSetinSeatingHome'
import Header from "../components/Header";
import Footer from "../components/Footer";

function WoodenSofaSetinSeating() {
	return (
		<>
			<Header />
			<main>
				<WoodenSofaSetinSeatingHome />
				<WoodenSofaSetinSeatingHero />
			</main>
			<Footer />
		</>
	)
}

export default WoodenSofaSetinSeating;
