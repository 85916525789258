import React from 'react';
import BeautifullyCarvedMandirBreadcumb from '../components/ourshop/Mandirshopdetails/BeautifullyCarvedMandirBreadcumb'
import BeautifullyCarvedMandirArea from '../components/ourshop/Mandirshopdetails/BeautifullyCarvedMandirArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function BeautifullyCarvedMandirPages() {
    return (
        <>
            <Header />
            <main>
                <BeautifullyCarvedMandirBreadcumb />
                <BeautifullyCarvedMandirArea />
            </main>
            <Footer />
        </>
    )
}

export default BeautifullyCarvedMandirPages; 