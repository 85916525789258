import React from 'react';
import ShopBreadcumb from '../components/ourshop/ShopBreadcumb'
import PartitionsArea from '../components/ourshop/PartitionsArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function PartitionsPages() {
	return (
		<>
			<Header />
			<main>
				<ShopBreadcumb />
				<PartitionsArea />
			</main>
			<Footer />
		</>
	)
}

export default PartitionsPages;
