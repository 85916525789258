import React from 'react';
import CaneHandworkCabinetHero from '../components/ourshop/WoodenCane/CaneHandworkCabinetHero'
import CaneHandworkCabinetHome from '../components/ourshop/WoodenCane/CaneHandworkCabinetHome'
import Header from "../components/Header";
import Footer from "../components/Footer";

function CaneHandworkCabinet() {
	return (
		<>
			<Header />
			<main>
				<CaneHandworkCabinetHome />
				<CaneHandworkCabinetHero />
			</main>
			<Footer />
		</>
	)
}

export default CaneHandworkCabinet;
