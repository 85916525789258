import React from 'react';
import MeshOfficeChairBreadcumb from '../components/ourshop/OfficeChairshopdetails/MeshOfficeChairBreadcumb'
import MeshOfficeChairArea from '../components/ourshop/OfficeChairshopdetails/MeshOfficeChairArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function MeshOfficeChairPages() {
    return (
        <>
            <Header />
            <main>
                <MeshOfficeChairBreadcumb />
                <MeshOfficeChairArea />
            </main>
            <Footer />
        </>
    )
}

export default MeshOfficeChairPages; 