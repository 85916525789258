import React from 'react';
import PrintedSofaDetailsBreadcumb from '../components/ourshop/Sofashopdetails/PrintedSofaDetailsBreadcumb'
import PrintedSofaDetailsArea from '../components/ourshop/Sofashopdetails/PrintedSofaDetailsArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function PrintedSofaDetailsPage() {
    return (
        <>
            <Header />
            <main>
                <PrintedSofaDetailsBreadcumb />
                <PrintedSofaDetailsArea />
            </main>
            <Footer />
        </>
    )
}

export default PrintedSofaDetailsPage;