import React from 'react';
import DiningChairWithoutCushionsDetailsArea from '../components/ourshop/Diningchair/DiningChairWithoutCushionsDetailsArea'
import DiningChairWithoutCushionsArea from '../components/ourshop/Diningchair/DiningChairWithoutCushionsArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function DiningChairWithoutCushions() {
	return (
		<>
			<Header />
			<main>
				<DiningChairWithoutCushionsDetailsArea />
				<DiningChairWithoutCushionsArea />
			</main>
			<Footer />
		</>
	)
}

export default DiningChairWithoutCushions;
