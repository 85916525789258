import React from 'react';
import MDFWoodenTempleBreadcumb from '../components/ourshop/Mandirshopdetails/MDFWoodenTempleBreadcumb'
import MDFWoodenTempleArea from '../components/ourshop/Mandirshopdetails/MDFWoodenTempleArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function MDFWoodenTemplePages() {
    return (
        <>
            <Header />
            <main>
                <MDFWoodenTempleBreadcumb />
                <MDFWoodenTempleArea />
            </main>
            <Footer />
        </>
    )
}

export default MDFWoodenTemplePages; 