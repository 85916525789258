import React from 'react';
import BedWithFullDrawerStorageDetailsBreadcumb from '../components/ourshop/Bedshopdetails/BedWithFullDrawerStorageDetailsBreadcumb'
import BedWithFullDrawerStorageDetailsArea from '../components/ourshop/Bedshopdetails/BedWithFullDrawerStorageDetailsArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function BedWithFullDrawerStorageDetailsPage() {
    return (
        <>
            <Header />
            <main>
                <BedWithFullDrawerStorageDetailsBreadcumb />
                <BedWithFullDrawerStorageDetailsArea />
            </main>
            <Footer />
        </>
    )
}

export default BedWithFullDrawerStorageDetailsPage;