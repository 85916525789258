import React from 'react';
import ArmChairPrintedBreadcumb from '../components/ourshop/Comfortersshopdetails/ArmChairPrintedBreadcumb'
import ArmChairPrintedArea from '../components/ourshop/Comfortersshopdetails/ArmChairPrintedArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function ArmChairPrintedPages() {
    return (
        <>
            <Header />
            <main>
                <ArmChairPrintedBreadcumb />
                <ArmChairPrintedArea />
            </main>
            <Footer />
        </>
    )
}

export default ArmChairPrintedPages;