import React from 'react';
import TowDoorMultiUtilityWardrobeHero from '../components/ourshop/wardrobe/TowDoorMultiUtilityWardrobeHero'
import TowDoorMultiUtilityWardrobeHome from '../components/ourshop/wardrobe/TowDoorMultiUtilityWardrobeHome'
import Header from "../components/Header";
import Footer from "../components/Footer";

function TowDoorMultiUtilityWardrobe() {
	return (
		<>
			<Header />
			<main>
				<TowDoorMultiUtilityWardrobeHome />
				<TowDoorMultiUtilityWardrobeHero />
			</main>
			<Footer />
		</>
	)
}

export default TowDoorMultiUtilityWardrobe;
