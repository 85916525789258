import React from 'react';
import LShapeComputerDeskBreadcumb from '../components/ourshop/OfficeCountershopdetails/LShapeComputerDeskBreadcumb'
import LShapeComputerDeskArea from '../components/ourshop/OfficeCountershopdetails/LShapeComputerDeskArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function LShapeComputerDeskPages() {
    return (
        <>
            <Header />
            <main>
                <LShapeComputerDeskBreadcumb />
                <LShapeComputerDeskArea />
            </main>
            <Footer />
        </>
    )
}

export default LShapeComputerDeskPages; 