import React from 'react';
import StraightKitchenDesignBreadcumb from '../components/ourshop/Kitchenshopdetails/StraightKitchenDesignBreadcumb'
import StraightKitchenDesignArea from '../components/ourshop/Kitchenshopdetails/StraightKitchenDesignArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function StraightKitchenDesignPages() {
    return (
        <>
            <Header />
            <main>
                <StraightKitchenDesignBreadcumb />
                <StraightKitchenDesignArea />
            </main>
            <Footer />
        </>
    )
}

export default StraightKitchenDesignPages;