import React from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import ScrollToTop from "./ScrollToTop";

import Home from "./pages/Home";
import PrintedWoodenStool from "./pages/PrintedWoodenStool";
import DoorWardrobewithMirror from "./pages/DoorWardrobewithMirror";
import StoolDarkBlue from "./pages/StoolDarkBlue";
import ThreeDoorWardrobe from "./pages/ThreeDoorWardrobe";
import MultiStorageSheeshamWardrobe from "./pages/MultiStorageSheeshamWardrobe";
import TowDoorMultiUtilityWardrobe from "./pages/TowDoorMultiUtilityWardrobe";
import OneDoorWardrobe from "./pages/OneDoorWardrobe";
import TowDoorAlmirah from "./pages/TowDoorAlmirah";
import BohoSmallSizeWardrobe from "./pages/BohoSmallSizeWardrobe";
import SquareTablewith4Stools from "./pages/SquareTablewith4Stools";
import SolidWoodenTable from "./pages/SolidWoodenTable";
import TwoDoorWardrobewithMirror from "./pages/TwoDoorWardrobewithMirror";
import ThreeDoorMultiUtilityWardrobe from "./pages/ThreeDoorMultiUtilityWardrobe";
import RectangularSolidWoodTable from "./pages/RectangularSolidWoodTable";
import RectangularWoodTableSpace from "./pages/RectangularWoodTableSpace";
import WoodenTablewithOpenSpace from "./pages/WoodenTablewithOpenSpace";
import WoodenTablewithGlassattop from "./pages/WoodenTablewithGlassattop";
import SquareWoodenTable from "./pages/SquareWoodenTable";
import RectangularSolidWoodCoffeeTable from "./pages/RectangularSolidWoodCoffeeTable";
import SofaCumBed from "./pages/SofaCumBed";
import RectangularWoodTableBeige from "./pages/RectangularWoodTableBeige";
import FiveSeaterSheeshamSet from "./pages/FiveSeaterSheeshamSet";
import LShapedCornerSofawithStorage from "./pages/LShapedCornerSofawithStorage";
import WoodenShoeRack from "./pages/WoodenShoeRack";
import SideStorageSheeshamSet from "./pages/SideStorageSheeshamSet";
import ThreeandoneWoodenSofaSet from "./pages/ThreeandoneWoodenSofaSet";
import WalnutFinishSofaset from "./pages/WalnutFinishSofaset";
import WoodenSofaSetinSeating from "./pages/WoodenSofaSetinSeating";
import WoodenSofaSet from "./pages/WoodenSofaSet";
import SixSeaterDiningSet from "./pages/SixSeaterDiningSet";
import FiveSeaterWoodenSofaSetwithSideStorage from "./pages/FiveSeaterWoodenSofaSetwithSideStorage";
import CaneWoodenChairSet from "./pages/CaneWoodenChairSet";
import HomeageWoodenChair from "./pages/HomeageWoodenChair";
import EverydayThreeSeaterBench from "./pages/EverydayThreeSeaterBench";
import Everydaystool from "./pages/Everydaystool";
import WoodenPodiumTable from "./pages/WoodenPodiumTable";
import CaneHandworkCabinet from "./pages/CaneHandworkCabinet";
import SeeshamCaneVintageBed from "./pages/SeeshamCaneVintageBed";
import StudyTablewithBookShelf from "./pages/StudyTablewithBookShelf";
import CenterTableWithStorage from "./pages/CenterTableWithStorage";
import StudyTablewithKeyboardTrayandSideStorage from "./pages/StudyTablewithKeyboardTrayandSideStorage";
import StudyTablewithDrawersandCabinet from "./pages/StudyTablewithDrawersandCabinet";
import StudyTableCumShelf from "./pages/StudyTableCumShelf";
import StudyTablewitOpenStorage from "./pages/StudyTablewitOpenStorage";
import StudyTablewithThreeDrawers from "./pages/StudyTablewithThreeDrawers";
import WallMountedStudytable from "./pages/WallMountedStudytable";
import StudyTablewithfourDrawers from "./pages/StudyTablewithfourDrawers";
import WoodenCanningPages from "./pages/WoodenCanningPages";
import StudyTablewithDrawer from "./pages/StudyTablewithDrawer";
import ReturnPolicy from "./pages/ReturnPolicy";
import StoolMulticolour from "./pages/StoolMulticolour";
import BarStool from "./pages/BarStool";
import WoodenStools from "./pages/WoodenStools";
import SolidWoodenStool from "./pages/SolidWoodenStool";
import StoolwithWoodenLegs from "./pages/StoolwithWoodenLegs";
import WoodenStool from "./pages/WoodenStool";
import WoodenDividerShapes from "./pages/WoodenDividerShapes";
import WoodCraftedDividers from "./pages/WoodCraftedDividers";
import PrintedStool from "./pages/PrintedStool";
import DivideCraftFurnish from "./pages/DivideCraftFurnish";
import PlywoodPartitions from "./pages/PlywoodPartitions";
import WoodenRoomPartitions from "./pages/WoodenRoomPartitions";
import HomeDividers from "./pages/HomeDividers";
import WoodenScreens from "./pages/WoodenScreens";
import PartitionCrafters from "./pages/PartitionCrafters";
import WoodWavesDivider from "./pages/WoodWavesDivider";
import WoodDressingTableWithShelf from "./pages/WoodDressingTableWithShelf";
import DressingTableWithStoolStorage from "./pages/DressingTableWithStoolStorage";
import SheeshamDressingtable from "./pages/SheeshamDressingtable";
import DressingTable from "./pages/DressingTable";
import WoodDressingTable from "./pages/WoodDressingTable";
import CatalogList from "./pages/CatalogList";
import SofasPages from "./pages/SofasPages";
import DressingTableWithShelvesandStorage from "./pages/DressingTableWithShelvesandStorage";
import DressingTableWithStorage from "./pages/DressingTableWithStorage";
import TwoSeaterSofaDetailsPage from "./pages/TwoSeaterSofaDetailsPage";
import HexagonWallShelf from "./pages/HexagonWallShelf";
import CornerShelf from "./pages/CornerShelf";
import SquareWallShelf from "./pages/SquareWallShelf";
import CornerShelves from "./pages/CornerShelves";
import RoundWallShelf from "./pages/RoundWallShelf";
import CornerStandShelf from "./pages/CornerStandShelf";
import WallShelves from "./pages/WallShelves";
import WoodenCornerShelf from "./pages/WoodenCornerShelf";
import DiningChairSetofTwo from "./pages/DiningChairSetofTwo";
import DiningChairWithoutFabric from "./pages/DiningChairWithoutFabric";
import DiningChairWithFabric from "./pages/DiningChairWithFabric";
import DiningChair from "./pages/DiningChair";
import DiningChairr from "./pages/DiningChairr";
import CushionedDiningChair from "./pages/CushionedDiningChair";
import DiningChairBlue from "./pages/DiningChairBlue";
import DiningChairMustard from "./pages/DiningChairMustard";
import DiningChairWithoutCushions from "./pages/DiningChairWithoutCushions";
import CornerShelfSetof3 from "./pages/CornerShelfSetof3";
import TwoSeaterCurvedSofaDetailsPage from "./pages/TwoSeaterCurvedSofaDetailsPage";
import LShapedSofaDetailsPage from "./pages/LShapedSofaDetailsPage";
import ThreeSeaterSofaDetailsPage from "./pages/ThreeSeaterSofaDetailsPage";
import ThreeSeaterQuilttedSofaDetailsPage from "./pages/ThreeSeaterQuilttedSofaDetailsPage";
import PrintedSofaDetailsPage from "./pages/PrintedSofaDetailsPage";
import OliveGreenSofaDetailsPage from "./pages/OliveGreenSofaDetailsPage";
import CornerSofaDetailsPage from "./pages/CornerSofaDetailsPage";
import RoundQuilttedSofaDetailsPage from "./pages/RoundQuilttedSofaDetailsPage";
import DiningSetsPages from "./pages/DiningSetsPages";
import SixSeaterDiningSetDetailsPage from "./pages/SixSeaterDiningSetDetailsPage";
import DiningWithBenchDetailsPage from "./pages/DiningWithBenchDetailsPage";
import CurvedVertexDiningDetailsPage from "./pages/CurvedVertexDiningDetailsPage";
import SixSeaterDiningDetailsPage from "./pages/SixSeaterDiningDetailsPage";
import CushionedDiningDetailsPage from "./pages/CushionedDiningDetailsPage";
import DiningTableSetDetailsPage from "./pages/DiningTableSetDetailsPage";
import DiningTableDetailsPage from "./pages/DiningTableDetailsPage.js";
import MarbleDiningDetailsPage from "./pages/MarbleDiningDetailsPage.js";
import BlueDiningDetailsPage from "./pages/BlueDiningDetailsPage.js";
import BedsPages from "./pages/BedsPages";
import SheeshamWoodBedDetailsPage from "./pages/SheeshamWoodBedDetailsPage.js";
import BedWithDrawerStorageDetailsPage from "./pages/BedWithDrawerStorageDetailsPage.js";
import BedWithFullDrawerStorageDetailsPage from "./pages/BedWithFullDrawerStorageDetailsPage.js";
import BohoSheeshamWoodBedDetailsPage from "./pages/BohoSheeshamWoodBedDetailsPage.js";
import FullyUpholsteredBedDetailsPage from "./pages/FullyUpholsteredBedDetailsPage.js";
import KingSizeBedDetailsPage from "./pages/KingSizeBedDetailsPage.js";
import StorageBedWithBedsideDetailsPage from "./pages/StorageBedWithBedsideDetailsPage.js";
import SheeshamWoodKingSizeBedDetailsPage from "./pages/SheeshamWoodKingSizeBedDetailsPage.js";
import SheeshamWoodBedWithStorageDetailsPage from "./pages/SheeshamWoodBedWithStorageDetailsPage.js";
import SheeshamSofaPages from "./pages/SheeshamSofaPages";
import CenterTablePages from "./pages/CenterTablePages";
import WardrobePages from "./pages/WardrobePages";
import StudyTablePages from "./pages/StudyTablePages";
import OfficeCounterPages from "./pages/OfficeCounterPages";
import ServiceCounterPages from "./pages/ServiceCounterPages";
import LShapedOfficeTablePages from "./pages/LShapedOfficeTablePages";
import OfficeTableWithThreeDrawersPages from "./pages/OfficeTableWithThreeDrawersPages";
import ModularOfficeTablePages from "./pages/ModularOfficeTablePages";
import WoodAndMetalOfficeDeskPages from "./pages/WoodAndMetalOfficeDeskPages";
import OfficeCounterWithStoragePages from "./pages/OfficeCounterWithStoragePages";
import LShapeComputerDeskPages from "./pages/LShapeComputerDeskPages";
import WoodenComputerDeskPages from "./pages/WoodenComputerDeskPages";
import OfficeTablePages from "./pages/OfficeTablePages";
import OfficeChairPages from "./pages/OfficeChairPages";
import MeshOfficeChairPages from "./pages/MeshOfficeChairPages";
import BeigeRevolvingChairPages from "./pages/BeigeRevolvingChairPages";
import OfficeChairInBlackPages from "./pages/OfficeChairInBlackPages";
import HighBackRevolvingChairPages from "./pages/HighBackRevolvingChairPages";
import BlackRevolvingChairPages from "./pages/BlackRevolvingChairPages";
import MeshChairInBlackPages from "./pages/MeshChairInBlackPages";
import BrownRevolvingChairPages from "./pages/BrownRevolvingChairPages";
import BlackOfficeChairPages from "./pages/BlackOfficeChairPages";
import HighBackOfficeChairPages from "./pages/HighBackOfficeChairPages";
import IronChairPages from "./pages/IronChairPages";
import MetalMasterTablePages from "./pages/MetalMasterTablePages";
import IronStoolPages from "./pages/IronStoolPages";
import ForgeCoffeeTablePages from "./pages/ForgeCoffeeTablePages";
import IronForgeChairPages from "./pages/IronForgeChairPages";
import IronSeatPages from "./pages/IronSeatPages";
import IronShoeRackPages from "./pages/IronShoeRackPages";
import IronBarStoolPages from "./pages/IronBarStoolPages";
import IronCushionedStoolPages from "./pages/IronCushionedStoolPages";
import IronTablePages from "./pages/IronTablePages";
import MandirPages from "./pages/MandirPages";
import WallHangingDivineCraftsPages from "./pages/WallHangingDivineCraftsPages";
import WoodenDevotionMandirPages from "./pages/WoodenDevotionMandirPages";
import TempleWoodWithStoragePages from "./pages/TempleWoodWithStoragePages";
import CraftedMandirPages from "./pages/CraftedMandirPages";
import HomeTemplePages from "./pages/HomeTemplePages";
import WoodenHomeTemplePages from "./pages/WoodenHomeTemplePages";
import SpaciousShelfMandirPages from "./pages/SpaciousShelfMandirPages";
import BeautifullyCarvedMandirPages from "./pages/BeautifullyCarvedMandirPages";
import MDFWoodenTemplePages from "./pages/MDFWoodenTemplePages";
import CabinetPages from "./pages/CabinetPages";
import OfficeFurniturePages from './pages/OfficeFurniturePages';
import SeatingSpacePages from './pages/SeatingSpacePages';
import OfficeCounterSpacePages from './pages/OfficeCounterSpacePages';
import EmployeeDeskPages from './pages/EmployeeDeskPages';
import ModularWorkspaceSystemPages from './pages/ModularWorkspaceSystemPages';
import OfficeDeskPages from './pages/OfficeDeskPages';
import ReceptionPages from './pages/ReceptionPages';
import WorkplaceFurnishingsPages from './pages/WorkplaceFurnishingsPages';
import WorkspacePages from './pages/WorkspacePages';
import SwiftStorageFileCabinetPages from './pages/SwiftStorageFileCabinetPages';
import ComforterPages from "./pages/ComforterPages";
import WoodenChairCreamPages from "./pages/WoodenChairCreamPages";
import RevolvingLoungeChairPages from "./pages/RevolvingLoungeChairPages";
import LoungeChairPinkPages from "./pages/LoungeChairPinkPages";
import LoungeChairNavyBluePages from "./pages/LoungeChairNavyBluePages";
import LoungeChairYellowPages from "./pages/LoungeChairYellowPages";
import RockingChairPages from "./pages/RockingChairPages";
import FloralPrintLoungeChairPages from "./pages/FloralPrintLoungeChairPages";
import ArmChairPrintedPages from "./pages/ArmChairPrintedPages";
import LoungeChairBeigePages from "./pages/LoungeChairBeigePages";
import SideTablePages from "./pages/SideTablePages";
import RoundNestTablePages from "./pages/RoundNestTablePages";
import BedSideTablePages from "./pages/BedSideTablePages";
import SheeshamWoodSideTablePages from "./pages/SheeshamWoodSideTablePages";
import SolidWoodCoffeeTablePages from "./pages/SolidWoodCoffeeTablePages";
import GoldFinishSideTablePages from "./pages/GoldFinishSideTablePages";
import NestOfTablePages from "./pages/NestOfTablePages";
import RoundSideTablePages from "./pages/RoundSideTablePages";
import SideEndTablePages from "./pages/SideEndTablePages";
import SideAndEndTablePages from "./pages/SideAndEndTablePages";
import KitchenPages from "./pages/KitchenPages";
import SlidingWardrobePages from "./pages/SlidingWardrobePages";
import StraightKitchenDesignPages from "./pages/StraightKitchenDesignPages";
import BarCabinetPages from "./pages/BarCabinetPages";
import WallMountedBarCabinetPages from "./pages/WallMountedBarCabinetPages";
import WineBarUnitPages from "./pages/WineBarUnitPages";
import SimpleKitchenDesignPages from "./pages/SimpleKitchenDesignPages";
import IslandKitchenDesignPages from "./pages/IslandKitchenDesignPages";
import LShapedKitchenDesignPages from "./pages/LShapedKitchenDesignPages";
import ModularKitchenDesignPages from "./pages/ModularKitchenDesignPages";
import PuffyPages from './pages/PuffyPages';
import PartitionsPages from './pages/PartitionsPages';
import DressingTablePages from './pages/DressingTablePages';
import DressingTableWithDrawers from "./pages/DressingTableWithDrawers";
import DiningChairPages from './pages/DiningChairPages';
import CornerShelfsPages from './pages/CornerShelfsPages';
import DogList from "./pages/DogList";
import SofasList from './pages/SofasList';
import AdoptionsPages from './pages/AdoptionsPages'
import ContactPage from './pages/ContactPage'
import interiors from './pages/interiors.js'
import TermsofUse from './pages/TermsofUse'
import Privacypolicy from './pages/Privacyandpolicy.js'
import BlogPage from './pages/BlogPage'
import BlogDetailsPage from './pages/BlogDetailsPage'
import ShopPages from './pages/ShopPages'
import ShopDetailsPage from './pages/ShopDetailsPage'
import BreederPage from './pages/BreederPage'
import BreederDetailsPage from './pages/BreederDetailsPage'
import HometwoPage from './pages/HometwoPage'
import DressingTableWithShelfPages from './pages/DressingTableWithShelfPages';


function Routes() {
    return (
        <div className='app'>
            <Router>
                <ScrollToTop>
                    <Switch>
                        <Route exact path="/" component={Home} />
                        <Route exact path="/categories" component={CatalogList} />
                        <Route exact path="/sofas" component={SofasPages} />
                        <Route exact path="/two-seater-sofa" component={TwoSeaterSofaDetailsPage} />
                        <Route exact path="/two-seater-curved-sofa" component={TwoSeaterCurvedSofaDetailsPage} />
                        <Route exact path="/l-shaped-sofa" component={LShapedSofaDetailsPage} />
                        <Route exact path="/three-seater-sofa" component={ThreeSeaterSofaDetailsPage} />
                        <Route exact path="/three-quiltted-sofa" component={ThreeSeaterQuilttedSofaDetailsPage} />
                        <Route exact path="/printed-seater-sofa" component={PrintedSofaDetailsPage} />
                        <Route exact path="/olive-green-sofa" component={OliveGreenSofaDetailsPage} />
                        <Route exact path="/l-shaped-corner-sofa" component={CornerSofaDetailsPage} />
                        <Route exact path="/round-quiltted-sofa" component={RoundQuilttedSofaDetailsPage} />
                        <Route exact path="/dining-sets" component={DiningSetsPages} />
                        <Route exact path="/six-seater-dining-set" component={SixSeaterDiningSetDetailsPage} />
                        <Route exact path="/dining-bench-set" component={DiningWithBenchDetailsPage} />
                        <Route exact path="/curved-vertex-dining" component={CurvedVertexDiningDetailsPage} />
                        <Route exact path="/six-seater-dining" component={SixSeaterDiningDetailsPage} />
                        <Route exact path="/six-seater-cushioned-dining" component={CushionedDiningDetailsPage} />
                        <Route exact path="/dining-table" component={DiningTableSetDetailsPage} />
                        <Route exact path="/dining-table-set" component={DiningTableDetailsPage} />
                        <Route exact path="/marble-dining-table-set" component={MarbleDiningDetailsPage} />
                        <Route exact path="/blue-dining-table-set" component={BlueDiningDetailsPage} />
                        <Route exact path="/beds" component={BedsPages} />
                        <Route exact path="/sheesham-wood-bed" component={SheeshamWoodBedDetailsPage} />
                        <Route exact path="/bed-drawer-storage" component={BedWithDrawerStorageDetailsPage} />
                        <Route exact path="/bed-full-drawer-storage" component={BedWithFullDrawerStorageDetailsPage} />
                        <Route exact path="/boho-sheesham-wood-bed" component={BohoSheeshamWoodBedDetailsPage} />
                        <Route exact path="/fully-upholstered-bed" component={FullyUpholsteredBedDetailsPage} />
                        <Route exact path="/king-size-bed" component={KingSizeBedDetailsPage} />
                        <Route exact path="/storage-bed-bedside" component={StorageBedWithBedsideDetailsPage} />
                        <Route exact path="/sheesham-wood-king-size-bed" component={SheeshamWoodKingSizeBedDetailsPage} />
                        <Route exact path="/sheesham-wood-bed-storage" component={SheeshamWoodBedWithStorageDetailsPage} />
                        <Route exact path="/sheeshamsofa" component={SheeshamSofaPages} />
                        <Route exact path="/wooden-cane" component={WoodenCanningPages} />
                        <Route exact path="/centertable" component={CenterTablePages} />
                        <Route exact path="/wardrobe" component={WardrobePages} />
                        <Route exact path="/studytable" component={StudyTablePages} />
                        <Route exact path="/officecounter" component={OfficeCounterPages} />
                        <Route exact path="/service-counter" component={ServiceCounterPages} />
                        <Route exact path="/l-shaped-office-table" component={LShapedOfficeTablePages} />
                        <Route exact path="/office-table-with-three-drawers" component={OfficeTableWithThreeDrawersPages} />
                        <Route exact path="/modular-office-table" component={ModularOfficeTablePages} />
                        <Route exact path="/wood-and-metal-office-desk" component={WoodAndMetalOfficeDeskPages} />
                        <Route exact path="/office-counter-with-storage" component={OfficeCounterWithStoragePages} />
                        <Route exact path="/l-shape-computer-desk" component={LShapeComputerDeskPages} />
                        <Route exact path="/wooden-computer-desk" component={WoodenComputerDeskPages} />
                        <Route exact path="/office-table" component={OfficeTablePages} />
                        <Route exact path="/officechair" component={OfficeChairPages} />
                        <Route exact path="/mesh-office-chair" component={MeshOfficeChairPages} />
                        <Route exact path="/beige-revolving-chair" component={BeigeRevolvingChairPages} />
                        <Route exact path="/office-chair-black" component={OfficeChairInBlackPages} />
                        <Route exact path="/high-back-revolving-chair" component={HighBackRevolvingChairPages} />
                        <Route exact path="/black-revolving-chair" component={BlackRevolvingChairPages} />
                        <Route exact path="/mesh-chair-black" component={MeshChairInBlackPages} />
                        <Route exact path="/brown-revolving-chair" component={BrownRevolvingChairPages} />
                        <Route exact path="/black-office-chair" component={BlackOfficeChairPages} />
                        <Route exact path="/high-back-office-chair" component={HighBackOfficeChairPages} />
                        <Route exact path="/ironchair" component={IronChairPages} />
                        <Route exact path="/metal-master-table" component={MetalMasterTablePages} />
                        <Route exact path="/iron-stool" component={IronStoolPages} />
                        <Route exact path="/forge-coffee-table" component={ForgeCoffeeTablePages} />
                        <Route exact path="/iron-forge-chair" component={IronForgeChairPages} />
                        <Route exact path="/iron-seat" component={IronSeatPages} />
                        <Route exact path="/iron-shoe-rack" component={IronShoeRackPages} />
                        <Route exact path="/iron-bar-stool" component={IronBarStoolPages} />
                        <Route exact path="/iron-cushioned-stool" component={IronCushionedStoolPages} />
                        <Route exact path="/iron-table" component={IronTablePages} />
                        <Route exact path="/mandir" component={MandirPages} />
                        <Route exact path="/wall-hanging-divine-crafts" component={WallHangingDivineCraftsPages} />
                        <Route exact path="/wooden-devotion-mandir" component={WoodenDevotionMandirPages} />
                        <Route exact path="/temple-wood-with-storage" component={TempleWoodWithStoragePages} />
                        <Route exact path="/crafted-mandir" component={CraftedMandirPages} />
                        <Route exact path="/home-temple" component={HomeTemplePages} />
                        <Route exact path="/wooden-home-temple" component={WoodenHomeTemplePages} />
                        <Route exact path="/spacious-shelf-mandir" component={SpaciousShelfMandirPages} />
                        <Route exact path="/beautifully-carved-mandir" component={BeautifullyCarvedMandirPages} />
                        <Route exact path="/mdf-wooden-temple" component={MDFWoodenTemplePages} />
                        <Route exact path="/cabinet" component={CabinetPages} />
                        <Route exact path="/officefurniture" component={OfficeFurniturePages} />
                        <Route exact path="/seating-space" component={SeatingSpacePages} />
                        <Route exact path="/office-counter-space" component={OfficeCounterSpacePages} />
                        <Route exact path="/employee-desk" component={EmployeeDeskPages} />
                        <Route exact path="/modular-workspace-system" component={ModularWorkspaceSystemPages} />
                        <Route exact path="/office-desk" component={OfficeDeskPages} />
                        <Route exact path="/reception-area" component={ReceptionPages} />
                        <Route exact path="/workplace-furnishings" component={WorkplaceFurnishingsPages} />
                        <Route exact path="/workspace-area" component={WorkspacePages} />
                        <Route exact path="/swift-storage-file-cabinet" component={SwiftStorageFileCabinetPages} />
                        <Route exact path="/comforter" component={ComforterPages} />
                        <Route exact path="/wooden-chair-cream" component={WoodenChairCreamPages} />
                        <Route exact path="/revolving-lounge-chair" component={RevolvingLoungeChairPages} />
                        <Route exact path="/lounge-chair-pink" component={LoungeChairPinkPages} />
                        <Route exact path="/lounge-chair-navy-blue" component={LoungeChairNavyBluePages} />
                        <Route exact path="/lounge-chair-yellow" component={LoungeChairYellowPages} />
                        <Route exact path="/rocking-chair" component={RockingChairPages} />
                        <Route exact path="/floral-print-lounge-chair" component={FloralPrintLoungeChairPages} />
                        <Route exact path="/arm-chair-printed" component={ArmChairPrintedPages} />
                        <Route exact path="/lounge-chair-beige" component={LoungeChairBeigePages} />
                        <Route exact path="/sidetable" component={SideTablePages} />
                        <Route exact path="/round-nest-table" component={RoundNestTablePages} />
                        <Route exact path="/bed-side-table" component={BedSideTablePages} />
                        <Route exact path="/sheesham-wood-side-table" component={SheeshamWoodSideTablePages} />
                        <Route exact path="/solid-wood-coffee-table" component={SolidWoodCoffeeTablePages} />
                        <Route exact path="/gold-finish-side-table" component={GoldFinishSideTablePages} />
                        <Route exact path="/nest-of-table" component={NestOfTablePages} />
                        <Route exact path="/round-side-table" component={RoundSideTablePages} />
                        <Route exact path="/side-end-table" component={SideEndTablePages} />
                        <Route exact path="/side-and-end-table" component={SideAndEndTablePages} />
                        <Route exact path="/kitchen" component={KitchenPages} />
                        <Route exact path="/sliding-wardrobe" component={SlidingWardrobePages} />
                        <Route exact path="/straight-kitchen-design" component={StraightKitchenDesignPages} />
                        <Route exact path="/bar-cabinet" component={BarCabinetPages} />
                        <Route exact path="/wall-mounted-bar-cabinet" component={WallMountedBarCabinetPages} />
                        <Route exact path="/wine-bar-unit" component={WineBarUnitPages} />
                        <Route exact path="/simple-kitchen-design" component={SimpleKitchenDesignPages} />
                        <Route exact path="/island-kitchen-design" component={IslandKitchenDesignPages} />
                        <Route exact path="/l-shaped-kitchen-design" component={LShapedKitchenDesignPages} />
                        <Route exact path="/modular-kitchen-design" component={ModularKitchenDesignPages} />
                        <Route exact path="/puffy" component={PuffyPages} />
                        <Route exact path="/partitions" component={PartitionsPages} />
                        <Route exact path="/dressingtable" component={DressingTablePages} />
                        <Route exact path="/diningchair" component={DiningChairPages} />
                        <Route exact path="/cornershelfs" component={CornerShelfsPages} />
                        <Route exact path="/sofaslist" component={SofasList} />
                        <Route exact path="/doglist" component={DogList} />
                        <Route exact path="/about-us" component={AdoptionsPages} />
                        <Route exact path="/terms-of-use" component={TermsofUse} />
                        <Route exact path="/privacy-and-policy" component={Privacypolicy} />
                        <Route exact path="/contacts" component={ContactPage} />
                        <Route exact path="/interiors" component={interiors} />
                        <Route exact path="/blogs" component={BlogPage} />
                        <Route exact path="/blog-details" component={BlogDetailsPage} />
                        <Route exact path="/best-sellers" component={ShopPages} />
                        <Route exact path="/shop-details" component={ShopDetailsPage} />
                        <Route exact path="/Modular" component={BreederPage} />
                        <Route exact path="/hexagon-wall-shelf" component={HexagonWallShelf} />
                        <Route exact path="/dining-chair-without-fabric" component={DiningChairWithoutFabric} />
                        <Route exact path="/dining-chair-with-fabric" component={DiningChairWithFabric} />
                        <Route exact path="/corner-shelf" component={CornerShelf} />
                        <Route exact path="/corner-shelf-set-three" component={CornerShelfSetof3} />
                        <Route exact path="/corner-shelves" component={CornerShelves} />
                        <Route exact path="/dining-chair-without-cushions" component={DiningChairWithoutCushions} />
                        <Route exact path="/square-wall-shelf" component={SquareWallShelf} />
                        <Route exact path="/wooden-corner-shelf" component={WoodenCornerShelf} />
                        <Route exact path="/corner-stand-shelf" component={CornerStandShelf} />
                        <Route exact path="/round-wall-shelf" component={RoundWallShelf} />
                        <Route exact path="/dining-chair" component={DiningChairr} />
                        <Route exact path="/wall-shelves" component={WallShelves} />
                        <Route exact path="/dressing-table-with-storage" component={DressingTableWithStorage} />
                        <Route exact path="/dining-chair-mustard" component={DiningChairMustard} />
                        <Route exact path="/dining-chair-blue" component={DiningChairBlue} />
                        <Route exact path="/cushioned-dining-chair" component={CushionedDiningChair} />
                        <Route exact path="/dining-chair" component={DiningChair} />
                        <Route exact path="/set-of-two-Dining-Chair" component={DiningChairSetofTwo} />
                        <Route exact path="/modular" component={BreederPage} />
                        <Route exact path="/breeder-details" component={BreederDetailsPage} />
                        <Route exact path="/sheesham-dressing-table" component={SheeshamDressingtable} />
                        <Route exact path="/dressing-table-with-shelves-and-storage" component={DressingTableWithShelvesandStorage} />
                        <Route exact path="/dressing-table-with-stool-storage" component={DressingTableWithStoolStorage} />
                        <Route exact path="/home-two" component={HometwoPage} />
                        <Route exact path="/wood-dressing-table-with-shelf" component={WoodDressingTableWithShelf} />
                        <Route exact path="/dressing-table-with-shelf" component={DressingTableWithShelfPages} />
                        <Route exact path="/dressing-table-with-drawers" component={DressingTableWithDrawers} />
                        <Route exact path="/dressing-table" component={DressingTable} />
                        <Route exact path="/wood-waves-divider" component={WoodWavesDivider} />
                        <Route exact path="/wood-dressing-table" component={WoodDressingTable} />
                        <Route exact path="/wood-crafted-dividers" component={WoodCraftedDividers} />
                        <Route exact path="/partition-crafters" component={PartitionCrafters} />
                        <Route exact path="/home-dividers" component={HomeDividers} />
                        <Route exact path="/wooden-screens" component={WoodenScreens} />
                        <Route exact path="/divide-craft-furnish" component={DivideCraftFurnish} />
                        <Route exact path="/wooden-room-partitions" component={WoodenRoomPartitions} />
                        <Route exact path="/plywood-partitions" component={PlywoodPartitions} />
                        <Route exact path="/wooden-divider-shapes" component={WoodenDividerShapes} />
                        <Route exact path="/printed-stool-grey" component={PrintedStool} />
                        <Route exact path="/wooden-stool-irish-cream" component={WoodenStool} />
                        <Route exact path="/printed-wooden-stool" component={PrintedWoodenStool} />
                        <Route exact path="/solid-wooden-stool" component={SolidWoodenStool} />
                        <Route exact path="/stool-dark-blue" component={StoolDarkBlue} />
                        <Route exact path="/wooden-stool" component={WoodenStools} />
                        <Route exact path="/bar-stool" component={BarStool} />
                        <Route exact path="/stool-multicolour" component={StoolMulticolour} />
                        <Route exact path="/stool-with-wooden-legs" component={StoolwithWoodenLegs} />
                        <Route exact path="/return-policy" component={ReturnPolicy} />
                        <Route exact path="/study-table-cum-shelf" component={StudyTableCumShelf} />
                        <Route exact path="/study-table-with-1-drawer" component={StudyTablewithDrawer} />
                        <Route exact path="/study-table-with-4-drawers" component={StudyTablewithfourDrawers} />
                        <Route exact path="/study-table-with-3-drawers" component={StudyTablewithThreeDrawers} />
                        <Route exact path="/study-table-with-open-storage" component={StudyTablewitOpenStorage} />
                        <Route exact path="/study-table-with-keyboard-tray-and-side-storage" component={StudyTablewithKeyboardTrayandSideStorage} />
                        <Route exact path="/study-table-with-2-drawers-and-cabinet" component={StudyTablewithDrawersandCabinet} />
                        <Route exact path="/study-table-with-book-shelf" component={StudyTablewithBookShelf} />
                        <Route exact path="/wall-mounted-study-table" component={WallMountedStudytable} />
                        <Route exact path="/seesham-cane-vintage-bed" component={SeeshamCaneVintageBed} />
                        <Route exact path="/center-table-with-storage" component={CenterTableWithStorage} />
                        <Route exact path="/cane-handwork-cabinet" component={CaneHandworkCabinet} />
                        <Route exact path="/wooden-podium-table" component={WoodenPodiumTable} />
                        <Route exact path="/everyday-stool" component={Everydaystool} />
                        <Route exact path="/homeage-wooden-chair" component={HomeageWoodenChair} />
                        <Route exact path="/cane-wooden-chair-set" component={CaneWoodenChairSet} />
                        <Route exact path="/wooden-shoe-rack" component={WoodenShoeRack} />
                        <Route exact path="/wooden-sofa-set-in-3+1+1-seating" component={WoodenSofaSetinSeating} />
                        <Route exact path="/six-seater-dining-sets" component={SixSeaterDiningSet} />
                        <Route exact path="/wooden-sofa-set" component={WalnutFinishSofaset} />
                        <Route exact path="/5-seater-sheesham-set" component={FiveSeaterSheeshamSet} />
                        <Route exact path="/walnut-finish-sofa-set" component={WoodenSofaSet} />
                        <Route exact path="/l-shaped-corner-sofa-with-storage" component={LShapedCornerSofawithStorage} />
                        <Route exact path="/3+1+1-wooden-sofa-set" component={ThreeandoneWoodenSofaSet} />
                        <Route exact path="/rectangular-wood-table-Beige" component={RectangularWoodTableBeige} />
                        <Route exact path="/rectangular-solid-wood-coffee-table" component={RectangularSolidWoodCoffeeTable} />
                        <Route exact path="/sofa-cum-bed" component={SofaCumBed} />
                        <Route exact path="/solid-wooden-table" component={SolidWoodenTable} />
                        <Route exact path="/wooden-table-with-open-space" component={WoodenTablewithOpenSpace} />
                        <Route exact path="/square-wooden-table" component={SquareWoodenTable} />
                        <Route exact path="/square-table-with-4-stools" component={SquareTablewith4Stools} />
                        <Route exact path="/rectangular-wood-table" component={RectangularWoodTableSpace} />
                        <Route exact path="/side-storage-sheesham-set" component={SideStorageSheeshamSet} />
                        <Route exact path="/rectangular-solid-wood-table" component={RectangularSolidWoodTable} />
                        <Route exact path="/everyday-three-seater-bench" component={EverydayThreeSeaterBench} />
                        <Route exact path="/2-door-wardrobe-with-mirror" component={TwoDoorWardrobewithMirror} />
                        <Route exact path="/3-door-multi-utility-wardrobe" component={ThreeDoorMultiUtilityWardrobe} />
                        <Route exact path="/1-door-wardrobe" component={OneDoorWardrobe} />
                        <Route exact path="/2-door-multi-utility-wardrobe" component={TowDoorMultiUtilityWardrobe} />
                        <Route exact path="/boho-small-size-wardrobe" component={BohoSmallSizeWardrobe} />
                        <Route exact path="/2-door-almirah" component={TowDoorAlmirah} />
                        <Route exact path="/3-door-wardrobe" component={ThreeDoorWardrobe} />
                        <Route exact path="/wooden-table-with-glass-at-top" component={WoodenTablewithGlassattop} />
                        <Route exact path="/5-seater-wooden-sofa-set-with-side-storage" component={FiveSeaterWoodenSofaSetwithSideStorage} />
                        <Route exact path="/multi-storage-sheesham-wardrobe" component={MultiStorageSheeshamWardrobe} />
                        <Route exact path="/4-door-wardrobe-with-mirror" component={DoorWardrobewithMirror} />
                    </Switch>
                </ScrollToTop>
            </Router>
        </div>
    );
}

export default Routes;
