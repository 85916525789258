import React from "react";
import { Link } from 'react-router-dom'



function BlogsHome() {
  return (
    <section className="blog-area pt-110 pb-60 breeds-services-pt blog-area-pb">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-7 col-lg-9">
            <div className="section-title text-center mb-65">
              <div className="section-icon"><img src="img/icon/icon.png" alt="" /></div>
              <h2 className="sub-title main-slider-font-weight">Find Us Everywhere You Shop</h2>
              <h2 className="title font-size main-slider-font-weight">Discover Your Perfect Peice</h2>
              <p>At Maheshwari Furniture, we believe your furniture journey should be convenient and accessible. That's why we're thrilled to offer our carefully crafted pieces across multiple platforms</p>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-4 col-sm-4">
            <div className="blog-post-item mb-50">
              <div className="blog-post-thumb">
                <Link to="#" aria-label="#"><img src="img/blog/amazon.png" alt="" /></Link>
                <div className="blog-post-tag">
                </div>
              </div>
              <div className="blog-post-content">
                <h3 className="title faq-font-weight"><Link to="#">Find Our Collection on Amazon</Link></h3>
                <p>Explore a curated selection of our most popular furniture pieces on Amazon, allowing you to enjoy the ease and familiarity of their platform.</p>
                <Link to="#" aria-label="#" className="read-more">Shop Now <img src="img/icon/arrow.png" alt="" /></Link>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-4">
            <div className="blog-post-item mb-50">
              <div className="blog-post-thumb">
                <Link to="#"aria-label="#"><img src="img/blog/flipkart.png" alt="" /></Link>
                <div className="blog-post-tag">
                </div>
              </div>
              <div className="blog-post-content">
                <h3 className="title faq-font-weight"><Link to="#">Explore Full Range on Flipkart</Link></h3>
                <p>Discover our complete furniture collection on Flipkart, showcasing a wider range of styles and customizable options.</p>
                <Link to="#" aria-label="#" className="read-more">Shop Now <img src="img/icon/arrow.png" alt="" /></Link>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-4">
            <div className="blog-post-item mb-50">
              <div className="blog-post-thumb">
                <Link to="#" aria-label="#"><img src="img/blog/facebook.png" alt="" /></Link>
                <div className="blog-post-tag">
                </div>
              </div>
              <div className="blog-post-content">
                <h3 className="title faq-font-weight"><Link to="#">Connect Us on Facebook Shop</Link></h3>
                <p> Engage with our brand story and discover new arrivals on Facebook Shop and maximize your shopping experience.</p>
                <Link to="#" aria-label="#" className="read-more">Shop Now <img src="img/icon/arrow.png" alt="" /></Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BlogsHome;
