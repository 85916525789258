import React from 'react';
import SolidWoodCoffeeTableBreadcumb from '../components/ourshop/SideTableshopdetails/SolidWoodCoffeeTableBreadcumb'
import SolidWoodCoffeeTableArea from '../components/ourshop/SideTableshopdetails/SolidWoodCoffeeTableArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function SolidWoodCoffeeTablePages() {
    return (
        <>
            <Header />
            <main>
                <SolidWoodCoffeeTableBreadcumb />
                <SolidWoodCoffeeTableArea />
            </main>
            <Footer />
        </>
    )
}

export default SolidWoodCoffeeTablePages; 