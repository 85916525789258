import React from 'react';
import BlogBreadcumb from '../components/blogs/BlogBreadcumb';
import BlogContent from '../components/blogs/BlogContent';
import Header from "../components/Header";
import Footer from "../components/Footer";

function BlogPage() {
  return (
	  <>
		  <Header/>
		  <main>
			  <BlogBreadcumb/>
			  <BlogContent/>
		  </main>
		  <Footer/>
	  </>
  )
}

export default BlogPage;
