import React, { useEffect } from 'react';
import BreadCumb from '../components/doglists/BreadCumb';
import CatalogShop from '../components/CatalogShop'
import Faq from '../components/Faq'
import BreedServices from '../components/BreedServices'
import NewsLetter from '../components/NewsLetter'
import Header from "../components/Header";
import Footer from "../components/Footer";


function CatalogList() {
	useEffect(() => {
        document.title = 'Explore Our Furniture Categories | Maheshwari Furniture';
    }, []);

  return(
	  <>
		  <Header/>
		  <main>
			  <BreadCumb/>
			  <CatalogShop/>
			  <Faq afterElment="faq-area-two"/>
			  <BreedServices/>
			  <NewsLetter/>
		  </main>
		  <Footer/>
	  </>
  )
}

export default CatalogList;
