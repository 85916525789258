import React from 'react';
import BreederDetBread from '../components/breederDetails/BreederDetBread'
import BreederContent from '../components/breederDetails/BreederContent'
import AdoptionFaq from '../components/adoptions/AdoptionFaq'
import AdoptionPuppies from '../components/adoptions/AdoptionPuppies'
import Header from "../components/Header";
import Footer from "../components/Footer";
function BreederDetailsPage() {
  return (
	  <>
		  <Header/>
		  <main>
			  <BreederDetBread/>
			  <BreederContent/>
			  <AdoptionFaq/>
			  <AdoptionPuppies/>
		  </main>
		  <Footer/>
	  </>
  )
}

export default BreederDetailsPage;
