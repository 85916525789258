import React, { useEffect } from 'react';
import Hero from '../components/interiors/Hero'
import CounterArea from '../components/interiors/CounterArea'
import NewsLetter from '../components/NewsLetter'
import Services from '../components/interiors/Services'
import Brand from '../components/Brand'
import Header from "../components/Header";
import Footer from "../components/Footer";


function ContactPage() {
	useEffect(() => {
        document.title = 'Get in Touch with Maheshwari Furniture | Contact Information';
    }, []);
	return (
		<>
			<Header />
			<main>
				<Hero />
				<Services />
				<CounterArea />
				<Brand />
				<NewsLetter />

			</main>
			<Footer />
		</>
	)
}

export default ContactPage;
