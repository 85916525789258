import React from 'react';
import WorkspaceBreadcumb from '../components/ourshop/OfficeFurnitureshopdetails/WorkspaceBreadcumb'
import WorkspaceArea from '../components/ourshop/OfficeFurnitureshopdetails/WorkspaceArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function WorkspacePages() {
    return (
        <>
            <Header />
            <main>
                <WorkspaceBreadcumb />
                <WorkspaceArea />
            </main>
            <Footer />
        </>
    )
}

export default WorkspacePages; 