import React, { useEffect } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom'
import $ from 'jquery';

function Header() {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");

  const handleActive = (e) => {
    document.querySelectorAll('.main-menu ul li').forEach(el => {
      el.classList.remove('active');
    })
    e.target.parentNode.classList += ' active';
  }
  const subActive = (e) => {
    document.querySelectorAll('.main-menu ul li').forEach(el => {
      el.classList.remove('active');
    })
    e.target.parentNode.parentNode.parentNode.classList += ' active';
  }
  useEffect(() => {


    //SubMenu Dropdown Toggle
    if ($('.menu-area li.menu-item-has-children ul').length) {
      $('.menu-area .navigation li.menu-item-has-children').append('<div class="dropdown-btn"><span class="fas fa-angle-down"></span></div>');

    }
    if ($('.mobile-menu').length) {

      var mobileMenuContent = $('.menu-area .main-menu').html();
      $('.mobile-menu .menu-box .menu-outer').append(mobileMenuContent);

      //Dropdown Button
      $('.mobile-menu li.menu-item-has-children .dropdown-btn').on('click', function () {
        $(this).toggleClass('open');
        $(this).prev('ul').slideToggle(500);
      });
      //Menu Toggle Btn
      $('.mobile-nav-toggler').on('click', function () {
        $('body').addClass('mobile-menu-visible');
      });

      //Menu Toggle Btn
      $('.menu-backdrop, .mobile-menu .close-btn').on('click', function () {
        $('body').removeClass('mobile-menu-visible');
      });
    }

    $(".navbar-toggle").on('click', function () {
      $(".navbar-nav").addClass("mobile_menu");
    });
    $(".navbar-nav li a").on('click', function () {
      $(".navbar-collapse").removeClass("show");
    });

    $(".header-search > a").on('click', function () {
      $(".search-popup-wrap").slideToggle();
      return false;
    });

    $(".search-close").on('click', function () {
      $(".search-popup-wrap").slideUp(500);
    });
  }, [])



  return (
    <header>
      <div className="header-top-area">
        <div className="container custom-container">
          <div className="row align-items-center">
            <div className="col-md-7">
              <div className="header-top-left">
                <ul>
                  <li>Call us: +91 94140-45241</li>
                  <li><i className="far fa-clock" />Opening Hours: 10:00 am - 7:00 pm (Mon - Sun)</li>
                </ul>
              </div>
            </div>
            <div className="col-md-5 header-top-social-hidden">
              <div className="header-top-right">
                <ul className="header-top-social">
                  <li className="follow">Follow :</li>
                  <li><a href="https://www.facebook.com/maheshwarifurniture"aria-label="Facebook"><i className="fab fa-facebook-f icon-size" /></a></li>
                  <li><a href="https://www.facebook.com/Maheshwari2019?mibextid=qWsEUC" aria-label="Facebook"><i className=" fas fa-flag icon-size" /></a></li>
                  <li><a href="https://www.instagram.com/maheshwarifurniture.online" aria-label="instagram"><i className="fab fa-instagram icon-size" /></a></li>
                  <li><a href="https://www.youtube.com/@maheshwarifurniture" aria-label="youtube"><i className="fab fa-youtube icon-size" /></a></li>
                  <li><a href="https://wa.me/919414045241" aria-label="number"><i className="fab fa-whatsapp icon-size" /></a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="sticky-header" className="menu-area menu-area-padding">
        <div className="container custom-container">
          <div className="row">
            <div className="col-12 header-top-display-item">
                 <div className="mobile-nav-toggler mobile-toggler-top mobile-toggler-top-icon">
                    <ul className="header-top-social margin-top-0">
                      <li><a href="https://www.facebook.com/maheshwarifurniture"aria-label="Facebook"><i className="fab fa-facebook-f icon-size icon-color" /></a></li>
                      <li><a href="https://www.facebook.com/Maheshwari2019?mibextid=qWsEUC" aria-label="Facebook"><i className=" fas fa-flag icon-size icon-color" /></a></li>
                      <li><a href="https://www.instagram.com/maheshwarifurniture.online" aria-label="instagram"><i className="fab fa-instagram icon-size icon-color" /></a></li>
                      <li><a href="https://www.youtube.com/@maheshwarifurniture" aria-label="youtube"><i className="fab fa-youtube icon-size icon-color" /></a></li>
                      <li><a href="https://wa.me/919414045241" aria-label="number"><i className="fab fa-whatsapp icon-size icon-color" /></a></li>
                    </ul>
                    <i className="fas fa-bars" />
                </div>
              <div className="menu-wrap">
                <nav className="menu-nav show">
                  <div className="logo"><a href="/"><img className="header-logo-width" src="img/logo/logo-new.svg" alt="logo" aria-label="logo"/></a></div>
                  <div className="navbar-wrap main-menu d-none d-lg-flex">
                    <ul className="navigation">
                      <li className={splitLocation[1] === "" ? "active" : ""}>
                        <Link to="/" aria-label="">Home</Link>
                      </li>
                      <li className="menu-item-has-children"><Link to="/best-sellers"
                        onClick={(e) => handleActive(e)}>Best Sellers</Link>
                      </li>
                      <li className={splitLocation[1] === "categories"
                        || splitLocation[1] === "sofas"
                        || splitLocation[1] === "dining-sets" ? "active" : ""}>
                        <Link to="/categories">Categories</Link>
                      </li>
                      <li><Link to="/about-us"aria-label="" onClick={(e) => handleActive(e)}>About Us</Link></li>
                      <li className="menu-item-has-children"><Link to="/modular"
                        onClick={(e) => handleActive(e)}>Modular</Link>
                      </li>
                      <li><Link to="/contacts"aria-label="" onClick={(e) => handleActive(e)}>contacts</Link></li>
                      <li><Link to="/interiors"aria-label="" onClick={(e) => handleActive(e)}>Interiors</Link></li>

                    </ul>
                  </div>
                  <div className="header-action header-action-page">
                    <ul>
                      <li className="header-search"><a href="/#" aria-label="#"><i className="" /></a></li>
                      <li className="header-shop-cart"><a href="/#" aria-label="#"><i className="" /><span></span></a>
                        <ul className="minicart">
                          <li className="d-flex align-items-start">
                            <div className="cart-img">
                              <a href="/#" aria-label=""><img src="img/product/cart_p01.jpg" aria-label="#" alt="" /></a>
                            </div>
                            <div className="cart-content">
                              <h4><a href="/#" aria-label="#">The King Charles Spaniel</a></h4>
                              <div className="cart-price">
                                <span className="new">$229.9</span>
                                <span><del>$229.9</del></span>
                              </div>
                            </div>
                            <div className="del-icon">
                              <a href="/#" aria-label="#"><i className="far fa-trash-alt" /></a>
                            </div>
                          </li>
                          <li className="d-flex align-items-start">
                            <div className="cart-img">
                              <a href="/#" aria-label="#"><img src="img/product/cart_p02.jpg" alt="" /></a>
                            </div>
                            <div className="cart-content">
                              <h4><a href="/#" aria-label="#">The Labrador Retriever</a></h4>
                              <div className="cart-price">
                                <span className="new">$229.9</span>
                                <span><del>$229.9</del></span>
                              </div>
                            </div>
                            <div className="del-icon">
                              <a href="/#" aria-label="#"><i className="far fa-trash-alt" /></a>
                            </div>
                          </li>
                          <li>
                            <div className="total-price">
                              <span className="f-left">Total:</span>
                              <span className="f-right">$239.9</span>
                            </div>
                          </li>
                          <li>
                            <div className="checkout-link">
                              <a href="/#" aria-label="#">Shopping Cart</a>
                              <a className="black-color" href="/#" aria-label="#">Checkout</a>
                            </div>
                          </li>
                        </ul>
                      </li>
                      <li className="header-btn"><Link to="/contacts" className="btn">Contact Us <img className="phone-icon-padding" src="img/icon/phone.png"  srcSet="img/icon/phone.png 480w, img/icon/phone.png 1080w" sizes="50vw" alt="" /></Link></li>
                    </ul>
                  </div>
                </nav>
              </div>

              <div className="mobile-menu">
                <nav className="menu-box">
                  <div className="close-btn"><i className="fas fa-times" /></div>
                  <div className="nav-logo"><Link to="/"><img src="img/logo/logo.png" alt="" title='true' /></Link>
                  </div>
                  <div className="menu-outer">

                  </div>
                  <div className="social-links">
                    <ul className="clearfix">
                      <li><a href="/#" aria-label="#"><span className="fab fa-twitter" /></a></li>
                      <li><a href="/#" aria-label="#"><span className="fab fa-facebook-square" /></a></li>
                      <li><a href="/#" aria-label="#"><span className="fab fa-pinterest-p" /></a></li>
                      <li><a href="/#" aria-label="#"><span className="fab fa-instagram" /></a></li>
                      <li><a href="/#" aria-label="#"><span className="fab fa-youtube" /></a></li>
                    </ul>
                  </div>
                </nav>
              </div>
              <div className="menu-backdrop" />

            </div>
          </div>
        </div>
        <div className="header-shape" />
      </div>
      <div className="search-popup-wrap" tabIndex={-1} role="dialog" aria-hidden="true">
        <div className="search-close">
          <span><i className="fas fa-times" /></span>
        </div>
        <div className="search-wrap text-center">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2 className="title">... Search Here ...</h2>
                <div className="search-form">
                  <form>
                    <input type="text" name="search" placeholder="Type keywords here" />
                    <button className="search-btn"><i className="fas fa-search" /></button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </header>
  )
}

export default Header