import React from 'react';
import CornerSofaDetailsBreadcumb from '../components/ourshop/Sofashopdetails/CornerSofaDetailsBreadcumb'
import CornerSofaDetailsArea from '../components/ourshop/Sofashopdetails/CornerSofaDetailsArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function CornerSofaDetailsPage() {
    return (
        <>
            <Header />
            <main>
                <CornerSofaDetailsBreadcumb />
                <CornerSofaDetailsArea />
            </main>
            <Footer />
        </>
    )
}

export default CornerSofaDetailsPage;