import React from 'react';
import LoungeChairNavyBlueBreadcumb from '../components/ourshop/Comfortersshopdetails/LoungeChairNavyBlueBreadcumb'
import LoungeChairNavyBlueArea from '../components/ourshop/Comfortersshopdetails/LoungeChairNavyBlueArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function LoungeChairNavyBluePages() {
    return (
        <>
            <Header />
            <main>
                <LoungeChairNavyBlueBreadcumb />
                <LoungeChairNavyBlueArea />
            </main>
            <Footer />
        </>
    )
}

export default LoungeChairNavyBluePages; 