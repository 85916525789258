import React from 'react';
import TowDoorAlmirahHero from '../components/ourshop/wardrobe/TowDoorAlmirahHero'
import TowDoorAlmirahHome from '../components/ourshop/wardrobe/TowDoorAlmirahHome'
import Header from "../components/Header";
import Footer from "../components/Footer";

function TowDoorAlmirah() {
	return (
		<>
			<Header />
			<main>
				<TowDoorAlmirahHome />
				<TowDoorAlmirahHero />
			</main>
			<Footer />
		</>
	)
}

export default TowDoorAlmirah;
