import React from 'react';
import MultiStorageSheeshamWardrobeHero from '../components/ourshop/wardrobe/MultiStorageSheeshamWardrobeHero'
import MultiStorageSheeshamWardrobeHome from '../components/ourshop/wardrobe/MultiStorageSheeshamWardrobeHome'
import Header from "../components/Header";
import Footer from "../components/Footer";

function MultiStorageSheeshamWardrobe() {
	return (
		<>
			<Header />
			<main>
				<MultiStorageSheeshamWardrobeHome />
				<MultiStorageSheeshamWardrobeHero />
			</main>
			<Footer />
		</>
	)
}

export default MultiStorageSheeshamWardrobe;
