import React from 'react';
import BreadCumb from '../components/doglists/BreadCumb';
import AdoptionShop from '../components/AdoptionShop'
import Faq from '../components/Faq'
import BreedServices from '../components/BreedServices'
import NewsLetter from '../components/NewsLetter'
import Header from "../components/Header";
import Footer from "../components/Footer";


function DogList() {
  return(
	  <>
		  <Header/>
		  <main>
			  <BreadCumb/>
			  <AdoptionShop/>
			  <Faq afterElment="faq-area-two"/>
			  <BreedServices/>
			  <NewsLetter/>
		  </main>
		  <Footer/>
	  </>
  )
}

export default DogList;
