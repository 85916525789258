import React from 'react';
import CraftedMandirBreadcumb from '../components/ourshop/Mandirshopdetails/CraftedMandirBreadcumb'
import CraftedMandirArea from '../components/ourshop/Mandirshopdetails/CraftedMandirArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function CraftedMandirPages() {
    return (
        <>
            <Header />
            <main>
                <CraftedMandirBreadcumb />
                <CraftedMandirArea />
            </main>
            <Footer />
        </>
    )
}

export default CraftedMandirPages; 