import React from 'react';
import StoolwithWoodenLegsDetailsArea from '../components/ourshop/Puffy/StoolwithWoodenLegsDetailsArea'
import StoolwithWoodenLegsArea from '../components/ourshop/Puffy/StoolwithWoodenLegsArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function StoolwithWoodenLegs() {
	return (
		<>
			<Header />
			<main>
				<StoolwithWoodenLegsDetailsArea />
				<StoolwithWoodenLegsArea/>
			</main>
			<Footer />
		</>
	)
}

export default StoolwithWoodenLegs;
