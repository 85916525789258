import React from 'react';
import ShopBreadcumb from '../components/ourshop/ShopBreadcumb'
import DiningSetsArea from '../components/ourshop/DiningSetsArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function DiningSetsPages(){
	return(
		<>
			<Header/>
			<main>
				<ShopBreadcumb/>
				<DiningSetsArea/>
			</main>
			<Footer/>
		</>
	)
}

export default DiningSetsPages;
