import React from 'react';
import DressingTableWithStorageDetailsArea from '../components/ourshop/Dressingtable/DressingTableWithStorageDetailsArea'
import DressingTableWithStorageArea from '../components/ourshop/Dressingtable/DressingTableWithStorageArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function DressingTableWithStorage() {
	return (
		<>
			<Header />
			<main>
				<DressingTableWithStorageDetailsArea />
				<DressingTableWithStorageArea />
			</main>
			<Footer />
		</>
	)
}

export default DressingTableWithStorage;
