import React from 'react';

function AboutUsTwo() {
  return (
    <section className="py-md-5 py-3 text-dark bg-white">
      <div className="container">
        <div className="row justify-content-center justify-content-md-start">
          <div className="col-md-12 mb-4 text-black text-center">
            <h2 className="fw-bold mb-md-4 main-slider-font-weight">Providing <span className="yellow-text">Tailored Modular Storage </span> Solutions</h2>
            <p className="text-justify">At Maheshwari Furniture, we specialize in delivering top-tier modular storage services, crafting customized design layouts to suit each customer's budget and needs. Our solutions optimize every inch of your space, ensuring maximum functionality, durability, and aesthetic appeal throughout your home.</p>
          </div>
          <div className=" col-12 row align-items-stretch justify-content-center text-black">
            <div className="col-md-4 col-6 p-2">
              <div className="p-3 h-100">
                <div className="flex-column">
                  <div className="col-md-12 col-12 p-0 text-center mb-3">
                    <img
                      src="img/moduler_banner/1.png"
                      className="w-100"
                      alt="" />
                  </div>
                  <div className="col-md-12 col-12 p-0 text-center text-md-start">
                    <h5 className="main-slider-font-weight">Walk-In Wardrobe</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-6 p-2">
              <div className="p-3 h-100">
                <div className="flex-column">
                  <div className="col-md-12 col-12 p-0 text-center mb-3">
                    <img
                      src="img/moduler_banner/2.png"
                      className="w-100"
                      alt="" />
                  </div>
                  <div className="col-md-12 col-12 p-0 text-center text-md-start">
                    <h5 className="main-slider-font-weight">Hinged Wardrobe</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-6 p-2">
              <div className="p-3 h-100">
                <div className="flex-column">
                  <div className="col-md-12 col-12 p-0 text-center mb-3">
                    <img
                      src="img/moduler_banner/3.png"
                      className="w-100"
                      alt="" />
                  </div>
                  <div className="col-md-12 col-12 p-0 text-center text-md-start">
                    <h5 className="main-slider-font-weight"> Modular Study Area</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-6 p-2">
              <div className="p-3 h-100">
                <div className="flex-column">
                  <div className="col-md-12 col-12 p-0 text-center mb-3">
                    <img
                      src="img/moduler_banner/4.png"
                      className="w-100"
                      alt="" />
                  </div>
                  <div className="col-md-12 col-12 p-0 text-center text-md-start">
                    <h5 className="main-slider-font-weight">Parallel Modular Kitchen</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-6 p-2">
              <div className="p-3 h-100">
                <div className="flex-column">
                  <div className="col-md-12 col-12 p-0 text-center mb-3">
                    <img
                      src="img/moduler_banner/5.png"
                      className="w-100"
                      alt="" />
                  </div>
                  <div className="col-md-12 col-12 p-0 text-center text-md-start">
                    <h5 className="main-slider-font-weight">G-Shaped Modular Kitchen</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-6 p-2">
              <div className="p-3 h-100">
                <div className="flex-column">
                  <div className="col-md-12 col-12 p-0 text-center mb-3">
                    <img
                      src="img/moduler_banner/6.png"
                      className="w-100"
                      alt="" />
                  </div>
                  <div className="col-md-12 col-12 p-0 text-center text-md-start">
                    <h5 className="main-slider-font-weight">Island Modular Kitchen</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AboutUsTwo;
