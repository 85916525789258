import React from 'react';
import HomeageWoodenChairHero from '../components/ourshop/WoodenCane/HomeageWoodenChairHero'
import HomeageWoodenChairHome from '../components/ourshop/WoodenCane/HomeageWoodenChairHome'
import Header from "../components/Header";
import Footer from "../components/Footer";

function HomeageWoodenChair() {
	return (
		<>
			<Header />
			<main>
				<HomeageWoodenChairHome />
				<HomeageWoodenChairHero />
			</main>
			<Footer />
		</>
	)
}

export default HomeageWoodenChair;
