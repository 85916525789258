import React from 'react';
import ShopBreadcumb from '../components/ourshop/ShopBreadcumb'
import SideTableArea from '../components/ourshop/SideTableArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function SideTablePages() {
	return (
		<>
			<Header />
			<main>
				<ShopBreadcumb />
				<SideTableArea />
			</main>
			<Footer />
		</>
	)
}

export default SideTablePages;
