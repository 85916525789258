import React from 'react';
import WoodenDevotionMandirBreadcumb from '../components/ourshop/Mandirshopdetails/WoodenDevotionMandirBreadcumb'
import WoodenDevotionMandirArea from '../components/ourshop/Mandirshopdetails/WoodenDevotionMandirArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function WoodenDevotionMandirPages() {
    return (
        <>
            <Header />
            <main>
                <WoodenDevotionMandirBreadcumb />
                <WoodenDevotionMandirArea />
            </main>
            <Footer />
        </>
    )
}

export default WoodenDevotionMandirPages; 