import React from 'react';
import WalnutFinishSofasetHero from '../components/ourshop/sheeshamsofa/WalnutFinishSofasetHero'
import WalnutFinishSofasetHome from '../components/ourshop/sheeshamsofa/WalnutFinishSofasetHome'
import Header from "../components/Header";
import Footer from "../components/Footer";

function WalnutFinishSofaset() {
	return (
		<>
			<Header />
			<main>
				<WalnutFinishSofasetHome />
				<WalnutFinishSofasetHero />
			</main>
			<Footer />
		</>
	)
}

export default WalnutFinishSofaset;
