import React from 'react';
import SideStorageSheeshamSetHero from '../components/ourshop/sheeshamsofa/SideStorageSheeshamSetHero'
import SideStorageSheeshamSetHome from '../components/ourshop/sheeshamsofa/SideStorageSheeshamSetHome'
import Header from "../components/Header";
import Footer from "../components/Footer";

function SideStorageSheeshamSet() {
	return (
		<>
			<Header />
			<main>
				<SideStorageSheeshamSetHome />
				<SideStorageSheeshamSetHero />
			</main>
			<Footer />
		</>
	)
}

export default SideStorageSheeshamSet;
