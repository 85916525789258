import React from 'react'
import { Link } from 'react-router-dom'

function Adoption() {
    return (
        <section className="adoption-area adoption-area-padding">
            <div className="container">
                <div className="row align-items-center align-items-xl-end justify-content-center">
                    <div className="col-xl-7 col-lg-6 col-md-10 order-0 order-lg-2">
                        <div className="adoption-img">
                            <img src="img/images/mf.png" alt="" />
                        </div>
                    </div>
                    <div className="col-xl-5 col-lg-6">
                        <div className="adoption-content">
                            <h2 className="title adoption-font-weight">Who <span>we</span> are ?</h2>
                            <p className="text-justify">Welcome to Maheshwari Furniture, your one-stop destination for premium furnished furniture and top-quality plywood suitable for both domestic and office settings. Our extensive range encompasses everything you need for interior decor, from exquisite beds and stylish study tables to elegant center tables and functional office furniture along with home furniture.  </p>
                            <Link to="/about-us"aria-label="" className="btn main-slider-font-weight">Explore Now </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="adoption-shape"><img src="img/images/adoption_shape.png" alt="" /></div>
        </section>
    )
}

export default Adoption;
