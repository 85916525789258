import React from 'react';


function Services() {
  return (
    <section className="why-us-section py-5 p-md-5 back-cover">
      <div className="container">
        <div className="row mx-md-6">
          <div className="col-md-12">
            <div className="why-choose-wrapper">
              <div className="why-choose display-fl why-choose-width">
                <div className="icon col-2 icon-dis-fl">
                  <img className="img-backgroun-col" src="img/moduler_banner/exceptional_craftsmining.png" alt="" />
                </div>
                <div className="col-10">
                  <h1 className="font-size-22px main-slider-font-weight">Exceptional Craftsmanship</h1>
                  <p className="text-justify">
                    Recognised for superior engineering and compared with the automobile industry,
                    Maheshwari Furniture promises the highest quality kitchen for your home.
                  </p>
                </div>
              </div>
              <div className="why-choose display-fl why-choose-width">
                <div className="icon col-2 icon-dis-fl">
                  <img className="img-backgroun-col" src="img/moduler_banner/innovative_designing.png" alt="" />
                </div>
                <div className="col-10">
                  <h2 className="font-size-22px main-slider-font-weight">Innovative Designs</h2>
                  <p className="text-justify">
                    Maheshwari Furniture’s modular solutions are known for their innovative quality,
                    functionality, durability, designs, keeping up with the latest trends.
                  </p>
                </div>
              </div>
              <div className="why-choose display-fl why-choose-width">
                <div className="icon col-2 icon-dis-fl">
                  <img className="img-backgroun-col" src="img/moduler_banner/quality_materials.png" alt="" />
                </div>
                <div className="col-10">
                  <h2 className="font-size-22px main-slider-font-weight">Quality Materials</h2>
                  <p className="text-justify">
                    We use top-quality materials, including durable cabinets, countertops, and hardware to
                    ensure the longevity and functionality of your kitchen.
                  </p>
                </div>
              </div>
              <div className="why-choose display-fl why-choose-width">
                <div className="icon col-2 icon-dis-fl">
                  <img className="img-backgroun-col" src="img/moduler_banner/customized_solutions.png" alt="" />
                </div>
                <div className="col-10">
                  <h2 className="font-size-22px main-slider-font-weight">Customized Solutions</h2>
                  <p className="text-justify">
                    We offer Tailor-made kitchen solutions that cater to your unique preferences, ensuring your
                    kitchen reflects your style and needs.
                  </p>
                </div>
              </div>
              <div className="why-choose display-fl why-choose-width">
                <div className="icon col-2 icon-dis-fl">
                  <img className="img-backgroun-col" src="img/moduler_banner/professional_installation.png" alt="" />
                </div>
                <div className="col-10">
                  <h2 className="font-size-22px main-slider-font-weight">Professional Installation</h2>
                  <p className="text-justify">
                    Our experienced team ensures a hassle-free and precise installation process, leaving you with
                    a fully functional kitchen.
                  </p>
                </div>
              </div>
              <div className="why-choose display-fl why-choose-width">
                <div className="icon col-2 icon-dis-fl">
                  <img className="img-backgroun-col" src="img/moduler_banner/space_optimization.png" alt="" />
                </div>
                <div className="col-10">
                  <h2 className="font-size-22px main-slider-font-weight">Space Optimization</h2>
                  <p className="text-justify">
                    Our modular kitchens are designed with a focus on maximizing space utilization, making the most of
                    even the smallest kitchen areas.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


  )
}

export default Services;

