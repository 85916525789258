import React from 'react';
import DiningChairSetofTwoDetailsArea from '../components/ourshop/Diningchair/DiningChairSetofTwoDetailsArea'
import DiningChairSetofTwoArea from '../components/ourshop/Diningchair/DiningChairSetofTwoArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function DiningChairSetofTwo() {
	return (
		<>
			<Header />
			<main>
				<DiningChairSetofTwoDetailsArea />
				<DiningChairSetofTwoArea />
			</main>
			<Footer />
		</>
	)
}

export default DiningChairSetofTwo;
