import React from 'react';
import IronTableBreadcumb from '../components/ourshop/IronFurnitureshopdetails/IronTableBreadcumb'
import IronTableArea from '../components/ourshop/IronFurnitureshopdetails/IronTableArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function IronTablePages() {
    return (
        <>
            <Header />
            <main>
                <IronTableBreadcumb />
                <IronTableArea />
            </main>
            <Footer />
        </>
    )
}

export default IronTablePages; 