import React from 'react';
import SlidingWardrobeBreadcumb from '../components/ourshop/Kitchenshopdetails/SlidingWardrobeBreadcumb'
import SlidingWardrobeArea from '../components/ourshop/Kitchenshopdetails/SlidingWardrobeArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function SlidingWardrobePages() {
    return (
        <>
            <Header />
            <main>
                <SlidingWardrobeBreadcumb />
                <SlidingWardrobeArea />
            </main>
            <Footer />
        </>
    )
}

export default SlidingWardrobePages;