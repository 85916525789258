import React from 'react';
import DiningTableSetDetailsBreadcumb from '../components/ourshop/Diningshopdetails/DiningTableSetDetailsBreadcumb'
import DiningTableSetDetailsArea from '../components/ourshop/Diningshopdetails/DiningTableSetDetailsArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function DiningTableSetDetailsPage() {
    return (
        <>
            <Header />
            <main>
                <DiningTableSetDetailsBreadcumb />
                <DiningTableSetDetailsArea />
            </main>
            <Footer />
        </>
    )
}

export default DiningTableSetDetailsPage;