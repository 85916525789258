import React from 'react';
import DiningWithBenchDetailsBreadcumb from '../components/ourshop/Diningshopdetails/DiningWithBenchDetailsBreadcumb'
import DiningWithBenchDetailsArea from '../components/ourshop/Diningshopdetails/DiningWithBenchDetailsArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function DiningWithBenchDetailsPage() {
    return (
        <>
            <Header />
            <main>
                <DiningWithBenchDetailsBreadcumb />
                <DiningWithBenchDetailsArea />
            </main>
            <Footer />
        </>
    )
}

export default DiningWithBenchDetailsPage;