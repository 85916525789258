import React from 'react';
import RoundWallShelfshop from '../components/ourshop/cornershelfs/RoundWallShelfshop'
import RoundWallShelfshopdetiple from '../components/ourshop/cornershelfs/RoundWallShelfshopdetiple'
import Header from "../components/Header";
import Footer from "../components/Footer";

function RoundWallShelf() {

	return (
		<>
			<Header />
			<main>
				<RoundWallShelfshopdetiple />
				<RoundWallShelfshop />
			</main>
			<Footer/>
		</>
	)
}

export default RoundWallShelf;
