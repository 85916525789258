import React from 'react';
import EmployeeDeskBreadcumb from '../components/ourshop/OfficeFurnitureshopdetails/EmployeeDeskBreadcumb'
import EmployeeDeskArea from '../components/ourshop/OfficeFurnitureshopdetails/EmployeeDeskArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function EmployeeDeskPages() {
    return (
        <>
            <Header />
            <main>
                <EmployeeDeskBreadcumb />
                <EmployeeDeskArea />
            </main>
            <Footer />
        </>
    )
}

export default EmployeeDeskPages; 