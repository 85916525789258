import ContactAddress from './ContactAddress'
import FaqContact from '../../components/FaqContact'

function ContactForm() {
  return (

    <section className="contact-area pt-110 pb-110">
      <div className="container">
        <div className="container-inner-wrap">
          <div className="row justify-content-center justify-content-lg-between">
           

            <FaqContact />
            <ContactAddress />

          </div>
        </div>
      </div>
    </section>
  )
      
}


export default ContactForm;
