import React from 'react';
import SeeshamCaneVintageBedHero from '../components/ourshop/WoodenCane/SeeshamCaneVintageBedHero'
import SeeshamCaneVintageBedHome from '../components/ourshop/WoodenCane/SeeshamCaneVintageBedHome'
import Header from "../components/Header";
import Footer from "../components/Footer";

function SeeshamCaneVintageBed() {
	return (
		<>
			<Header />
			<main>
				<SeeshamCaneVintageBedHome />
				<SeeshamCaneVintageBedHero />
			</main>
			<Footer />
		</>
	)
}

export default SeeshamCaneVintageBed;
