import React from 'react';
import WoodenStoolsDetailsArea from '../components/ourshop/Puffy/WoodenStoolsDetailsArea'
import WoodenStoolsArea from '../components/ourshop/Puffy/WoodenStoolsArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function WoodenStools() {
	return (
		<>
			<Header />
			<main>
				<WoodenStoolsDetailsArea />
				<WoodenStoolsArea/>
			</main>
			<Footer />
		</>
	)
}

export default WoodenStools;
