import React from 'react';
import ModularKitchenDesignBreadcumb from '../components/ourshop/Kitchenshopdetails/ModularKitchenDesignBreadcumb'
import ModularKitchenDesignArea from '../components/ourshop/Kitchenshopdetails/ModularKitchenDesignArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function ModularKitchenDesignPages() {
    return (
        <>
            <Header />
            <main>
                <ModularKitchenDesignBreadcumb />
                <ModularKitchenDesignArea />
            </main>
            <Footer />
        </>
    )
}

export default ModularKitchenDesignPages;