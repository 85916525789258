import React from "react";
import { Link } from 'react-router-dom'

function CatalogShop() {
  return (
    <section className="adoption-shop-area adoption-shop-area-padding">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-7 col-lg-9">
            <div className="section-title text-center mb-65">
              <div className="section-icon"><img src="img/icon/icon.png" alt="" /></div>
              <h1 className="sub-title main-slider-font-weight">Browse Our Furniture Collections</h1>
              <h2 className="title font-size main-slider-font-weight">Explore Our Furniture Range</h2>
              <p>Discover our wide range of furniture categories, from timeless classics to contemporary designs, explore our collections and elevate your living spaces with quality craftsmanship and exceptional comfort.</p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="adoption-shop-item">
              <div className="adoption-shop-thumb">
                <img src="img/product/sofa.png" alt="" />
                <Link to="/sofas" className="btn main-slider-font-weight">View more <img src="img/icon/shop-now.png" alt="" /></Link>
              </div>
              <div className="adoption-shop-content">
                <h2 className="title main-slider-font-weight"><Link to="/sofas">Sofas</Link></h2>
                <div className="adoption-meta">
                  <ul>
                    <li><i className="fas fa-cog" /><a href="/sofas">All Customized</a></li>
                    <li><i className="far fa-calendar-alt" /> On Time Delivery</li>
                  </ul>
                </div>
                <div className="adoption-rating">
                  <ul>
                    <li className="rating">
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                    </li>
                    <li className="price">Premium <span>Quality</span></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="adoption-shop-item">
              <div className="adoption-shop-thumb">
                <img src="img/product/dining-table.png" alt="" />
                <Link to="/dining-sets" className="btn main-slider-font-weight">View more <img src="img/icon/shop-now.png" alt="" /></Link>
              </div>
              <div className="adoption-shop-content">
                <h2 className="title main-slider-font-weight"><Link to="/dining-sets">Dining Sets</Link></h2>
                <div className="adoption-meta">
                  <ul>
                    <li><i className="fas fa-cog" /><a href="/dining-sets">All Customized</a></li>
                    <li><i className="far fa-calendar-alt" /> On Time Delivery</li>
                  </ul>
                </div>
                <div className="adoption-rating">
                  <ul>
                    <li className="rating">
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                    </li>
                    <li className="price">Premium <span>Quality</span></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="adoption-shop-item">
              <div className="adoption-shop-thumb">
                <img src="img/product/sheesham-bed.png" alt="" />
                <Link to="/beds" className="btn main-slider-font-weight">View more<img src="img/icon/shop-now.png" alt="" /></Link>
              </div>
              <div className="adoption-shop-content">
                <h2 className="title main-slider-font-weight"><Link to="/beds">Beds</Link></h2>
                <div className="adoption-meta">
                  <ul>
                    <li><i className="fas fa-cog" /><a href="/#">All Customized</a></li>
                    <li><i className="far fa-calendar-alt" /> On Time Delivery</li>
                  </ul>
                </div>
                <div className="adoption-rating">
                  <ul>
                    <li className="rating">
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                    </li>
                    <li className="price">Premium <span>Quality</span></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="adoption-shop-item">
              <div className="adoption-shop-thumb">
                <img src="img/product/canewood_furniture.png" alt="" />
                <Link to="/wooden-cane" className="btn main-slider-font-weight">View more<img src="img/icon/shop-now.png" alt="" /></Link>
              </div>
              <div className="adoption-shop-content">
                <h2 className="title main-slider-font-weight"><Link to="/wooden-cane">Wooden Cane</Link></h2>
                <div className="adoption-meta">
                  <ul>
                    <li><i className="fas fa-cog" /><a href="/#">All Customized</a></li>
                    <li><i className="far fa-calendar-alt" /> On Time Delivery</li>
                  </ul>
                </div>
                <div className="adoption-rating">
                  <ul>
                    <li className="rating">
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                    </li>
                    <li className="price">Premium <span>Quality</span></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="adoption-shop-item">
              <div className="adoption-shop-thumb">
                <img src="img/product/sheesham-sofa.png" alt="" />
                <Link to="/sheeshamsofa" className="btn main-slider-font-weight">View more<img src="img/icon/shop-now.png" alt="" /></Link>
              </div>
              <div className="adoption-shop-content">
                <h2 className="title main-slider-font-weight"><Link to="/sheeshamsofa">Sheesham-Sofa</Link></h2>
                <div className="adoption-meta">
                  <ul>
                    <li><i className="fas fa-cog" /><a href="/#">All Customized</a></li>
                    <li><i className="far fa-calendar-alt" /> On Time Delivery</li>
                  </ul>
                </div>
                <div className="adoption-rating">
                  <ul>
                    <li className="rating">
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                    </li>
                    <li className="price">Premium <span>Quality</span></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="adoption-shop-item">
              <div className="adoption-shop-thumb">
                <img src="img/product/center-table.png" alt="" />
                <Link to="/centertable" className="btn main-slider-font-weight">View more<img src="img/icon/shop-now.png" alt="" /></Link>
              </div>
              <div className="adoption-shop-content">
                <h2 className="title main-slider-font-weight"><Link to="/centertable">Center-Table</Link></h2>
                <div className="adoption-meta">
                  <ul>
                    <li><i className="fas fa-cog" /><a href="/#">All Customized</a></li>
                    <li><i className="far fa-calendar-alt" /> On Time Delivery</li>
                  </ul>
                </div>
                <div className="adoption-rating">
                  <ul>
                    <li className="rating">
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                    </li>
                    <li className="price">Premium <span>Quality</span></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="adoption-shop-item">
              <div className="adoption-shop-thumb">
                <img src="img/product/wardrobe.png" alt="" />
                <Link to="/wardrobe" className="btn main-slider-font-weight">View more<img src="img/icon/shop-now.png" alt="" /></Link>
              </div>
              <div className="adoption-shop-content">
                <h2 className="title main-slider-font-weight"><Link to="/wardrobe">Wardrobe</Link></h2>
                <div className="adoption-meta">
                  <ul>
                    <li><i className="fas fa-cog" /><a href="/#">All Customized</a></li>
                    <li><i className="far fa-calendar-alt" /> On Time Delivery</li>
                  </ul>
                </div>
                <div className="adoption-rating">
                  <ul>
                    <li className="rating">
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                    </li>
                    <li className="price">Premium <span>Quality</span></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="adoption-shop-item">
              <div className="adoption-shop-thumb">
                <img src="img/product/study-table.png" alt="" />
                <Link to="/studytable" className="btn main-slider-font-weight">View more <img src="img/icon/shop-now.png" alt="" /></Link>
              </div>
              <div className="adoption-shop-content">
                <h2 className="title main-slider-font-weight"><Link to="/stydytable">Study-Table</Link></h2>
                <div className="adoption-meta">
                  <ul>
                    <li><i className="fas fa-cog" /><a href="/#">All Customized</a></li>
                    <li><i className="far fa-calendar-alt" /> On Time Delivery</li>
                  </ul>
                </div>
                <div className="adoption-rating">
                  <ul>
                    <li className="rating">
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                    </li>
                    <li className="price">Premium <span>Quality</span></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="adoption-shop-item">
              <div className="adoption-shop-thumb">
                <img src="img/product/office-counters.png" alt="" />
                <Link to="/officecounter" className="btn main-slider-font-weight">View more <img src="img/icon/shop-now.png" alt="" /></Link>
              </div>
              <div className="adoption-shop-content">
                <h2 className="title main-slider-font-weight"><Link to="/officecounter">Office-Counters</Link></h2>
                <div className="adoption-meta">
                  <ul>
                    <li><i className="fas fa-cog" /><a href="/#">All Customized</a></li>
                    <li><i className="far fa-calendar-alt" /> On Time Delivery</li>
                  </ul>
                </div>
                <div className="adoption-rating">
                  <ul>
                    <li className="rating">
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                    </li>
                    <li className="price">Premium <span>Quality</span></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="adoption-shop-item">
              <div className="adoption-shop-thumb">
                <img src="img/product/office-chairs.png" alt="" />
                <Link to="/officechair" className="btn main-slider-font-weight">View more <img src="img/icon/shop-now.png" alt="" /></Link>
              </div>
              <div className="adoption-shop-content">
                <h2 className="title main-slider-font-weight"><Link to="/officechair">Office-Chairs</Link></h2>
                <div className="adoption-meta">
                  <ul>
                    <li><i className="fas fa-cog" /><a href="/#">All Customized</a></li>
                    <li><i className="far fa-calendar-alt" /> On Time Delivery</li>
                  </ul>
                </div>
                <div className="adoption-rating">
                  <ul>
                    <li className="rating">
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                    </li>
                    <li className="price">Premium <span>Quality</span></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="adoption-shop-item">
              <div className="adoption-shop-thumb">
                <img src="img/product/iron-furniture.png" alt="" />
                <Link to="/ironchair" className="btn main-slider-font-weight">View more <img src="img/icon/shop-now.png" alt="" /></Link>
              </div>
              <div className="adoption-shop-content">
                <h2 className="title main-slider-font-weight"><Link to="/ironchair">Iron Furniture</Link></h2>
                <div className="adoption-meta">
                  <ul>
                    <li><i className="fas fa-cog" /><a href="/#">All Customized</a></li>
                    <li><i className="far fa-calendar-alt" /> On Time Delivery</li>
                  </ul>
                </div>
                <div className="adoption-rating">
                  <ul>
                    <li className="rating">
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                    </li>
                    <li className="price">Premium <span>Quality</span></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="adoption-shop-item">
              <div className="adoption-shop-thumb">
                <img src="img/product/mandir.png" alt="" />
                <Link to="/mandir" className="btn main-slider-font-weight">View more<img src="img/icon/shop-now.png" alt="" /></Link>
              </div>
              <div className="adoption-shop-content">
                <h2 className="title main-slider-font-weight"><Link to="/mandir">Mandir</Link></h2>
                <div className="adoption-meta">
                  <ul>
                    <li><i className="fas fa-cog" /><a href="/#">All Customized</a></li>
                    <li><i className="far fa-calendar-alt" /> On Time Delivery</li>
                  </ul>
                </div>
                <div className="adoption-rating">
                  <ul>
                    <li className="rating">
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                    </li>
                    <li className="price">Premium <span>Quality</span></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="adoption-shop-item">
              <div className="adoption-shop-thumb">
                <img src="img/product/tv-cabinets.png" alt="" />
                <Link to="/cabinet" className="btn main-slider-font-weight">View more <img src="img/icon/shop-now.png" alt="" /></Link>
              </div>
              <div className="adoption-shop-content">
                <h2 className="title main-slider-font-weight"><Link to="/cabinet">TV-Cabinets</Link></h2>
                <div className="adoption-meta">
                  <ul>
                    <li><i className="fas fa-cog" /><a href="/#">All Customized</a></li>
                    <li><i className="far fa-calendar-alt" /> On Time Delivery</li>
                  </ul>
                </div>
                <div className="adoption-rating">
                  <ul>
                    <li className="rating">
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                    </li>
                    <li className="price">Premium <span>Quality</span></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="adoption-shop-item">
              <div className="adoption-shop-thumb">
                <img src="img/product/office-furniture.png" alt="" />
                <Link to="/officefurniture" className="btn main-slider-font-weight">View more <img src="img/icon/shop-now.png" alt="" /></Link>
              </div>
              <div className="adoption-shop-content">
                <h2 className="title main-slider-font-weight"><Link to="/officefurniture">Office Furniture</Link></h2>
                <div className="adoption-meta">
                  <ul>
                    <li><i className="fas fa-cog" /><a href="/#">All Customized</a></li>
                    <li><i className="far fa-calendar-alt" /> On Time Delivery</li>
                  </ul>
                </div>
                <div className="adoption-rating">
                  <ul>
                    <li className="rating">
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                    </li>
                    <li className="price">Premium <span>Quality</span></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="adoption-shop-item">
              <div className="adoption-shop-thumb">
                <img src="img/product/comforters.png" alt="" />
                <Link to="/comforter" className="btn main-slider-font-weight">View more<img src="img/icon/shop-now.png" alt="" /></Link>
              </div>
              <div className="adoption-shop-content">
                <h2 className="title main-slider-font-weight"><Link to="/comforter">Comforters</Link></h2>
                <div className="adoption-meta">
                  <ul>
                    <li><i className="fas fa-cog" /><a href="/#">All Customized</a></li>
                    <li><i className="far fa-calendar-alt" /> On Time Delivery</li>
                  </ul>
                </div>
                <div className="adoption-rating">
                  <ul>
                    <li className="rating">
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                    </li>
                    <li className="price">Premium <span>Quality</span></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="adoption-shop-item">
              <div className="adoption-shop-thumb">
                <img src="img/product/side-table.png" alt="" />
                <Link to="/sidetable" className="btn main-slider-font-weight">View more <img src="img/icon/shop-now.png" alt="" /></Link>
              </div>
              <div className="adoption-shop-content">
                <h2 className="title main-slider-font-weight"><Link to="/sidetable">Side-Table</Link></h2>
                <div className="adoption-meta">
                  <ul>
                    <li><i className="fas fa-cog" /><a href="/#">All Customized</a></li>
                    <li><i className="far fa-calendar-alt" /> On Time Delivery</li>
                  </ul>
                </div>
                <div className="adoption-rating">
                  <ul>
                    <li className="rating">
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                    </li>
                    <li className="price">Premium <span>Quality</span></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="adoption-shop-item">
              <div className="adoption-shop-thumb">
                <img src="img/product/kitchen.png" alt="" />
                <Link to="/kitchen" className="btn main-slider-font-weight">View more<img src="img/icon/shop-now.png" alt="" /></Link>
              </div>
              <div className="adoption-shop-content">
                <h2 className="title main-slider-font-weight"><Link to="/kitchen">Kitchen</Link></h2>
                <div className="adoption-meta">
                  <ul>
                    <li><i className="fas fa-cog" /><a href="/#">All Customized</a></li>
                    <li><i className="far fa-calendar-alt" /> On Time Delivery</li>
                  </ul>
                </div>
                <div className="adoption-rating">
                  <ul>
                    <li className="rating">
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                    </li>
                    <li className="price">Premium <span>Quality</span></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="adoption-shop-item">
              <div className="adoption-shop-thumb">
                <img src="img/product/puffy.png" alt="" />
                <Link to="/puffy" className="btn main-slider-font-weight">View more <img src="img/icon/shop-now.png" alt="" /></Link>
              </div>
              <div className="adoption-shop-content">
                <h2 className="title main-slider-font-weight"><Link to="/puffy">Puffy</Link></h2>
                <div className="adoption-meta">
                  <ul>
                    <li><i className="fas fa-cog" /><a href="/#">All Customized</a></li>
                    <li><i className="far fa-calendar-alt" /> On Time Delivery</li>
                  </ul>
                </div>
                <div className="adoption-rating">
                  <ul>
                    <li className="rating">
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                    </li>
                    <li className="price">Premium <span>Quality</span></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="adoption-shop-item">
              <div className="adoption-shop-thumb">
                <img src="img/product/partitions.png" alt="" />
                <Link to="/partitions" className="btn main-slider-font-weight">View more <img src="img/icon/shop-now.png" alt="" /></Link>
              </div>
              <div className="adoption-shop-content">
                <h2 className="title main-slider-font-weight"><Link to="/partitions">Partitions</Link></h2>
                <div className="adoption-meta">
                  <ul>
                    <li><i className="fas fa-cog" /><a href="/#">All Customized</a></li>
                    <li><i className="far fa-calendar-alt" />On Time Delivery</li>
                  </ul>
                </div>
                <div className="adoption-rating">
                  <ul>
                    <li className="rating">
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                    </li>
                    <li className="price">Premium <span>Quality</span></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="adoption-shop-item">
              <div className="adoption-shop-thumb">
                <img src="img/product/dressing_table.png" alt="" />
                <Link to="/dressingtable" className="btn main-slider-font-weight">View more<img src="img/icon/shop-now.png" alt="" /></Link>
              </div>
              <div className="adoption-shop-content">
                <h2 className="title main-slider-font-weight"><Link to="/dressingtable">Dressing-Table</Link></h2>
                <div className="adoption-meta">
                  <ul>
                    <li><i className="fas fa-cog" /><a href="/#">All Customized</a></li>
                    <li><i className="far fa-calendar-alt" /> On Time Delivery</li>
                  </ul>
                </div>
                <div className="adoption-rating">
                  <ul>
                    <li className="rating">
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                    </li>
                    <li className="price">Premium <span>Quality</span></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="adoption-shop-item">
              <div className="adoption-shop-thumb">
                <img src="img/product/dining-chairs.png" alt="" />
                <Link to="/diningchair" className="btn main-slider-font-weight">View more<img src="img/icon/shop-now.png" alt="" /></Link>
              </div>
              <div className="adoption-shop-content">
                <h2 className="title main-slider-font-weight"><Link to="/diningchair">Dining-Chairs</Link></h2>
                <div className="adoption-meta">
                  <ul>
                    <li><i className="fas fa-cog" /><a href="/#">All Customized</a></li>
                    <li><i className="far fa-calendar-alt" /> On Time Delivery</li>
                  </ul>
                </div>
                <div className="adoption-rating">
                  <ul>
                    <li className="rating">
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                    </li>
                    <li className="price">Premium <span>Quality</span></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="adoption-shop-item">
              <div className="adoption-shop-thumb">
                <img src="img/product/corner-shelfs.png" alt="" />
                <Link to="/cornershelfs" className="btn main-slider-font-weight">View more<img src="img/icon/shop-now.png" alt="" /></Link>
              </div>
              <div className="adoption-shop-content">
                <h2 className="title main-slider-font-weight"><Link to="/cornershelfs">Corner-Shelves</Link></h2>
                <div className="adoption-meta">
                  <ul>
                    <li><i className="fas fa-cog" /><a href="/#">All Customized</a></li>
                    <li><i className="far fa-calendar-alt" />On Time Delivery</li>
                  </ul>
                </div>
                <div className="adoption-rating">
                  <ul>
                    <li className="rating">
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                    </li>
                    <li className="price">Premium <span>Quality</span></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  )
}

export default CatalogShop;
