import React from 'react';
import { Link } from 'react-router-dom'

function Features() {
  return (
    <section className="adoption-shop-padding adoption-shop-area-padding">
      <div className="container">
          <div>
              <h2 className="main-slider-font-weight">Maheshwari Furniture Approach to Security</h2>
              <p className="text-justify">Maheshwari Furniture uses highest security standards to ensure its customers 
                    about secure online transactions. Being the data controller Maheshwari 
                    Furniture is responsible to secure your data and committed to practice 
                    advanced firewall technology for the order process.
              </p>
         </div>
         <div>
                <h2 className="main-slider-font-weight">
                Respecting Your Privacy </h2>
             <p>
                Maheshwari Furniture is committed to respect your privacy. Therefore we set 
                the highest ethical standards to protect your sensitive information. This 
                privacy policy describes the type of information we collect, why we will collect 
                it and with whom we will share it.
             </p>
             <p>
                We value the trust you have paced in us, please read the document regarding 
                our privacy policy.  If you have any queries about our privacy policy, please 
                contact <strong> <a href="mailto:maheshwarifurniturejpr@gmail.com.">
                 maheshwarifurniturejpr@gmail.com.</a> </strong>
             </p>
         </div>
         <div>
                <h2 className="main-slider-font-weight">
                Type of information we collect</h2>
              <ul className="ul-padding">
                 <li className="features-list-style">
                    We may collect your contact details, such as your name, email address, 
                    phone number, postal address and delivery address (if different).  
                 </li>
                 <li className="features-list-style">
                    We may require your banking details such as credit card number, card 
                    holder name, expiration date and CVV or other details necessary for 
                    Internet banking.
                 </li>
                 <li className="features-list-style">
                    In order to improve the shopping experience, we may seek order details 
                    of your previous purchases, including transactions and pricing details.
                 </li>
                 <li className="features-list-style">
                    We may also collect your demographic information (gender, age, 
                    income) to conduct optional online surveys to asses our services.
                 </li>
                 <li className="features-list-style">
                    We will collect your session and log in information, which include your 
                    IP address, operating system, browser type, browser language, date 
                    and time of request.
                 </li>
                 <li className="features-list-style">
                    We will also collect sign in information for third party accounts shared 
                    on our website such as, Facebook, Twitter, Pinterest, Instagram, 
                    YouTube etc.
                 </li>
                 <li className="features-list-style">
                    We shall ask for email communication by any user, for product inquiry, 
                    order and transaction details, for any complaints and disputes etc.
                 </li>
             </ul> 
         </div>
         <div>
             <h2 className="main-slider-font-weight">
                How we use or share it
             </h2>
             <p>
                We conduct the business in an ethical manner, hence we collect the personal 
                information of our users or customer only to cater best products and services 
                at maheshwarifurniturejaipur.com. We do not sell, trade, share or transfer 
                your information to any third parties. 
             </p>  
             <ul className="ul-padding">
                <li className="features-list-style">
                    Our team that are involved in buying and delivering process, shall use 
                    your information.    
                </li>
                <li className="features-list-style">
                    An aggregated form of information may be used to understand the 
                    consumer behaviour so that we can improve the shopping experience. 
                    Our business and marketing activities may also use the demographical 
                    profiles of our users.
                </li>
                <li className="features-list-style">
                    To tell you about deals, offers, new launches, shipment status, 
                    informational and promotional content, we will use your contact 
                    information. There is an opt-out alternative for such services.
                </li>
                <li className="features-list-style">
                    To understand the consumer behavior, buying preferences and to 
                    analyze how user interaction is being forwarded, we will use traffic flow 
                    information.
                </li>
                <li className="features-list-style">
                    User engagement and time spent by each user on our site accessed 
                    with the help your IP address and traffic source information. We may 
                    use it to measure the performance.
                </li>
                <li className="features-list-style">
                    User information may be required in case of legal disputes, regulatory 
                    or statutory requirements, troubleshoot problems, collect fees owed, 
                    detect or protect against errors, prevent abuse of our services, prevent 
                    violation of rights of third parties, enforce our terms and conditions.
                </li>
             </ul>          
         </div>
         <div>
             <h2 className="main-slider-font-weight">
                Cookie Policy
             </h2>
             <p>
                For enhanced shopping experience, Maheshwari Furniture uses cookies to 
                store your browsing information. Cookies do not save your PII (personally 
                identifiable information), though you can change the cookie settings as per 
                your preferences. By using the website, we believe that you consent with the 
                cookie policy.
             </p>
         </div>
         <div>
             <h2 className="main-slider-font-weight">
                User Consent  
             </h2>
             <p>
                When you access the website or fill in your details to buy the products or 
                services offered at website, Maheshwari Furniture will accept it as your 
                consent with the privacy policy. For user who are not comfortable with the 
                clause and conditions to share their personally identifiable information, it is 
                requested to abort the use of the website. We also provide an opt-out option 
                to our registered users to unsubscribe all the non-essential mails and 
                communication from us.
             </p>
         </div>
         <div>
              <h2 className="main-slider-font-weight">
                More Information
             </h2>
             <p>
                In case you have any question regarding our privacy practices, feel free to 
                contact <strong> <a href="mailto:maheshwarifurniturejpr@gmail.com.">
                maheshwarifurniturejpr@gmail.com. </a> </strong>  
                Note: The privacy policy of <a href="/">maheshwarifurniturejaipur.com</a> may subject to 
                change at anytime to include or exclude clauses, thus users are requested to 
                visit this page in a timely manner to stay aware with the changes. The above 
                privacy policy does not apply to our affiliates, partners and any third party 
                associated with us, for further interaction kindly review the privacy statement 
                of the respective.
             </p>
         </div>
      </div>
          
        
    </section>
  )
}

export default Features;
