import React from 'react';
import WoodDressingTableDetailsArea from '../components/ourshop/Dressingtable/WoodDressingTableDetailsArea'
import WoodDressingTableArea from '../components/ourshop/Dressingtable/WoodDressingTableArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function WoodDressingTable() {
	return (
		<>
			<Header />
			<main>
				<WoodDressingTableDetailsArea />
				<WoodDressingTableArea />
			</main>
			<Footer />
		</>
	)
}

export default WoodDressingTable;
