import React from 'react';
import BlogPost from '../components/blogdetails/BlogPost'
import BlogDetBread from '../components/blogdetails/BlogDetBread'
import Header from "../components/Header";
import Footer from "../components/Footer";

function BlogDetailsPage() {
  return (
      <>
          <Header/>
          <main>
              <BlogDetBread/>
              <BlogPost/>
          </main>
          <Footer/>
      </>
  )
}

export default BlogDetailsPage;
