import React from 'react';
import BlackRevolvingChairBreadcumb from '../components/ourshop/OfficeChairshopdetails/BlackRevolvingChairBreadcumb'
import BlackRevolvingChairArea from '../components/ourshop/OfficeChairshopdetails/BlackRevolvingChairArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function BlackRevolvingChairPages() {
    return (
        <>
            <Header />
            <main>
                <BlackRevolvingChairBreadcumb />
                <BlackRevolvingChairArea />
            </main>
            <Footer />
        </>
    )
}

export default BlackRevolvingChairPages; 