import React from 'react';
import ModularOfficeTableBreadcumb from '../components/ourshop/OfficeCountershopdetails/ModularOfficeTableBreadcumb'
import ModularOfficeTableArea from '../components/ourshop/OfficeCountershopdetails/ModularOfficeTableArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function ModularOfficeTablePages() {
    return (
        <>
            <Header />
            <main>
                <ModularOfficeTableBreadcumb />
                <ModularOfficeTableArea />
            </main>
            <Footer />
        </>
    )
}

export default ModularOfficeTablePages; 