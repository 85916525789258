import React from 'react';
import SheeshamWoodBedWithStorageDetailsBreadcumb from '../components/ourshop/Bedshopdetails/SheeshamWoodBedWithStorageDetailsBreadcumb'
import SheeshamWoodBedWithStorageDetailsArea from '../components/ourshop/Bedshopdetails/SheeshamWoodBedWithStorageDetailsArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function SheeshamWoodBedWithStorageDetailsPage() {
    return (
        <>
            <Header />
            <main>
                <SheeshamWoodBedWithStorageDetailsBreadcumb />
                <SheeshamWoodBedWithStorageDetailsArea />
            </main>
            <Footer />
        </>
    )
}

export default SheeshamWoodBedWithStorageDetailsPage; 