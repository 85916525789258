import React from 'react';
import ShopBreadcumb from '../components/ourshop/ShopBreadcumb'
import IronChairArea from '../components/ourshop/IronChairArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function IronChairPages() {
	return (
		<>
			<Header />
			<main>
				<ShopBreadcumb />
				<IronChairArea />
			</main>
			<Footer />
		</>
	)
}

export default IronChairPages;
