import React from 'react';
import IronBarStoolBreadcumb from '../components/ourshop/IronFurnitureshopdetails/IronBarStoolBreadcumb'
import IronBarStoolArea from '../components/ourshop/IronFurnitureshopdetails/IronBarStoolArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function IronBarStoolPages() {
    return (
        <>
            <Header />
            <main>
                <IronBarStoolBreadcumb />
                <IronBarStoolArea />
            </main>
            <Footer />
        </>
    )
}

export default IronBarStoolPages; 