import React from 'react';
import WallShelvesshop from '../components/ourshop/cornershelfs/WallShelvesshop'
import WallShelvesshopdetiple from '../components/ourshop/cornershelfs/WallShelvesshopdetiple'
import Header from "../components/Header";
import Footer from "../components/Footer";

function WallShelves() {
	return (
		<>
			<Header />
			<main>
				<WallShelvesshopdetiple/>
				<WallShelvesshop />
			</main>
			<Footer />
		</>
	)
}

export default WallShelves;
