import React from 'react';
import ShopBreadcumb from '../components/ourshop/ShopBreadcumb'
import BedsArea from '../components/ourshop/BedsArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function BedsPages() {
	return (
		<>
			<Header />
			<main>
				<ShopBreadcumb />
				<BedsArea />
			</main>
			<Footer />
		</>
	)
}

export default BedsPages;
