import React from 'react';
import BeigeRevolvingChairBreadcumb from '../components/ourshop/OfficeChairshopdetails/BeigeRevolvingChairBreadcumb'
import BeigeRevolvingChairArea from '../components/ourshop/OfficeChairshopdetails/BeigeRevolvingChairArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function BeigeRevolvingChairPages() {
    return (
        <>
            <Header />
            <main>
                <BeigeRevolvingChairBreadcumb />
                <BeigeRevolvingChairArea />
            </main>
            <Footer />
        </>
    )
}

export default BeigeRevolvingChairPages; 