import React from 'react';
import GoldFinishSideTableBreadcumb from '../components/ourshop/SideTableshopdetails/GoldFinishSideTableBreadcumb'
import GoldFinishSideTableArea from '../components/ourshop/SideTableshopdetails/GoldFinishSideTableArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function GoldFinishSideTablePages() {
    return (
        <>
            <Header />
            <main>
                <GoldFinishSideTableBreadcumb />
                <GoldFinishSideTableArea />
            </main>
            <Footer />
        </>
    )
}

export default GoldFinishSideTablePages; 