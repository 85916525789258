import React from 'react';
import RectangularWoodTableBeigeHero from '../components/ourshop/centertable/RectangularWoodTableBeigeHero'
import RectangularWoodTableBeigeHome from '../components/ourshop/centertable/RectangularWoodTableBeigeHome'
import Header from "../components/Header";
import Footer from "../components/Footer";

function RectangularWoodTableBeige() {
	return (
		<>
			<Header />
			<main>
				<RectangularWoodTableBeigeHome />
				<RectangularWoodTableBeigeHero />
			</main>
			<Footer />
		</>
	)
}

export default RectangularWoodTableBeige;
