import React, { useEffect } from 'react';
import BreederBreadCumb from '../components/breeder/BreederBreadCumb';
import BreederAdoption from '../components/breeder/BreederAdoption';
import AboutUs from '../components/breeder/AboutUs';
import AboutUsTwo from '../components/breeder/AboutUsTwo';
import Hero from '../components/breeder/Hero';
import Services from '../components/breeder/Services';
import Download from '../components/breeder/Download';
import Projects from '../components/breeder/Projects';
import Features from '../components/breeder/Features';
import AdoptionPuppies from '../components/adoptions/AdoptionPuppies'
import AdoptionFaq from '../components/adoptions/AdoptionFaq'
import AdoptionGallery from '../components/adoptions/AdoptionGallery'
import Header from "../components/Header";
import Footer from "../components/Footer";


function BreederPage() {
    useEffect(() => {
        document.title = 'Explore Versatility with Maheshwari Furniture’s Modular Furniture Collection';
    }, []);
  return (
	  <>
		  <Header/>
		  <main>
			<Hero/>
		  <AboutUs/>
		  <BreederBreadCumb/>
		  <Download/>
			  <Services/>
			  <AboutUsTwo/>
			  <Features/>
			  <Projects/>
			  <BreederAdoption/>
			  <AdoptionPuppies/>
			  <AdoptionFaq/>
			  <AdoptionGallery/>

		  </main>
		  <Footer/>
	  </>
  )
}

export default BreederPage;
