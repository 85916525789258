import React, { useEffect } from 'react';
import 'magnific-popup';
import $ from 'jquery';
import { Link } from 'react-router-dom'



function Faq({ afterElment }) {
  useEffect(() => {


    /* magnificPopup video view */
    $('.popup-video').magnificPopup({
      type: 'iframe'
    });

  }, [])


  return (

    <section className={afterElment + " faq-bg"}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="faq-img-wrap">
              <img src="img/images/testimonial.png" className="img-frame" alt="" />
              <img src="img/images/tv_screen.png" className="main-img" alt="" />
              <Link to="https://www.youtube.com/shorts/xECmGKCKrsc" className="popup-video" aria-label="popup-video" ></Link>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="faq-wrapper">
              <div className="section-title mb-35">
                <h1 className="sub-title main-slider-font-weight">FAQ Question</h1>
                <h2 className="title font-size main-slider-font-weight">Answers to Your Furniture Questions</h2>
              </div>
              <div className="accordion" id="accordionExample">
                <div className="card">
                  <div className="card-header" id="headingOne">
                    <h2 className="mb-0">
                      <button className="btn btn-link btn-block text-left faq-font-weight" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        What types of furniture do you offer?
                      </button>
                    </h2>
                  </div>
                  <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                    <div className="card-body">
                      We provide a diverse range of furniture for homes and offices, including sofas, beds, tables, chairs, and more. Explore our collections for a comprehensive overview.
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingTwo">
                    <h2 className="mb-0">
                      <button className="btn btn-link btn-block text-left collapsed faq-font-weight" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        Can I customize furniture to suit my preferences?
                      </button>
                    </h2>
                  </div>
                  <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                    <div className="card-body">
                      Absolutely! We offer customization options to tailor furniture according to your unique style, color preferences, and size requirements.
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingThree">
                    <h2 className="mb-0">
                      <button className="btn btn-link btn-block text-left collapsed faq-font-weight" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        How does the delivery process work?
                      </button>
                    </h2>
                  </div>
                  <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                    <div className="card-body">
                      Once your order is confirmed, our team will arrange prompt delivery to your specified address. Delivery fees and timeframes will be communicated during the checkout process.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="faq-shape"><img src="img/images/faq-icon.png" alt="" /></div>
    </section>
  )
}

export default Faq;
