import React from 'react';
import Slider from 'react-slick'

function Testimonial() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 4000,
    autoplay: false,
    arrows: false,
    autoplaySpeed: 4000,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          speed: 1000,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          speed: 1000,
        }
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          speed: 1000,
        }
      },
    ]
  }
  return (
    <section className="testimonial-area testimonial-bg testimonial-area-padding">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-7 col-lg-9">
            <div className="section-title text-center mb-65">
              <div className="section-icon"><img src="img/icon/icon.png" alt="" /></div>
              <h2 className="sub-title main-slider-font-weight">Testimonials</h2>
              <h2 className="title font-size main-slider-font-weight">Our Happy Customers</h2>
              <p className="text-justify">At Maheshwari Furniture, our proudest achievement is the satisfaction of our customers. Our furniture not only transforms living spaces but also creates smiles. The joy and contentment of our happy customers are a testament to our commitment to quality, style, and personalized service. </p>
            </div>
          </div>
        </div>
        <Slider className="row testimonial-active"{...settings}>
          {/* <div className="col">
            <div className="testimonial-item">
              <div className="testi-avatar-thumb">
                <img src="img/images/testi-avatar01.png" alt="" />
              </div>
              <div className="testi-content">
                <p>"Absolutely thrilled with the transformation of my space by Maheshwari Furniture! They nailed every detail, from furniture selection to decor."</p>
                <div className="testi-avatar-info">
                  <h1 className="title main-slider-font-weight">Awani Joshi</h1>
                  <span>Jaipur</span>
                </div>
              </div>
            </div>
          </div> */}
          <div className="col">
            <div className="testimonial-item">
              <div className="testi-avatar-thumb">
                <img src="img/images/testi-avatar-02.png" alt="" />
              </div>
              <div className="testi-content">
                <p>"Professional, creative, and attentive – Maheshwari Furniture exceeded my expectations. My new fully furnished space is magazine-worthy!"</p>
                <div className="testi-avatar-info">
                  <h2 className="title main-slider-font-weight">S.N. Kabra</h2>
                  <span>Jaipur</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="testimonial-item">
              <div className="testi-avatar-thumb">
                <img src="img/images/testi-avatar03.png" alt="" />
              </div>
              <div className="testi-content">
                <p>"Impressed by the seamless coordination between the design team and furniture suppliers at Maheshwari Furniture. My home feels cohesive and inviting."</p>
                <div className="testi-avatar-info">
                  <h2 className="title main-slider-font-weight">Manoj Mundra</h2>
                  <span>Jaipur</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="testimonial-item">
              <div className="testi-avatar-thumb">
                <img src="img/images/testi-avatar04.png" alt="" />
              </div>
              <div className="testi-content">
                <p>“ Their showroom was well-organized, and the staff was incredibly knowledgeable and helpful. They helped me find the perfect pieces to fit my style and budget. ”</p>
                <div className="testi-avatar-info">
                  <h2 className="title main-slider-font-weight">Alessia Cara</h2>
                  <span>Jaipur</span>
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </section>
  )
}

export default Testimonial;
