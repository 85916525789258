import React from 'react';
import { Link } from 'react-router-dom'
import HomeCounter from '../hometwo/HomeCounter'
import Slider from 'react-slick'

function AdoptionSlider() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          speed: 1000,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          speed: 1000,
        }
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          speed: 1000,
        }
      },
    ]
  }
  return (
    <section className="inner-breeder-area breeder-bg adoption-area-padding">
      <div className="container" aria-hidden="true">
        <div className="row align-items-center justify-content-center">
          <div className="col-xl-4 col-lg-9 col-md-12 col-sm-9">
            <div className="adoption-content">
              <h2 className="sub-title faq-font-weight">Who we are ?</h2>
              <h2 className="title font-size faq-font-weight">Your Premier <span>Source</span> for Stylish Furniture</h2>
              <p className="text-justify"> We're passionate about providing top-quality furniture to enhance your home. With a diverse selection of stylish and durable pieces, we're here to make your shopping experience easy and enjoyable.</p>
              <div className="adoption-list">
                <ul>
                  <li><i className="flaticon-tick" /> Customization options available</li>
                  <li><i className="flaticon-tick" /> Hassle-free delivery and assembly services</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-xl-8 col-lg-9 col-md-12 col-sm-9">
            <div className="breeder-info-wrap">
              <div className="row no-gutters justify-content-center">
                <div className="col-md-6">
                  <Slider className="breeder-active owl-carousel" aria-hidden="true"{...settings}>
                    <div className="breeder-item" aria-hidden="true">
                      <img src="img/images/1.jpg" alt="" />
                    </div>
                    <div className="breeder-item" aria-hidden="true">
                      <img src="img/images/2.jpg" alt="" />
                    </div>
                    <div className="breeder-item" aria-hidden="true">
                      <img src="img/images/3.jpg" alt="" />
                    </div>
                    <div className="breeder-item" aria-hidden="true">
                      <img src="img/images/4.jpg" alt="" />
                    </div>
                  </Slider>
                </div>
                <div className="col-md-6">
                  <div className="breed-services-info" aria-hidden="true" style={{ backgroundImage: 'url("img/bg/bg.png")' }}>
                    <h2 className="sub-title faq-font-weight">Explore, Choose, Transform</h2>
                    <h3 className="title main-slider-font-weight">Diverse Collection</h3>
                    <p>Discover a wide range of furniture options, meticulously curated to suit the unique needs of your home or office.</p>
                    <Link to="/categories" className="btn main-slider-font-weight" tabIndex="-1">View All </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="counter-area mt-70">
          <HomeCounter />
        </div>
      </div>

    </section>
  )
}

export default AdoptionSlider;
