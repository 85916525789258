import React from 'react';
import SquareTablewith4StoolsHero from '../components/ourshop/centertable/SquareTablewith4StoolsHero'
import SquareTablewith4StoolsHome from '../components/ourshop/centertable/SquareTablewith4StoolsHome'
import Header from "../components/Header";
import Footer from "../components/Footer";

function SquareTablewith4Stools() {
	return (
		<>
			<Header />
			<main>
				<SquareTablewith4StoolsHome />
				<SquareTablewith4StoolsHero />
			</main>
			<Footer />
		</>
	)
}

export default SquareTablewith4Stools;
