import React from 'react';
import SheeshamWoodBedDetailsBreadcumb from '../components/ourshop/Bedshopdetails/SheeshamWoodBedDetailsBreadcumb'
import SheeshamWoodBedDetailsArea from '../components/ourshop/Bedshopdetails/SheeshamWoodBedDetailsArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function SheeshamWoodBedDetailsPage() {
    return (
        <>
            <Header />
            <main>
                <SheeshamWoodBedDetailsBreadcumb />
                <SheeshamWoodBedDetailsArea />
            </main>
            <Footer />
        </>
    )
}

export default SheeshamWoodBedDetailsPage;