import React from 'react';
import StoolMulticolourDetailsArea from '../components/ourshop/Puffy/StoolMulticolourDetailsArea'
import StoolMulticolourArea from '../components/ourshop/Puffy/StoolMulticolourArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function StoolMulticolour() {
	return (
		<>
			<Header />
			<main>
				<StoolMulticolourDetailsArea />
				<StoolMulticolourArea/>
			</main>
			<Footer />
		</>
	)
}

export default StoolMulticolour;
