import React from 'react';
import FloralPrintLoungeChairBreadcumb from '../components/ourshop/Comfortersshopdetails/FloralPrintLoungeChairBreadcumb'
import FloralPrintLoungeChairArea from '../components/ourshop/Comfortersshopdetails/FloralPrintLoungeChairArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function FloralPrintLoungeChairPages() {
    return (
        <>
            <Header />
            <main>
                <FloralPrintLoungeChairBreadcumb />
                <FloralPrintLoungeChairArea />
            </main>
            <Footer />
        </>
    )
}

export default FloralPrintLoungeChairPages; 