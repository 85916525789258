import React from 'react';
import WoodCraftedDividersDetailsArea from '../components/ourshop/Partitions/WoodCraftedDividersDetailsArea'
import WoodCraftedDividersArea from '../components/ourshop/Partitions/WoodCraftedDividersArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function WoodCraftedDividers() {
	return (
		<>
			<Header />
			<main>
				<WoodCraftedDividersDetailsArea />
				<WoodCraftedDividersArea/>
			</main>
			<Footer />
		</>
	)
}

export default WoodCraftedDividers;
