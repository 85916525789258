import React from "react";
import { Link } from 'react-router-dom'


function Footer() {
  return (
    <footer>
      <div className="footer-top-area footer-bg footer-bg-padding">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-md-3">
              <div className="footer-widget">
                <div className="f-logo logo-align-center">
                  <Link to="/" aria-label=""><img src="img/logo/logo-new.svg" alt="" aria-label="logo" /></Link>
                </div>
                <div className="footer-contact justify-content-center gstin-display-block">
                  <div className="content">
                  <span className="gstin-align"> GSTIN:</span>
                    <h2 className="title gstin-font-size gstin-align">08AHAPM6760G3Z8</h2>
                  </div>
                </div>
                <div className="footer-text logo-align-center">
                  <p className="footer-text-size">Elevate your space with our exquisite furniture collection at Maheshwari Furniture. </p>
                </div>
                {/* <div className="footer-contact justify-content-center contact-white-space">
                  <div className="icon"><i className="fas fa-headphones" /></div>
                  <div className="content">
                    <h2 className="title"><a href="tel:9414045241" aria-label="logo">+91 94140-45241</a></h2>
                    <span>Call Now</span>
                  </div>
                </div> */}
                {/* <div className="footer-social">
                  <ul className="justify-content-center">
                    <li><a href="https://www.facebook.com/maheshwarifurniture" aria-label="logo"><i className="fab fa-facebook-f icon-size" /></a></li>
                    <li><a href="https://www.facebook.com/Maheshwari2019?mibextid=qWsEUC" aria-label="logo"><i className="fas fa-flag icon-size" /></a></li>
                    <li><a href="https://www.instagram.com/maheshwarifurniture.online" aria-label="logo"><i className="fab fa-instagram icon-size" /></a></li>
                    <li><a href="https://www.youtube.com/@maheshwarifurniture" aria-label="logo"><i className="fab fa-youtube icon-size" /></a></li>
                    <li><a href="https://wa.me/919414045241" aria-label="logo"><i className="fab fa-whatsapp icon-size" /></a></li>
                  </ul>
                </div> */}
              </div>
            </div>
            <div className="col-md-3 col-6">
              <div className="footer-widget">
                <h2 className="fw-title">Quick Links</h2>
                <div className="fw-link">
                  <ul>
                    <li><Link to="/terms-of-use">Terms of Use</Link></li>
                    <li><Link to="/privacy-and-policy">Privacy Policy</Link></li>
                    <li><Link to="/return-policy">Return Policy</Link></li>
                    <li><Link to="/about-us">About Us</Link></li>
                    <li><Link to="/contacts">Team</Link></li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-6">
              <div className="footer-widget">
                <h2 className="fw-title">Our Services</h2>
                <div className="fw-link">
                  <ul>
                    <li><Link to="/interiors">Interiors</Link></li>
                    <li><Link to="/modular">Modular</Link></li>
                    <li><Link to="/best-sellers">Best Sellers</Link></li>
                    <li><Link to="/categories">Categories</Link></li>
                    <li><Link to="/contacts">Contact Us</Link></li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="footer-widget">
                <h2 className="fw-title">Contact Us</h2>
                {/* <div className="fw-insta">
                  <ul>
                    <li><a href="/#" aria-label="logo"><img src="img/images/1.png" alt="" /></a></li>
                    <li><a href="/#" aria-label="logo"><img src="img/images/2.png" alt="" /></a></li>
                    <li><a href="/#" aria-label="#"><img src="img/images/3.png" alt="" /></a></li>
                    <li><a href="/#" aria-label="#"><img src="img/images/4.png" alt="" /></a></li>
                    <li><a href="/#" aria-label="#"><img src="img/images/5.png" alt="" /></a></li>
                    <li><a href="/#" aria-label="#"><img src="img/images/6.png" alt="" /></a></li>
                  </ul>
                </div> */}
                <div className="footer-contact justify-content-center">
                  <div className="icon"><i className="fas fa-map-marker-alt" /></div>
                  <div className="content">
                      <a className="footer-add-color" href="https://maps.app.goo.gl/cxoLKcJbvhoWTKmeA" target="_blank" rel="noopener noreferrer" aria-label="Shop Location">
                      206/207 Heeranagar-A Near 200ft bypass, Service Lane, Jaipur, RJ
                      </a>
                  </div>
                </div>
                <div className="footer-contact">
                  <div className="icon"><i className="fas fa-headphones" /></div>
                  <div className="content">
                    <h2 className="title footer-number-size"><a href="tel:9414045241" aria-label="logo">+91 94140-45241</a></h2>
                    <span>Call Now</span>
                  </div>
                </div>
                <div className="footer-social">
                  <ul className="justify-content-center">
                    <li><a href="https://www.facebook.com/maheshwarifurniture" aria-label="logo"><i className="fab fa-facebook-f icon-size" /></a></li>
                    <li><a href="https://www.facebook.com/Maheshwari2019?mibextid=qWsEUC" aria-label="logo"><i className="fas fa-flag icon-size" /></a></li>
                    <li><a href="https://www.instagram.com/maheshwarifurniture.online" aria-label="logo"><i className="fab fa-instagram icon-size" /></a></li>
                    <li><a href="https://www.youtube.com/@maheshwarifurniture" aria-label="logo"><i className="fab fa-youtube icon-size" /></a></li>
                    <li><a href="https://wa.me/919414045241" aria-label="logo"><i className="fab fa-whatsapp icon-size" /></a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright-area">
       <div className="container">
         <div className="row">
             <div className="col-md-8">
               <div className="copyright-text Vision-color">
                 <span>Copyright © Maheshwari Furniture.</span>
                 <span className="reserved-rights Reserved-display Vision-color"> All Rights Reserved.</span>
                </div>
              </div>
          </div>
       </div>
      </div>
    </footer>

  )
}

export default Footer;
