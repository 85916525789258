import React from 'react';
import LoungeChairBeigeBreadcumb from '../components/ourshop/Comfortersshopdetails/LoungeChairBeigeBreadcumb'
import LoungeChairBeigeArea from '../components/ourshop/Comfortersshopdetails/LoungeChairBeigeArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function LoungeChairBeigePages() {
    return (
        <>
            <Header />
            <main>
                <LoungeChairBeigeBreadcumb />
                <LoungeChairBeigeArea />
            </main>
            <Footer />
        </>
    )
}

export default LoungeChairBeigePages; 