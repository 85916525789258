import React from 'react';
import ThreeandoneWoodenSofaSetHero from '../components/ourshop/sheeshamsofa/ThreeandoneWoodenSofaSetHero'
import ThreeandoneWoodenSofaSetHome from '../components/ourshop/sheeshamsofa/ThreeandoneWoodenSofaSetHome'
import Header from "../components/Header";
import Footer from "../components/Footer";

function ThreeandoneWoodenSofaSet() {
	return (
		<>
			<Header />
			<main>
				<ThreeandoneWoodenSofaSetHome />
				<ThreeandoneWoodenSofaSetHero />
			</main>
			<Footer />
		</>
	)
}

export default ThreeandoneWoodenSofaSet;
