import React from 'react';
import WoodenRoomPartitionsDetailsArea from '../components/ourshop/Partitions/WoodenRoomPartitionsDetailsArea'
import WoodenRoomPartitionsArea from '../components/ourshop/Partitions/WoodenRoomPartitionsArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function WoodenRoomPartitions() {
	return (
		<>
			<Header />
			<main>
				<WoodenRoomPartitionsDetailsArea />
				<WoodenRoomPartitionsArea/>
			</main>
			<Footer />
		</>
	)
}

export default WoodenRoomPartitions;
