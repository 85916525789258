import React from 'react';
import HighBackRevolvingChairBreadcumb from '../components/ourshop/OfficeChairshopdetails/HighBackRevolvingChairBreadcumb'
import HighBackRevolvingChairArea from '../components/ourshop/OfficeChairshopdetails/HighBackRevolvingChairArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function HighBackRevolvingChairPages() {
    return (
        <>
            <Header />
            <main>
                <HighBackRevolvingChairBreadcumb />
                <HighBackRevolvingChairArea />
            </main>
            <Footer />
        </>
    )
}

export default HighBackRevolvingChairPages; 