import React from 'react';
import Slider from 'react-slick'
import { Link } from 'react-router-dom'

function HomeAdoptionGallery() {
	const settings = {
		dots: false,
		infinite: true,
		speed: 1000,
		autoplay: true,
		arrows: false,
		autoplaySpeed: 4000,
		slidesToShow: 1,
		slidesToScroll: 1,
		vertical: true,
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					infinite: true,
				}
			},
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					speed: 1000,
				}
			},
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					arrows: false,
					speed: 1000,
				}
			},
			{
				breakpoint: 575,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					arrows: false,
					speed: 1000,
				}
			},
		]
	}
	return (
		<section className="breeder-gallery-area breeder-gallery-padding">
			<div className="container">
				<div className="row align-items-center">
					<div className="col-lg-6 order-0 order-lg-2">
						<Slider className="breeder-gallery-active" {...settings}>
							<div className="breeder-gallery-item">
								<img src="img/images/homeone.png" alt="" />
							</div>
							<div className="breeder-gallery-item">
								<img src="img/images/home6.png" alt="" />
							</div>
							<div className="breeder-gallery-item">
								<img src="img/images/home2.png" alt="" />
							</div>
							<div className="breeder-gallery-item">
								<img src="img/images/home3.png" alt="" />
							</div>
							<div className="breeder-gallery-item">
								<img src="img/images/home4.png" alt="" />
							</div>
							<div className="breeder-gallery-item">
								<img src="img/images/home5.png" alt="" />
							</div>
						</Slider>
					</div>
					<div className="col-lg-6">
						<div className="adoption-content">
							<h2 className="sub-title main-slider-font-weight">Discover Our Latest Arrivals!</h2>
							<h2 className="title font-size main-slider-font-weight">Introducing Customizable<span> Canning </span>Handwork </h2>
							<p className="text-justify">Customizable canning handwork for your home decor needs. Elevate the ambiance of your living space with our meticulously crafted furniture pieces that blend tradition with modern elegance. Our artisans specialize in the intricate art of canning handwork, bringing timeless craftsmanship to contemporary designs.<br/>Ready to elevate your décor to the next level? Simply reach out to us for a personalized quote and let our team bring your vision to life.</p>
							
							<Link to="/#" className="btn main-slider-font-weight" tabIndex="-1">View More</Link>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

export default HomeAdoptionGallery;
