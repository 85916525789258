import React from 'react';
import SheeshamWoodKingSizeBedDetailsBreadcumb from '../components/ourshop/Bedshopdetails/SheeshamWoodKingSizeBedDetailsBreadcumb'
import SheeshamWoodKingSizeBedDetailsArea from '../components/ourshop/Bedshopdetails/SheeshamWoodKingSizeBedDetailsArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function SheeshamWoodKingSizeBedDetailsPage() {
    return (
        <>
            <Header />
            <main>
                <SheeshamWoodKingSizeBedDetailsBreadcumb />
                <SheeshamWoodKingSizeBedDetailsArea />
            </main>
            <Footer />
        </>
    )
}

export default SheeshamWoodKingSizeBedDetailsPage;