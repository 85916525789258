import React, { useEffect } from 'react';
import 'magnific-popup';



function FaqContact({ afterElment }) {
  return (

    <div className="col-lg-6 col-md-8 order-2 order-lg-0">
      <div className="faq-wrapper">
        <div className="section-title mb-35">
          <h1 className="sub-title main-slider-font-weight">FAQ Question</h1>
          <h2 className="title main-slider-font-weight">Answers to your Furniture Questions</h2>
        </div>
        <div className="accordion" id="accordionExample">
          <div className="card">
            <div className="card-header" id="headingOne">
              <h2 className="mb-0">
                <button className="btn btn-link btn-block text-left main-slider-font-weight" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                  Do you offer delivery services?
                </button>
              </h2>
            </div>
            <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
              <div className="card-body">
                Yes, we offer delivery services for most of our products. Delivery options and fees may vary depending on your location and the size of the item.
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header" id="headingTwo">
              <h2 className="mb-0">
                <button className="btn btn-link btn-block text-left collapsed main-slider-font-weight" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                  Do you have a showroom where I can see your furniture in person?
                </button>
              </h2>
            </div>
            <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
              <div className="card-body">
                Yes, we have a showroom located at 206/207 Heeranagar-A Near 200ft bypass, Service Lane, Jaipur, RJ. You can visit us during our business hours to see our furniture collection in person and speak with our sales representatives.
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header" id="headingThree">
              <h2 className="mb-0">
                <button className="btn btn-link btn-block text-left collapsed main-slider-font-weight" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                  Do you offer assembly services for your furniture?
                </button>
              </h2>
            </div>
            <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
              <div className="card-body">
                Yes, we offer assembly services for an additional fee. Please contact us for more information and pricing.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FaqContact;
