import React from 'react';
import Slider from 'react-slick'

function AdoptionGallery() {
	const settings = {
		dots: false,
		infinite: true,
		speed: 1000,
		autoplay: true,
		arrows: false,
		autoplaySpeed: 4000,
		slidesToShow: 1,
		slidesToScroll: 1,
		vertical: true,
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					infinite: true,
				}
			},
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					speed: 1000,
				}
			},
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					arrows: false,
					speed: 1000,
				}
			},
			{
				breakpoint: 575,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					arrows: false,
					speed: 1000,
				}
			},
		]
	}
	return (
		<section className="breeder-gallery-area breeder-gallery-padding">
			<div className="container">
				<div className="row align-items-center">
					<div className="col-lg-6 order-0 order-lg-2">
						<Slider className="breeder-gallery-active" {...settings}>
							<div className="breeder-gallery-item">
								<img src="img/images/about1.jpg" alt="" />
							</div>
							<div className="breeder-gallery-item">
								<img src="img/images/about2.jpg" alt="" />
							</div>
							<div className="breeder-gallery-item">
								<img src="img/images/about3.jpg" alt="" />
							</div>
							<div className="breeder-gallery-item">
								<img src="img/images/about4.jpg" alt="" />
							</div>
							<div className="breeder-gallery-item">
								<img src="img/images/about5.jpg" alt="" />
							</div>
						</Slider>
					</div>
					<div className="col-lg-6">
						<div className="adoption-content">
							<h2 className="sub-title main-slider-font-weight">About Us</h2>
							<h2 className="title font-size main-slider-font-weight">Crafting  <span>Comfort Style</span> <br /> for Your Home</h2>
							<p className="text-justify">Welcome to Maheshwari Furniture, your go-to destination for quality furniture. With years of experience, we pride ourselves on offering a diverse selection of stylish and durable pieces to enhance your space. Our dedicated team is committed to providing exceptional service, ensuring a seamless shopping experience from start to finish. Thank you for choosing Maheshwari Furniture for all your furniture needs.</p>
							<h2 className="sub-title"><b>Vikas Mohta</b></h2>Proprietor
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

export default AdoptionGallery;
