import React from 'react';
import DressingTableWithShelfBreadcumb from '../components/ourshop/Dressingtable/DressingTableWithShelfBreadcumb'
import DressingTableWithShelfArea from '../components/ourshop/Dressingtable/DressingTableWithShelfArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function DressingTableWithShelfPages() {
    return (
        <>
            <Header />
            <main>
                <DressingTableWithShelfBreadcumb />
                <DressingTableWithShelfArea />
            </main>
            <Footer />
        </>
    )
}

export default DressingTableWithShelfPages;