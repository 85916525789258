import React from 'react';
import FiveSeaterSheeshamSetHero from '../components/ourshop/sheeshamsofa/FiveSeaterSheeshamSetHero'
import FiveSeaterSheeshamSetHome from '../components/ourshop/sheeshamsofa/FiveSeaterSheeshamSetHome'
import Header from "../components/Header";
import Footer from "../components/Footer";

function FiveSeaterSheeshamSet() {
	return (
		<>
			<Header />
			<main>
				<FiveSeaterSheeshamSetHome />
				<FiveSeaterSheeshamSetHero />
			</main>
			<Footer />
		</>
	)
}

export default FiveSeaterSheeshamSet;
