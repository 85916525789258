import React from 'react';
import KingSizeBedDetailsBreadcumb from '../components/ourshop/Bedshopdetails/KingSizeBedDetailsBreadcumb'
import KingSizeBedDetailsArea from '../components/ourshop/Bedshopdetails/KingSizeBedDetailsArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function KingSizeBedDetailsPage() {
    return (
        <>
            <Header />
            <main>
                <KingSizeBedDetailsBreadcumb />
                <KingSizeBedDetailsArea />
            </main>
            <Footer />
        </>
    )
}

export default KingSizeBedDetailsPage; 