import React from 'react'

function HomeCounter() {
  return (
    <div className="row justify-content-center">
      <div className="col-lg-3 col-md-3 col-sm-6">
        <div className="counter-item">
          <h2 className="count main-slider-font-weight"><span className="odometer">500</span>+</h2>
          <p>Happy Customers</p>
        </div>
      </div>
      <div className="col-lg-3 col-md-3 col-sm-6">
        <div className="counter-item">
          <h2 className="count main-slider-font-weight"><span className="odometer">100</span>+</h2>
          <p>Projects Completed</p>
        </div>
      </div>
      <div className="col-lg-3 col-md-3 col-sm-6">
        <div className="counter-item">
          <h2 className="count main-slider-font-weight"><span className="odometer">30</span>+</h2>
          <p>Skilled Team</p>
        </div>
      </div>
      <div className="col-lg-3 col-md-3 col-sm-6">
        <div className="counter-item">
          <h2 className="count main-slider-font-weight"><span className="odometer">10</span>+</h2>
          <p>Best Advisors</p>
        </div>
      </div>
    </div>
  )
}

export default HomeCounter
