import React from 'react';
import IslandKitchenDesignBreadcumb from '../components/ourshop/Kitchenshopdetails/IslandKitchenDesignBreadcumb'
import IslandKitchenDesignArea from '../components/ourshop/Kitchenshopdetails/IslandKitchenDesignArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function IslandKitchenDesignPages() {
    return (
        <>
            <Header />
            <main>
                <IslandKitchenDesignBreadcumb />
                <IslandKitchenDesignArea />
            </main>
            <Footer />
        </>
    )
}

export default IslandKitchenDesignPages;