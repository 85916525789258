import React from 'react';


function Services({ style }) {
  return (
      <section className="appie-counter-area pt-60 pb-60" id="counter" style={style}>
          <div className="container">
              <div className="row">
                  <div className="col-lg-12">
                      <div className="appie-section-title">
                          <h1 className="appie-title">
                             What We Can Do For You ?
                          </h1>
                      </div>
                  </div>
              </div>
              <div className="row">
                  <div className="col-sm-4 col-xl-2 col-lg-2 col-6 col-md-4 pt-0">
                      <div
                          className="fashion-trend mt-30 wow animated fadeInUp"
                          data-wow-duration="2000ms"
                          data-wow-delay="200ms">
                          <div className="counter-content">
                              <div className="icon">
                              <img src="img/moduler_banner/kitchen_modular.png" alt="" />
                              </div>
                              <p>Modular Kitchen </p>
                          </div>
                      </div>
                  </div>
                  <div className="col-sm-4 col-xl-2 col-lg-2 col-6 col-md-4 pt-0">
                      <div
                          className="fashion-trend mt-30 item-2 wow animated fadeInUp"
                          data-wow-duration="2000ms"
                          data-wow-delay="400ms">
                          <div className="counter-content">
                              <div className="icon">
                                 <img src="img/moduler_banner/wardrobe.png" alt="" />
                              </div>
                              <p>Storage & Wardrobes</p>
                          </div>
                      </div>
                  </div>
                  <div className="col-sm-4 col-xl-2 col-lg-2 col-6 col-md-4 pt-0">
                      <div
                          className="fashion-trend mt-30 item-3 wow animated fadeInUp"
                          data-wow-duration="2000ms"
                          data-wow-delay="600ms">
                          <div className="counter-content">
                              <div className="icon">
                              <img src="img/moduler_banner/bedroom_furniture.png" alt="" />
                              </div>
                              <p>Bedroom Furniture </p>
                          </div>
                      </div>
                  </div>
                  <div className="col-sm-4 col-xl-2 col-lg-2 col-6 col-md-4 pt-0">
                      <div
                          className="fashion-trend mt-30 item-4 wow animated fadeInUp"
                          data-wow-duration="2000ms"
                          data-wow-delay="800ms">
                          <div className="counter-content">
                              <div className="icon">
                              <img src="img/moduler_banner/tv_units.png" alt="" />
                              </div>
                              <p>TV Units </p>
                          </div>
                      </div>
                  </div>
                  <div className="col-sm-4 col-xl-2 col-lg-2 col-6 col-md-4 pt-0">
                      <div
                          className="fashion-trend mt-30 wow animated fadeInUp"
                          data-wow-duration="2000ms"
                          data-wow-delay="200ms">
                          <div className="counter-content">
                              <div className="icon">
                              <img src="img/moduler_banner/false_ceiling.png" alt="" />
                              </div>
                              <p> Paint </p>
                          </div>
                      </div>
                  </div>
                  <div className="col-sm-4 col-xl-2 col-lg-2 col-6 col-md-4 pt-0">
                      <div
                          className="fashion-trend mt-30 item-2 wow animated fadeInUp"
                          data-wow-duration="2000ms"
                          data-wow-delay="400ms">
                          <div className="counter-content">
                              <div className="icon">
                              <img src="img/moduler_banner/lights.png" alt="" />
                              </div>
                              <p>Lights </p>
                          </div>
                      </div>
                  </div>
              </div>
              
              <div className="row">
                  
                  <div className="col-sm-4 col-xl-2 col-lg-2 col-6 col-md-4 pt-0">
                      <div
                          className="fashion-trend mt-30 item-3 wow animated fadeInUp"
                          data-wow-duration="2000ms"
                          data-wow-delay="600ms">
                          <div className="counter-content">
                              <div className="icon">
                              <img src="img/moduler_banner/wallpaper.png" alt="" />
                              </div>
                              <p>Wallpaper </p>
                          </div>
                      </div>
                  </div>
                  <div className="col-sm-4 col-xl-2 col-lg-2 col-6 col-md-4 pt-0">
                      <div
                          className="fashion-trend mt-30 item-4 wow animated fadeInUp"
                          data-wow-duration="2000ms"
                          data-wow-delay="800ms">
                          <div className="counter-content">
                              <div className="icon">
                              <img src="img/moduler_banner/curtain.png" alt="" />
                              </div>
                              <p>Curtain</p>
                          </div>
                      </div>
                  </div>
                  <div className="col-sm-4 col-xl-2 col-lg-2 col-6 col-md-4 pt-0">
                      <div
                          className="fashion-trend mt-30 wow animated fadeInUp"
                          data-wow-duration="2000ms"
                          data-wow-delay="200ms">
                          <div className="counter-content">
                              <div className="icon">
                              <img src="img/moduler_banner/decor1.png" alt="" />
                              </div>
                              <p> Décor</p>
                          </div>
                      </div>
                  </div>
                  <div className="col-sm-4 col-xl-2 col-lg-2 col-6 col-md-4 pt-0">
                      <div
                          className="fashion-trend mt-30 item-2 wow animated fadeInUp"
                          data-wow-duration="2000ms"
                          data-wow-delay="400ms">
                          <div className="counter-content">
                              <div className="icon">
                              <img src="img/moduler_banner/commercial_furniture.png" alt="" />
                              </div>
                              <p>Commercial Furniture </p>
                          </div>
                      </div>
                  </div>
                  <div className="col-sm-4 col-xl-2 col-lg-2 col-6 col-md-4 pt-0">
                      <div
                          className="fashion-trend mt-30 item-3 wow animated fadeInUp"
                          data-wow-duration="2000ms"
                          data-wow-delay="600ms">
                          <div className="counter-content">
                              <div className="icon">
                              <img src="img/moduler_banner/foyer_design.png" alt="" />
                              </div>
                              <p>Foyer Designs </p>
                          </div>
                      </div>
                  </div>
                  <div className="col-sm-4 col-xl-2 col-lg-2 col-6 col-md-4 pt-0">
                      <div
                          className="fashion-trend mt-30 item-3 wow animated fadeInUp"
                          data-wow-duration="2000ms"
                          data-wow-delay="600ms">
                          <div className="counter-content">
                              <div className="icon">
                              <img src="img/moduler_banner/false_ceiling2.png" alt="" />
                              </div>
                              <p>False Ceiling </p>
                          </div>
                      </div>
                  </div>
              </div>                  
            
              <div className="row">
                  <div className="col-sm-4 col-xl-2 col-lg-2 col-6 col-md-4 pt-0">
                      <div
                          className=" fashion-trend mt-30 wow animated fadeInUp"
                          data-wow-duration="2000ms"
                          data-wow-delay="200ms">
                          <div className="counter-content">
                              <div className="icon">
                              <img src="img/moduler_banner/pooja_unit.png" alt="" />
                              </div>
                              <p> Pooja unit </p>
                          </div>
                      </div>
                  </div>
                  <div className="col-sm-4 col-xl-2 col-lg-2 col-6 col-md-4 pt-0">
                      <div
                          className="fashion-trend mt-30 item-2 wow animated fadeInUp"
                          data-wow-duration="2000ms"
                          data-wow-delay="400ms">
                          <div className="counter-content">
                              <div className="icon">
                              <img src="img/moduler_banner/movable_furniture.png" alt="" />
                              </div>
                              <p>Movable Furniture  </p>
                          </div>
                      </div>
                  </div>
                  <div className="col-sm-4 col-xl-2 col-lg-2 col-6 col-md-4 pt-0">
                      <div
                          className="fashion-trend mt-30 item-3 wow animated fadeInUp"
                          data-wow-duration="2000ms"
                          data-wow-delay="600ms">
                          <div className="counter-content">
                              <div className="icon">
                              <img src="img/moduler_banner/wooden_flooring.png" alt="" />
                              </div>
                              <p>Wooden Flooring </p>
                          </div>
                      </div>
                  </div>
                  <div className="col-sm-4 col-xl-2 col-lg-2 col-6 col-md-4 pt-0">
                      <div
                          className=" fashion-trend mt-30 wow animated fadeInUp"
                          data-wow-duration="2000ms"
                          data-wow-delay="200ms">
                          <div className="counter-content">
                              <div className="icon">
                              <img src="img/moduler_banner/kids_area.png" alt="" />
                              </div>
                              <p> Kids area </p>
                          </div>
                      </div>
                  </div>
                  <div className="col-sm-4 col-xl-2 col-lg-2 col-6 col-md-4 pt-0">
                      <div
                          className="fashion-trend mt-30 item-2 wow animated fadeInUp"
                          data-wow-duration="2000ms"
                          data-wow-delay="400ms">
                          <div className="counter-content">
                              <div className="icon">
                              <img src="img/moduler_banner/flush_door.png" alt="" />
                              </div>
                              <p>Flush Door </p>
                          </div>
                      </div>
                  </div>
                  <div className="col-sm-4 col-xl-2 col-lg-2 col-6 col-md-4 pt-0">
                      <div
                          className="fashion-trend mt-30 item-3 wow animated fadeInUp"
                          data-wow-duration="2000ms"
                          data-wow-delay="600ms">
                          <div className="counter-content">
                              <div className="icon">
                              <img src="img/moduler_banner/study_area.png" alt="" />
                              </div>
                              <p>Study area </p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>
  );
}

export default Services;
