import React from 'react';
import CornerShelvesshop from '../components/ourshop/cornershelfs/CornerShelvesshop'
import CornerShelfshopdetiple from '../components/ourshop/cornershelfs/CornerShelvesShopDetailsArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function CornerShelves() {
	return (
		<>
			<Header />
			<main>
				<CornerShelfshopdetiple />
				<CornerShelvesshop />
			</main>
			<Footer />
		</>
	)
}

export default CornerShelves;
