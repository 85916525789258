import React from 'react';
import CenterTableWithStorageHero from '../components/ourshop/WoodenCane/CenterTableWithStorageHero'
import CenterTableWithStorageHome from '../components/ourshop/WoodenCane/CenterTableWithStorageHome'
import Header from "../components/Header";
import Footer from "../components/Footer";

function CenterTableWithStorage() {
	return (
		<>
			<Header />
			<main>
				<CenterTableWithStorageHome />
				<CenterTableWithStorageHero />
			</main>
			<Footer />
		</>
	)
}

export default CenterTableWithStorage;
