import React from 'react';
import WoodenScreensDetailsArea from '../components/ourshop/Partitions/WoodenScreensDetailsArea'
import WoodenScreensArea from '../components/ourshop/Partitions/WoodenScreensArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function WoodenScreens() {
	return (
		<>
			<Header />
			<main>
				<WoodenScreensDetailsArea />
				<WoodenScreensArea/>
			</main>
			<Footer />
		</>
	)
}

export default WoodenScreens;
