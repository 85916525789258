import React from 'react';
import LShapedOfficeTableBreadcumb from '../components/ourshop/OfficeCountershopdetails/LShapedOfficeTableBreadcumb'
import LShapedOfficeTableArea from '../components/ourshop/OfficeCountershopdetails/LShapedOfficeTableArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function LShapedOfficeTablePages() {
    return (
        <>
            <Header />
            <main>
                <LShapedOfficeTableBreadcumb />
                <LShapedOfficeTableArea />
            </main>
            <Footer />
        </>
    )
}

export default LShapedOfficeTablePages; 