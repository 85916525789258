import React from 'react';
import BlackOfficeChairBreadcumb from '../components/ourshop/OfficeChairshopdetails/BlackOfficeChairBreadcumb'
import BlackOfficeChairArea from '../components/ourshop/OfficeChairshopdetails/BlackOfficeChairArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function BlackOfficeChairPages() {
    return (
        <>
            <Header />
            <main>
                <BlackOfficeChairBreadcumb />
                <BlackOfficeChairArea />
            </main>
            <Footer />
        </>
    )
}

export default BlackOfficeChairPages; 