import React from 'react';
import ShopBreadcumb from '../components/ourshop/ShopBreadcumb'
import OfficeChairArea from '../components/ourshop/OfficeChairArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function OfficeChairPages() {
	return (
		<>
			<Header />
			<main>
				<ShopBreadcumb />
				<OfficeChairArea />
			</main>
			<Footer />
		</>
	)
}

export default OfficeChairPages;
