import React from 'react';
import RoundSideTableBreadcumb from '../components/ourshop/SideTableshopdetails/RoundSideTableBreadcumb'
import RoundSideTableArea from '../components/ourshop/SideTableshopdetails/RoundSideTableArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function RoundSideTablePages() {
    return (
        <>
            <Header />
            <main>
                <RoundSideTableBreadcumb />
                <RoundSideTableArea />
            </main>
            <Footer />
        </>
    )
}

export default RoundSideTablePages; 