import React from 'react';
import ServiceCounterBreadcumb from '../components/ourshop/OfficeCountershopdetails/ServiceCounterBreadcumb'
import ServiceCounterArea from '../components/ourshop/OfficeCountershopdetails/ServiceCounterArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function ServiceCounterPages() {
    return (
        <>
            <Header />
            <main>
                <ServiceCounterBreadcumb />
                <ServiceCounterArea />
            </main>
            <Footer />
        </>
    )
}

export default ServiceCounterPages; 