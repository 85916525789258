import React from 'react';
import RoundQuilttedSofaDetailsBreadcumb from '../components/ourshop/Sofashopdetails/RoundQuilttedSofaDetailsBreadcumb'
import RoundQuilttedSofaDetailsArea from '../components/ourshop/Sofashopdetails/RoundQuilttedSofaDetailsArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function RoundQuilttedSofaDetailsPage() {
    return (
        <>
            <Header />
            <main>
                <RoundQuilttedSofaDetailsBreadcumb />
                <RoundQuilttedSofaDetailsArea />
            </main>
            <Footer />
        </>
    )
}

export default RoundQuilttedSofaDetailsPage;