import React from 'react';
import { Link } from 'react-router-dom';

function WoodAndMetalOfficeDeskBreadcumb() {
    return (

        <section className="breadcrumb-area breadcrumb-bg" style={{ backgroundImage: 'url("img/bg/B1.jpg")' }}>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="breadcrumb-content">
                            <h2 className="title main-slider-font-weight">Shop Now</h2>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item main-slider-font-weight"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item main-slider-font-weight"><Link to="/officecounter">Office Counter</Link></li>
                                    <li className="breadcrumb-item active main-slider-font-weight" aria-current="page">Wood and Metal Office Desk</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default WoodAndMetalOfficeDeskBreadcumb; 