import React from 'react';
import ModularWorkspaceSystemBreadcumb from '../components/ourshop/OfficeFurnitureshopdetails/ModularWorkspaceSystemBreadcumb'
import ModularWorkspaceSystemArea from '../components/ourshop/OfficeFurnitureshopdetails/ModularWorkspaceSystemArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function ModularWorkspaceSystemPages() {
    return (
        <>
            <Header />
            <main>
                <ModularWorkspaceSystemBreadcumb />
                <ModularWorkspaceSystemArea />
            </main>
            <Footer />
        </>
    )
}

export default ModularWorkspaceSystemPages; 