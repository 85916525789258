import React from 'react';
import DivideCraftFurnishDetailsArea from '../components/ourshop/Partitions/DivideCraftFurnishDetailsArea'
import DivideCraftFurnishArea from '../components/ourshop/Partitions/DivideCraftFurnishArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function DivideCraftFurnish() {
	return (
		<>
			<Header />
			<main>
				<DivideCraftFurnishDetailsArea />
				<DivideCraftFurnishArea/>
			</main>
			<Footer />
		</>
	)
}

export default DivideCraftFurnish;
