import React from 'react';
import StudyTablewithDrawersandCabinetinery from '../components/ourshop/studytable/StudyTablewithDrawersandCabinetinery'
import StudyTablewithDrawersandCabinetaraey from '../components/ourshop/studytable/StudyTablewithDrawersandCabinetaraey'
import Header from "../components/Header";
import Footer from "../components/Footer";

function StudyTablewithDrawersandCabinet() {
	return (
		<>
			<Header />
			<main>
				<StudyTablewithDrawersandCabinetaraey />
				<StudyTablewithDrawersandCabinetinery />
			</main>
			<Footer />
		</>
	)
}

export default StudyTablewithDrawersandCabinet;
