import React from 'react';
import DiningChairWithFabricDetailsArea from '../components/ourshop/Diningchair/DiningChairWithFabricDetailsArea'
import DiningChairWithFabricArea from '../components/ourshop/Diningchair/DiningChairWithFabricArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function DiningChairWithFabric() {
	return (
		<>
			<Header />
			<main>
				<DiningChairWithFabricDetailsArea />
				<DiningChairWithFabricArea />
			</main>
			<Footer />
		</>
	)
}

export default DiningChairWithFabric;
