import React from 'react';
import { Link } from 'react-router-dom'

function Features() {
  return (
    <section className="adoption-shop-padding adoption-shop-area-padding">
      <div className="container">
           <div>
              <h2 className="main-slider-font-weight">Terms of Use – Maheshwari Furniture</h2>
              <p className="text-justify">
                Please read the following terms and conditions carefully. The 
                use of our services is subject to your compliance and 
                acceptance with the following statements.<br />
                Once you subscribe or use any of our services, you agree that 
                you have read and understood the terms and conditions and 
                ready to bound by the below statements.<br />
                Note: In these Terms, references to 'you', 'your’, ‘user' shall 
                mean the end user accessing the website, and 'we', 'us', 'our', 
                'Company' shall mean Wooden Street
              </p>
          </div>
           <div>  
                <h2 className="main-slider-font-weight">General</h2>
                <p className="text-justify">
                  The website <a href="/">maheshwarifurniturejaipur.com</a> is an e-commerce 
                  portal, owned and operated by Vikas Mohta. Use of the website 
                  is subject to your acceptance without any modifications to all 
                  the terms and conditions made by the Firm, as its sole 
                  discretion and stated on the website.
                </p>
                <p className="text-justify">
                  User will agree to the terms and privacy policy of the Firm by: <br />
                  1. Using the services and facilities provided by the website in 
                  any way. <br />
                  2. Browsing the content on the website.
                  <br />The Firm shall not be required to notify you about the 
                  modifications made in the terms; the revised terms should be 
                  determined by checking the website on a regular basis. Use of 
                  services and facilities on the website is subject to the current 
                  version of the policy, hence to know about any updates in the                     policy shall be the sole responsibility of the user.
                 </p>
          </div>
           <div>  
                   <h2 className="main-slider-font-weight">Product Policy</h2>
                   <p className="text-justify">
                        The product shown in the image is a reference product and the 
                        new manufactured product can look different from the one 
                        shown in the image due to various factors like lighting, manual 
                        polish, wood's texture, etc.<br />
                        Maheshwari Furniture’s products are available in a fixed set of 
                        Finishes. So please discuss the finish you need with our team 
                        once in order to receive the best quality product. <br />
                        Please note that in case of Solid Wood products, slight grains 
                        would be visible. There may be a slight variation in finish 
                        between the actual product and the 3-D designs which are 
                        being displayed on our website.
                  </p>
           </div>
          <div>  
               <h2 className="main-slider-font-weight">Services</h2>
               <p className="text-justify">
                  <span><a href="/">maheshwarifurniturejaipur.com</a></span> provides an Internet based 
                  platform to showcase their products and merchandise on the 
                  website. Such services enable users to purchase home 
                  furnishing products upon placing an order. Once an order 
                  placed, the Firm will ship the product, and the user will be 
                  entitled to pay for it.
               </p>
           </div>
          <div>
              <h2 className="main-slider-font-weight">Eligibility to Use</h2>
                <p className="text-justify">
                    According to the Indian Constitution, a person under the age of 
                    18 is minor. Services and facilities offered on our website shall 
                    not be available for minors. Please note if you are living outside 
                    of India then it's your responsibility to make sure whether you 
                    are eligible or not for our services.<br />
                    Persons barred under the laws of India are not permitted to use 
                    our services.<br />
                    The Firm reserves the end right to reject or grant the access to 
                    a new user or terminate the access of existing users, at any 
                    time. Also, the Firm shall not be required to furnish any reason 
                    for it.
                </p>
           </div>  
            <div>
                 <h2 className="main-slider-font-weight">Product and Pricing Information</h2>
                 <p className="text-justify">
                        The Pricing Information relating to the Product shall be as 
                        discussed on the call and as disclosed to You at the time of Your
                        purchase/order. We do not guarantee that the price will be the 
                        lowest for similar products within any given locality, city, region 
                        or geography. Prices and availability are subject to change 
                        without notice or any consequential liability to You. While 
                        Maheshwari Furniture strives to provide accurate information 
                        relating to Products and Services including pricing information 
                        or availability, but typographical and other errors may occur. In 
                        the event that a Product or Service is listed at an incorrect price
                        or with incorrect information due to an error, Maheshwari 
                        Furniture will have the right to modify the price of the Product 
                        or Services and contact You for further instructions via e-mail 
                        address provided by You at the time of cancel the order or 
                        notify You of such cancellation. If Maheshwari Furniture cancels 
                        the order after the payment has been processed, the said 
                        amount will be remitted to Your account from which the 
                        payment was made.<br />
                        Maheshwari Furniture reserves the right to change the 
                        discounts offered at any time during the sale and without prior 
                        notice.
                 </p>
             </div>
             <div> 
                   <h2 className="main-slider-font-weight">User Obligations</h2>
                   <p className="text-justify">
                        1. Your access to the website is a non-exclusive limited 
                        privilege, which is subject to compliance with the Terms of 
                        Use.<br />
                        2. The Services, Website and the materials offered to a user 
                        can only be used for purposes that are permitted by: (a) 
                        the Terms; and (b) any applicable law, regulation or 
                        relevant jurisdictions.<br />
                        3. You agree to adhere to the limitations on circulation, use 
                        and reproduction of any material accessed from us in 
                        accordance to the Terms mentioned in the 'Use of Material'
                        section.<br />
                        4. You agree not to access the website, the material or the 
                        services through any other interface, which is not provided
                        by the firm. Use of deep-link, robot, spider or other 
                        automatic device, program, algorithm or methodology, or 
                        any other similar process to access or attempt or access 
                        the website or content (or any portion thereof) shall be 
                        strictly prohibited.<br />
                        5. The Firm disclaims all the liabilities arising in relation to 
                        offensive, indecent or otherwise objectionable content that
                        is exposed to a user while accessing the website.<br />
                        6. The content posted or uploaded on the website should not 
                        be offensive and should be in accordance to the applicable
                        laws. Users are legally liable for the content they post. 
                        Hence you accept not to:</p>
                   <ul className="ul-padding">
                      <li className="display-style">
                            Discredit, harass, abuse or violate the legal rights
                      </li>
                      <li className="display-style"> 
                          Impersonate any person or entity, or misrepresent 
                          your affiliation with a person
                      </li>
                      <li className="display-style">
                          Publish, post or distribute any inappropriate, 
                          defamatory, obscene, indecent or unlawful material 
                          or information
                      </li>
                      <li className="display-style">
                          Upload the files, software or other material protected 
                          by intellectual property laws that are not owned by 
                          you
                      </li>
                      <li className="display-style">
                          Upload files containing virus or similar program that 
                          may damage the website
                      </li>
                      <li className="display-style">
                          Undertake any activity that disrupts the website, the 
                          servers or networks connected to the website
                      </li>
                      <li className="display-style">
                          Seek unauthorized access to the system or network 
                          or to any server of the website, or any attempt to 
                          gain access via illegitimate means such as hacking
                      </li>
                      <li className="display-style">
                            Scan the vulnerability or breach the authentication 
                            measure of the website or networks connected to the 
                            website
                      </li>
                      <li className="display-style">
                            Harm the website, resources, accounts, servers, 
                            networks connected to the websites, or any links 
                            affiliated to it
                      </li>
                      <li className="display-style">
                          Collect or share data about other users
                      </li>
                      <li className="display-style">
                            Use of any device to interfere the working of the 
                            website
                      </li>
                      <li className="display-style">
                            Use of the website content or material that is 
                            unlawful. Or to practice any illegal activity that 
                            violates the rights of the Company
                      </li>
                      <li className="display-style">
                            Conduct surveys, pyramid schemes, contests or chain
                            letters
                      </li>
                      <li className="display-style">
                            Download files of another, users posted on the 
                            website that is illegal to be distributed in such 
                            manner;
                      </li>
                      <li className="display-style">
                            Delete any author attributions, legal notices, 
                            proprietary designations, labels of the origin of the 
                            software contained in the uploaded file
                      </li>
                      <li className="display-style">
                            Infringe code of conduct applicable for to or any 
                            particular service
                      </li>
                      <li className="display-style">
                            Breach applicable laws or regulations in force within 
                            or outside India and
                      </li>
                      <li className="display-style">
                          Reverse engineer, copy, dispense, exhibit, reproduce,
                          publish, license, transfer or sell any information 
                          acquired from the Website.
                      </li>
                  </ul>
               </div>     
        <div>
            <p className="text-justify">
                  7. You agree that you are solely responsible for any loss or 
                  damage or any breach of your obligations under the terms.<br />
                  8. Acknowledge that Maheshwari Furniture is facilitating the 
                  products from various vendors. Hence the Firm shall not 
                  be supposed to be the seller of such services. The Firm 
                  shall not be responsible for any certifications, warranty or 
                  guarantee offered by the vendors.
             </p>
        </div>
      <div>
            <h2 className="main-slider-font-weight">
              Use of Material
            </h2>
            <p className="text-justify">
                1. The Firm grants you a non-exclusive, revocable, non-
                transferable right to access the product catalog or any 
                other material available on the website. In accordance with
                the terms, you may:
            </p>
          <ul className="ul-padding">
                <li className="display-style">
                    Access the material for personal, internal an 
                    informational purpose.
                </li>
                <li className="display-style">
                    Not alter the material available on the website.
                </li>
                <li className="display-style">
                    Not remove any copyright or proprietary notices 
                    available on the website.
                </li>
          </ul>
          <p className="text-justify">
              2. The design, layout and look, and feel of the website are 
              protected under intellectual property rights, thus copying 
              of the same shall not be granted here in.
              <br />
              3. Unless or otherwise permitted, users are not allowed to 
              download or install any of the software available on the 
              website
          </p>
      </div>
      <div>
            <h2 className="main-slider-font-weight">
              Usage Conduct
            </h2>
           <p className="text-justify">
              1. Basic requirements to access the website, such as 
              computer and Internet connection, shall be the sole 
              responsibility of the user.
              <br />
              2. The website should be used for the sensible purpose only, 
              users shall not indulge in activities that are not foreseen 
              by the website.<br />
              3. Loss or damage arises out of decaf authorization for a 
              transaction shall not be the liability of the Firm.
           </p>
      </div>
      <div>
            <h2 className="main-slider-font-weight">
                Intellectual Property Rights
            </h2>
           <p className="text-justify">
            The website, texts, graphics, UI, visual interfaces, images, 
            sounds and music, codes on the website is owned and 
            controlled by Maheshwari Furniture and the design, structure, 
            selection, coordination, expression, look-and-feel and 
            arrangement of such Content is protected by copyright, patent 
            and trademark laws, and various other intellectual property 
            rights.
          </p>
           <p className="text-justify">
              The trademarks, logos and service marks displayed on the 
              Website are the property of Maheshwari Furniture. You are not 
              permitted to use these Marks without the prior consent of 
              Maheshwari Furniture.
          </p>
          <p className="text-justify">
              Maheshwari Furniture owns all intellectual property rights to the
              trademark "Maheshwari Furniture" and variants thereof, and the
              domain name, including, without limitation, any and all rights, 
              title and interest in and to copyright, related rights, patents, 
              trade secrets and inventions (patent pending), goodwill, source 
              code, meta tags, databases, text, content, graphics, icons, and 
              hyperlinks.
           </p>
           <p className="text-justify">
              Except as expressly provided herein, You acknowledge and 
              agree that You shall not copy, republish, post, display, translate,
              transmit, reproduce or distribute any Content through any 
              medium without obtaining the necessary authorization from 
              Maheshwari Furniture.
           </p>
      </div>
      <div>
        <h2 className="main-slider-font-weight">
           Disclaimer of Warranties and Liability
        </h2>
         <p className="text-justify">
            We make sure that all the information posted on the website is 
            correct, however the Firm never warrants the accuracy, quality 
            and completeness of data, product, and services.
        </p>
         <p className="text-justify">
            The Firm shall not be liable for any direct, indirect, incidental or 
            consequential damages caused by:
        </p>
        <ul className="ul-padding">
              <li className="display-style">
                 The use or inability of use of services;
              </li>
              <li className="display-style">
                 Unauthorized access to the user's data; and
              </li>
              <li className="display-style">
                 Any matter is related to the services.
              </li>
        </ul>
        <p className="text-justify">
            The Firm shall not be liable for delay or inability to use of the 
            website or services, or any information, software and graphics 
            acquired through the website. Further, we are not responsible 
            for non- availability of the website during periodic maintenance 
            or accidental suspension caused due to technical reasons.<br />
            You acknowledge that data obtained from the website is done 
            entirely through your wisdom. Thus you agree that you will be 
            responsible for any damage and loss caused to your computer 
            with such data.
        </p>
      </div>
      <div>
            <h2 className="main-slider-font-weight">
              Violation of the Terms of Use
            </h2>
           <p className="text-justify">
              The Firm, in its sole and absolute discretion, may terminate the 
              access to the website of any user in case of violation of the 
              terms or additional terms. You consent to the injunctive or 
              equitable remedy taken by us after the contravention of the 
              terms of use that causes irreparable loss to the Firm.<br />
              You agree to indemnify and hold harmless Maheshwari 
              Furniture, its affiliate, directors, employees from and against 
              any and all losses, liabilities, damages, claims and expenses 
              incurred by the Firm that emerged of infringement, non-
              performance of any warranty or obligation performed by you.<br />
              Further, you agree to hold the Firm harmless against any claims
              made by any third party due to your use of the website that 
              caused damaged to a third party. The Firm will also be entitled 
              to redeem from you and you agree to pay any cost and fees of 
              such action.
           </p>
      </div>
      <div>
              <h2 className="main-slider-font-weight">
                Termination
              </h2>
              <p className="text-justify">
                1. The term will continue to apply until terminated by you or 
                Maheshwari Furniture. Further, you may terminate the 
                agreement by:
              </p>
                <ul className="ul-padding">
                    <li className="display-style">
                        Not accessing the website
                    </li>
                </ul>
              <p className="text-justify">
                  2.  Maheshwari Furniture at its sole and absolute discretion 
                  may terminate the terms of use with or without cause at 
                  any time if:
              </p>
                <ul className="ul-padding">
                    <li className="display-style">
                        Terms, conditions or any policy of the Firm, applicable
                        to you is violated.
                    </li>
                    <li className="display-style">
                        The provision of the services to you becomes 
                        unlawful.
                    </li>
                    <li className="display-style">
                        The provision of the services to you is no longer 
                        commercially viable.
                    </li>
                    <li className="display-style">
                        The Firm chooses to discontinue the access to the 
                        website, in general or specifically, for you.
                    </li>
                </ul>
                <p className="text-justify">
                      3. Termination or suspension due to technical reasons may 
                      include, removal of access to the website, deletion of user 
                      material(including files and material of user with account 
                      information), exclude you from accessing our website.<br />
                      4. The Firm shall not be liable to any third party for such 
                      termination.<br />
                      5. You acknowledge that termination shall not affect your 
                      liabilities and obligation.
                 </p>
      </div>
          <div>
                    <h2 className="main-slider-font-weight">
                          Governing Law
                    </h2>
                   <p className="text-justify">
                      1. Terms, transactions and any mutual claims between you 
                      and the Firm shall be governed in accordance with the 
                      laws of India.<br />
                      2. All claims and disputes arising in connection with the 
                      website shall be subject to the exclusive jurisdiction of 
                      the Jaipur, Rajasthan.
                   </p>
         </div>
      <div>
        <h2 className="main-slider-font-weight">
        Report Abuse
        </h2>
        <p>
        If Maheshwari Furniture permits you to post any material from 
        the website, you hereby undertake to ensure that it should not 
        be:
        </p>
        <ul className="ul-padding">
          <li className="display-style">
          Offensive, Abusive and threating.
          </li>
          <li className="display-style">
          Defame the applicable laws and rights.
          </li>
        </ul>
        <p>
        As per terms, users can be held legally liable for every content 
        or material posted on the website, and legally accountable if 
        the content or material is defamatory or protected by copyright 
        and trademark. In case you come across any abuse or violation 
        of the terms, please report to us at  <strong> <a href="mailto:maheshwarifurniturejpr@gmail.com.">
       maheshwarifurniturejpr@gmail.com.
          </a> </strong>
        </p>
      </div>
      <div>
        <h2 className="main-slider-font-weight">
        Privacy Policy     
        </h2>
        <p>
        You accept that you have read and fully understand the privacy 
        policy of the website, further you agree that the terms and 
        conditions of the policy are acceptable to you.
        </p>
      </div>
      <div>
        <h2 className="main-slider-font-weight">
        General Provisions
        </h2>
        <p>
        1. <b>Notice:</b> All notices from the Company will be served either
        by general notification on the website or via an email to 
        the designated account.<br />
        2. <b>Assignment:</b> Rights of the Company, under the terms of 
        use, are freely transferable to any third party without the 
        requirement to ask for your consent.<br />
        3. <b>Severability:</b> Provision of the terms, or any portion 
        because of that, found unenforceable by a competent 
        jurisdiction, shall not affect other portion of the terms.<br />
        4.<b> Waiver:</b> Failure to enforce or exercise any provision of the
        terms by the Company, shall not constitute a waiver of 
        such provision.<br />
        5.<b> Feedback and Information:</b> The Company is free to use 
        the feedback provided by you to the website. You warrant 
        that:
        </p>
        <ul className="ul-padding">
          <li className="display-style">
          Your feedback does not contain any confidential 
          information of you.
          </li>
          <li className="display-style">
          Maheshwari Furniture is not committed under any 
          liabilities of confidentiality.
          </li>
          <li className="display-style">
          Under any circumstances, you are not entitled to any 
          compensation for the feedback.
          </li>
        </ul>
        <p>
        6. Fidelity not guaranteed: Maheshwari Furniture hereby 
        disclaims the guarantee of exactness of the appearance or
        finish of the final product.
        </p>
      </div>
      <div>
     <h2 className="main-slider-font-weight"> What is the estimated time for a delivery?
     </h2>
     <p>
     Team Maheshwari Furniture is dedicated to dispatching your 
    products within 5 to 7 days of the timeline; however, in case of 
    exceptional situations like transport strike, riots, natural 
    calamities (like rains, flood, earthquakes, etc.), it might happen 
    that the Est delivery time may exceed than the promised 
    timeline. In such situations; you will be given updates about 
    your products, and Maheshwari Furniture will be responsible to 
    keep your products on priority and deliver at earliest as 
    possible. We humbly request your cooperation in above 
    exceptional situations, as your support is essential to ensure 
    that we continue to keep serving you with high quality furniture.<br />
    As the custom products are manufactured from scratch on 
    order, so sometimes it can get extended.
     </p>
      </div>
      <div>
        <h2 className="main-slider-font-weight">
        Are there any delivery or installation charges, or any 
        hidden fees?
        </h2>
        <p>
        Delivery is applicable depending upon the distance and weight 
        of products to be delivered and asked by the third party 
        delivering team.<br />
        Product installation will depend upon the delivery condition of 
        the product. No exception will be made to this rule.
        </p>
      </div>
      <div>
        <h2 className="main-slider-font-weight">
        What should I check when the product is delivered to 
        me?
        </h2>
        <p>
        When the product has been delivered to your doorstep, please 
        ensure the following: <br />
        1. Please check all the external surfaces of your product for 
        any forms of breakages, cracks, chip-offs, unfinished 
        patches or insect infestations.<br />
        2. In case there is some dust deposit, or the product lacks 
        shine, our delivery team will be able to fix this on-the-spot 
        by applying a coat of wood polish or by rubbing the 
        surface in question with a cloth. Please know that this is an
        accepted industry-standard way of cleaning the surface or 
        polishing it.<br />
        3. If there is a scratch or a crack on the product, raise this 
        issue with the delivery personnel as well as our team. Our 
        carpenter will resolve the issue on the spot, or we will 
        arrange a visit to rectify the issue. If the carpenter fails to 
        resolve the issue, we shall remanufacture the product/part 
        of the product.<br />
        4. For all products that inform any form of assembly, please 
        ensure that the delivery team assembles the product, so 
        that you have the peace of mind that everything fits well 
        and is accounted for.<br />
        5. For all products that require wall mounting/installation, 
        kindly decide, prior to the delivery of the product, on the 
        exact location in your house where you would want the 
        product to be placed. Instruct the delivery team of the 
        same.<br />
        6. For all products that are kept on the floor, such as tables 
        and chairs, ensure that the product stands stable and 
        straight. If there happens to be an uneven leg (having less 
        than 5mm of difference), our team will install bushes at 
        the foot of the leg so that the product remains balanced. If 
        the difference of the uneven leg is more than 5mm, we will
        take the item back and provide a replacement or a repair, 
        based on the extent of the change required.<br />
        7. For all forms of seating products, such as sofas, kindly sit 
        on the product to ensure that the whole structure is stable 
        with your weight on it. Also check the fabrics for any 
        deformities or defects.<br />
        8. We make sure that the insides of storage products, such 
        as drawers, are well-finished so as to not have any 
        splinters or loose pieces that might hurt your arms. 
        However, the extent of polish in these internal areas may 
        not match with the extent of polish on the external areas.<br />
        9. Wood, especially hardwoods, have natural physical 
        aspects, such as differing grain patterns and minimal stain 
        differences. No knots will be present in your product, as 
        these result in structural weakness, and are rejected at the
        quality control stage. Differing grain patterns and stain 
        differences are generally acceptable on hardwood 
        products.
        </p>
        <p>
        Once the product has been delivered to you, and after your 
        inspections, our delivery team has left your premises, 
        Maheshwari Furniture won’t be liable to any damage that occurs
        due to mishandling or rough usage. Any self-mishandled or 
        damage issues reported after successful delivery and 
        installation of the product, will not be considered as company’s 
        responsibility. On such circumstances the customer is 100% 
        accountable for these self-damages caused to the product and 
        shall not receive any replacement or refund from the company. 
        As well any damage on inspection if found due to rigid use or 
        mis-handling will overpass the criteria of refund/replacement or 
        compensations.
        </p>
      </div>
      <div>
        <h2 className="main-slider-font-weight">
        Will the product be exactly as shown on the Website?
        </h2>
        <p>
        Maheshwari Furniture tries its best to deliver Products and 
        Services exactly the way they are described on the Website. 
        However, the nature and tendency of natural material-based 
        products is that each piece of furniture is unique in its own way.
        Maheshwari Furniture hereby disclaims any guarantees of 
        exactness of the finish or appearance of the final Products or 
        Services ordered by the User over and above generally 
        acceptable standards on the same. The quality of the Products, 
        Services, information, or other material purchased or obtained 
        by you through the Website may not meet your expectations.
        </p>
      </div>
      <div>
        <h2 className="main-slider-font-weight">
        What if I receive a damaged product or there is a 
        manufacturing defect?
        </h2>
        <p>
        We practice highly specialized and strict quality control 
        measures to ensure that the product is up to our standards
        even at the time of delivery. In case you do happen to 
        receive a product that has been damaged during 
        transportation, or there is some manufacturing defect, 
        such as, balancing, levelling, finish, paint, fabric, etc., 
        please raise this issue to the delivery personnel at the time
        of delivery and also 
        contact <strong>
        <a href="mailto:maheshwarifurniturejpr@gmail.com.">
       maheshwarifurniturejpr@gmail.com.
          </a> </strong>
        with providing photographic evidence of the said issue. Our 
        team will assess the issue and get back to you within 1-2 
        business days. Depending on the degree of the damage in 
        question, we shall provide you the solution. For issues 
        concerning manufacturing defects, a solution will be 
        provided.
        </p>
        <p>
        Maheshwari Furniture shall have the sole right to 
        determine whether the product is defective or not. (also 
        mention specification)
        </p>
      </div>
      <div>
        <h2 className="main-slider-font-weight">
        Can I get all my products in one single delivery?
        </h2>
        <p>
        For this, you can request for the same as one delivery and 
        invariably it will be shipped as one delivery until and unless the 
        items are in separate warehouses. On the other hand, if the 
        products are from different vendors, you can’t get all the 
        products in one single delivery. As, one seller will dispatch it by 
        today and another may dispatch after two days, as per his 
        availability. So, you'll be receiving both packages at different 
        dates.
        </p>
      </div>
      <div>
        <h2 className="main-slider-font-weight">
        Wallet Terms of Use:
        </h2>
        <p>
        We accept every mode of payment convenient to the 
        customers.
        <br />
        We provide finance and EMI options to customers. 
        Finance Terms of Use:
        </p>
        <ul className="ul-padding">
          <li className="display-style">
          The HDFC bank executive will coordinate with customer to 
          provide the finance loan facilities and will check if it would 
          be applicable to the customer or not.
          </li>
          <li className="display-style">
          Maheshwari Furniture is not liable to any transactions held 
          in the finance repayments by the customer.
          </li>
          <li className="display-style">
           Maheshwari Furniture also provides EMI options depends 
          on your bank debit / credit card offers.
          </li>
        </ul>
      </div>
      <div>
        <h2 className="main-slider-font-weight">
        Contact
        </h2>
        <p>
        If you have any questions regarding this Agreement or the Site, 
        please contact us by email 
        at  <strong>
        <a href="mailto:maheshwarifurniturejpr@gmail.com.">
       maheshwarifurniturejpr@gmail.com.
          </a>
        </strong>
        </p>
      </div>
          
        </div>
    </section>
  )
}

export default Features;
