import React from 'react';
import ShopDetailsBreadcumb from '../components/shopdetails/ShopDetailsBreadcumb'
import ShopDetailsArea from '../components/shopdetails/ShopDetailsArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function ShopDetailsPage() {
	return (
		<>
			<Header />
			<main>
				<ShopDetailsBreadcumb />
				<ShopDetailsArea />
			</main>
			<Footer />
		</>
	)
}

export default ShopDetailsPage;
