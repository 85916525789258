import React from 'react';
import BrownRevolvingChairBreadcumb from '../components/ourshop/OfficeChairshopdetails/BrownRevolvingChairBreadcumb'
import BrownRevolvingChairArea from '../components/ourshop/OfficeChairshopdetails/BrownRevolvingChairArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function BrownRevolvingChairPages() {
    return (
        <>
            <Header />
            <main>
                <BrownRevolvingChairBreadcumb />
                <BrownRevolvingChairArea />
            </main>
            <Footer />
        </>
    )
}

export default BrownRevolvingChairPages; 