import React from 'react';
import IronForgeChairBreadcumb from '../components/ourshop/IronFurnitureshopdetails/IronForgeChairBreadcumb'
import IronForgeChairArea from '../components/ourshop/IronFurnitureshopdetails/IronForgeChairArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function IronForgeChairPages() {
    return (
        <>
            <Header />
            <main>
                <IronForgeChairBreadcumb />
                <IronForgeChairArea />
            </main>
            <Footer />
        </>
    )
}

export default IronForgeChairPages; 