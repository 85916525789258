import React from 'react';
import IronShoeRackBreadcumb from '../components/ourshop/IronFurnitureshopdetails/IronShoeRackBreadcumb'
import IronShoeRackArea from '../components/ourshop/IronFurnitureshopdetails/IronShoeRackArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function IronShoeRackPages() {
    return (
        <>
            <Header />
            <main>
                <IronShoeRackBreadcumb />
                <IronShoeRackArea />
            </main>
            <Footer />
        </>
    )
}

export default IronShoeRackPages; 