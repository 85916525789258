import React from 'react';
import RectangularWoodTableSpaceHero from '../components/ourshop/centertable/RectangularWoodTableSpaceHero'
import RectangularWoodTableSpaceHome from '../components/ourshop/centertable/RectangularWoodTableSpaceHome'
import Header from "../components/Header";
import Footer from "../components/Footer";

function RectangularWoodTableSpace() {
	return (
		<>
			<Header />
			<main>
				<RectangularWoodTableSpaceHome />
				<RectangularWoodTableSpaceHero />
			</main>
			<Footer />
		</>
	)
}

export default RectangularWoodTableSpace;
