import React from 'react';
import IronSeatBreadcumb from '../components/ourshop/IronFurnitureshopdetails/IronSeatBreadcumb'
import IronSeatArea from '../components/ourshop/IronFurnitureshopdetails/IronSeatArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function IronSeatPages() {
    return (
        <>
            <Header />
            <main>
                <IronSeatBreadcumb />
                <IronSeatArea />
            </main>
            <Footer />
        </>
    )
}

export default IronSeatPages; 