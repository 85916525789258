import React from 'react';
import StorageBedWithBedsideDetailsBreadcumb from '../components/ourshop/Bedshopdetails/StorageBedWithBedsideDetailsBreadcumb'
import StorageBedWithBedsideDetailsArea from '../components/ourshop/Bedshopdetails/StorageBedWithBedsideDetailsArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function StorageBedWithBedsideDetailsPage() {
    return (
        <>
            <Header />
            <main>
                <StorageBedWithBedsideDetailsBreadcumb />
                <StorageBedWithBedsideDetailsArea />
            </main>
            <Footer />
        </>
    )
}

export default StorageBedWithBedsideDetailsPage; 