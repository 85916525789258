import React, { useEffect } from 'react';
import MainSlider from '../components/MainSlider';
import Find from '../components/Find'
import HomeAdoptionGallery from '../components/HomeAdoptionGallery'
import Counter from '../components/Counter'
import Adoption from '../components/Adoption'
import BreedServices from '../components/BreedServices'
import Faq from '../components/Faq'
import Brand from '../components/Brand'
import AdoptionShop from '../components/AdoptionShop'
import Testimonial from '../components/Testimonial'
import Newsletter from '../components/NewsLetter'
import BlogsHome from '../components/BlogsHome'
import Header from "../components/Header";
import Footer from "../components/Footer";

function Home() {
	useEffect(() => {
		document.title = 'Maheshwari Furniture: Elevate Your Home with Premium Furnishings';
	}, []);
	return (
		<>
			<Header />
			<main>

				<MainSlider />
				<Find />
				<Counter />
				<Adoption />
				<BreedServices />
				<Faq />
				{/* <Brand /> */}
				<HomeAdoptionGallery/>
				<AdoptionShop />
				<Testimonial />
				<BlogsHome />
				<Newsletter />

			</main>
			<Footer />
		</>
	)
}

export default Home;
