import React, { useEffect } from 'react';
import ReturnPolicyCumb from '../components/contacts/ReturnPolicyCumb'
import Returnandrefunds from '../components/Returnandrefunds'
import Header from "../components/Header";
import Footer from "../components/Footer";

function ReturnPolicy() {
	useEffect(() => {
        document.title = 'About Maheshwari Furniture: Crafting Quality Furnishings for Your Home';
    }, []);
	return (
		<>
			<Header />
			<main>
			<ReturnPolicyCumb />
				<Returnandrefunds />
				
			</main>
			<Footer />
		</>
	)
}

export default ReturnPolicy;
