import React from 'react';
import SimpleKitchenDesignBreadcumb from '../components/ourshop/Kitchenshopdetails/SimpleKitchenDesignBreadcumb'
import SimpleKitchenDesignArea from '../components/ourshop/Kitchenshopdetails/SimpleKitchenDesignArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function SimpleKitchenDesignPages() {
    return (
        <>
            <Header />
            <main>
                <SimpleKitchenDesignBreadcumb />
                <SimpleKitchenDesignArea />
            </main>
            <Footer />
        </>
    )
}

export default SimpleKitchenDesignPages;