import React from 'react';
import OfficeChairInBlackBreadcumb from '../components/ourshop/OfficeChairshopdetails/OfficeChairInBlackBreadcumb'
import OfficeChairInBlackArea from '../components/ourshop/OfficeChairshopdetails/OfficeChairInBlackArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function OfficeChairInBlackPages() {
    return (
        <>
            <Header />
            <main>
                <OfficeChairInBlackBreadcumb />
                <OfficeChairInBlackArea />
            </main>
            <Footer />
        </>
    )
}

export default OfficeChairInBlackPages; 