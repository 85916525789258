import React from 'react';
import DressingTableWithStoolStorageDetailsArea from '../components/ourshop/Dressingtable/DressingTableWithStoolStorageDetailsArea'
import DressingTableWithStoolStorageArea from '../components/ourshop/Dressingtable/DressingTableWithStoolStorageArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function DressingTableWithStoolStorage() {
	return (
		<>
			<Header />
			<main>
				<DressingTableWithStoolStorageDetailsArea />
				<DressingTableWithStoolStorageArea/>
			</main>
			<Footer />
		</>
	)
}

export default DressingTableWithStoolStorage;
