import React from 'react';
import LShapedCornerSofawithStorageHero from '../components/ourshop/sheeshamsofa/LShapedCornerSofawithStorageHero'
import LShapedCornerSofawithStorageHome from '../components/ourshop/sheeshamsofa/LShapedCornerSofawithStorageHome'
import Header from "../components/Header";
import Footer from "../components/Footer";

function LShapedCornerSofawithStorage() {
	return (
		<>
			<Header />
			<main>
				<LShapedCornerSofawithStorageHome />
				<LShapedCornerSofawithStorageHero />
			</main>
			<Footer />
		</>
	)
}

export default LShapedCornerSofawithStorage;
