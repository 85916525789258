import React from 'react';
import WoodenChairCreamBreadcumb from '../components/ourshop/Comfortersshopdetails/WoodenChairCreamBreadcumb'
import WoodenChairCreamArea from '../components/ourshop/Comfortersshopdetails/WoodenChairCreamArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function WoodenChairCreamPages() {
    return (
        <>
            <Header />
            <main>
                <WoodenChairCreamBreadcumb />
                <WoodenChairCreamArea />
            </main>
            <Footer />
        </>
    )
}

export default WoodenChairCreamPages; 