import React from 'react';
import PartitionCraftersDetailsArea from '../components/ourshop/Partitions/PartitionCraftersDetailsArea'
import PartitionCraftersArea from '../components/ourshop/Partitions/PartitionCraftersArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function PartitionCrafters() {
	return (
		<>
			<Header />
			<main>
				<PartitionCraftersDetailsArea />
				<PartitionCraftersArea/>
			</main>
			<Footer />
		</>
	)
}

export default PartitionCrafters;
