import React from 'react';
import WoodWavesDividerDetailsArea from '../components/ourshop/Partitions/WoodWavesDividerDetailsArea'
import WoodWavesDividerArea from '../components/ourshop/Partitions/WoodWavesDividerArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function WoodWavesDivider() {
	return (
		<>
			<Header />
			<main>
				<WoodWavesDividerDetailsArea />
				<WoodWavesDividerArea/>
			</main>
			<Footer />
		</>
	)
}

export default WoodWavesDivider;
