import React from 'react';
import SheeshamDressingtableDetailsArea from '../components/ourshop/Dressingtable/SheeshamDressingtableDetailsArea'
import SheeshamDressingtableArea from '../components/ourshop/Dressingtable/SheeshamDressingtableArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function SheeshamDressingtable() {
	return (
		<>
			<Header />
			<main>
				<SheeshamDressingtableDetailsArea />
				<SheeshamDressingtableArea/>
			</main>
			<Footer />
		</>
	)
}

export default SheeshamDressingtable;
