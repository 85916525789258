import React from 'react';
import WoodenStoolDetailsArea from '../components/ourshop/Puffy/WoodenStoolDetailsArea'
import WoodenStoolArea from '../components/ourshop/Puffy/WoodenStoolArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function WoodenStool() {
	return (
		<>
			<Header />
			<main>
				<WoodenStoolDetailsArea />
				<WoodenStoolArea/>
			</main>
			<Footer />
		</>
	)
}

export default WoodenStool;
