import React from 'react';
import BohoSheeshamWoodBedDetailsBreadcumb from '../components/ourshop/Bedshopdetails/BohoSheeshamWoodBedDetailsBreadcumb'
import BohoSheeshamWoodBedDetailsArea from '../components/ourshop/Bedshopdetails/BohoSheeshamWoodBedDetailsArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function BohoSheeshamWoodBedDetailsPage() {
    return (
        <>
            <Header />
            <main>
                <BohoSheeshamWoodBedDetailsBreadcumb />
                <BohoSheeshamWoodBedDetailsArea />
            </main>
            <Footer />
        </>
    )
}

export default BohoSheeshamWoodBedDetailsPage;