import React from 'react';
import PrintedStoolDetailsArea from '../components/ourshop/Puffy/PrintedStoolDetailsArea'
import PrintedStoolArea from '../components/ourshop/Puffy/PrintedStoolArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function PrintedStool() {
	return (
		<>
			<Header />
			<main>
				<PrintedStoolDetailsArea />
				<PrintedStoolArea/>
			</main>
			<Footer />
		</>
	)
}

export default PrintedStool;
