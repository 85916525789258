import React from 'react';
import WoodenTablewithOpenSpaceHero from '../components/ourshop/centertable/WoodenTablewithOpenSpaceHero'
import WoodenTablewithOpenSpaceHome from '../components/ourshop/centertable/WoodenTablewithOpenSpaceHome'
import Header from "../components/Header";
import Footer from "../components/Footer";

function WoodenTablewithOpenSpace() {
	return (
		<>
			<Header />
			<main>
				<WoodenTablewithOpenSpaceHome />
				<WoodenTablewithOpenSpaceHero />
			</main>
			<Footer />
		</>
	)
}

export default WoodenTablewithOpenSpace;
