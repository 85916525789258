import React from 'react';
import OfficeCounterWithStorageBreadcumb from '../components/ourshop/OfficeCountershopdetails/OfficeCounterWithStorageBreadcumb'
import OfficeCounterWithStorageArea from '../components/ourshop/OfficeCountershopdetails/OfficeCounterWithStorageArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function OfficeCounterWithStoragePages() {
    return (
        <>
            <Header />
            <main>
                <OfficeCounterWithStorageBreadcumb />
                <OfficeCounterWithStorageArea />
            </main>
            <Footer />
        </>
    )
}

export default OfficeCounterWithStoragePages; 