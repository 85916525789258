import React from 'react';
import SolidWoodenTableHome from '../components/ourshop/centertable/SolidWoodenTableHome'
import SolidWoodenTableHero from '../components/ourshop/centertable/SolidWoodenTableHero'
import Header from "../components/Header";
import Footer from "../components/Footer";

function SolidWoodenTable() {
	return (
		<>
			<Header />
			<main>
				<SolidWoodenTableHome />
				<SolidWoodenTableHero />
			</main>
			<Footer />
		</>
	)
}

export default SolidWoodenTable;
