import React from 'react';
import SquareWoodenTableHero from '../components/ourshop/centertable/SquareWoodenTableHero'
import SquareWoodenTableHome from '../components/ourshop/centertable/SquareWoodenTableHome'
import Header from "../components/Header";
import Footer from "../components/Footer";

function SquareWoodenTable() {
	return (
		<>
			<Header />
			<main>
				<SquareWoodenTableHome />
				<SquareWoodenTableHero />
			</main>
			<Footer />
		</>
	)
}

export default SquareWoodenTable;
