import React from 'react';
import ThreeDoorWardrobeHero from '../components/ourshop/wardrobe/ThreeDoorWardrobeHero'
import ThreeDoorWardrobeHome from '../components/ourshop/wardrobe/ThreeDoorWardrobeHome'
import Header from "../components/Header";
import Footer from "../components/Footer";

function ThreeDoorWardrobe() {
	return (
		<>
			<Header />
			<main>
				<ThreeDoorWardrobeHome />
				<ThreeDoorWardrobeHero />
			</main>
			<Footer />
		</>
	)
}

export default ThreeDoorWardrobe;
