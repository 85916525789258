import React, { useEffect } from 'react';
import Hero from '../components/TermsofUse/Hero'
import Features from '../components/TermsofUse/Features'
import Header from "../components/Header";
import Footer from "../components/Footer";

function TermsofUse(){
	useEffect(() => {
        document.title = 'Maheshwari Furniture’s Bestselling Pieces for Your Home';
    }, []);
	return(
		<>
			<Header/>
			<main>
				<Hero/>
				<Features/>
			</main>
			<Footer/>
		</>
	)
}

export default TermsofUse;
