import React from "react";
function Newsletter(){
    return(
        <div className="newsletter-area pb-110 newsletter-area-pb">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <img src="img/mf/Mattress-3.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    )
}
export default Newsletter