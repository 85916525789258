import React from 'react';
import BohoSmallSizeWardrobeHero from '../components/ourshop/wardrobe/BohoSmallSizeWardrobeHero'
import BohoSmallSizeWardrobeHome from '../components/ourshop/wardrobe/BohoSmallSizeWardrobeHome'
import Header from "../components/Header";
import Footer from "../components/Footer";

function BohoSmallSizeWardrobe() {
	return (
		<>
			<Header />
			<main>
				<BohoSmallSizeWardrobeHome />
				<BohoSmallSizeWardrobeHero />
			</main>
			<Footer />
		</>
	)
}

export default BohoSmallSizeWardrobe;
