import React from 'react';
import RectangularSolidWoodCoffeeTableHero from '../components/ourshop/centertable/RectangularSolidWoodCoffeeTableHero'
import RectangularSolidWoodCoffeeTableHome from '../components/ourshop/centertable/RectangularSolidWoodCoffeeTableHome'
import Header from "../components/Header";
import Footer from "../components/Footer";

function RectangularSolidWoodCoffeeTable() {
	return (
		<>
			<Header />
			<main>
				<RectangularSolidWoodCoffeeTableHome />
				<RectangularSolidWoodCoffeeTableHero />
			</main>
			<Footer />
		</>
	)
}

export default RectangularSolidWoodCoffeeTable;
