import React from 'react';
import WoodenPodiumTableHero from '../components/ourshop/WoodenCane/WoodenPodiumTableHero'
import WoodenPodiumTableHome from '../components/ourshop/WoodenCane/WoodenPodiumTableHome'
import Header from "../components/Header";
import Footer from "../components/Footer";

function WoodenPodiumTable() {
	return (
		<>
			<Header />
			<main>
				<WoodenPodiumTableHome />
				<WoodenPodiumTableHero />
			</main>
			<Footer />
		</>
	)
}

export default WoodenPodiumTable;
