import React from 'react';
import SideEndTableBreadcumb from '../components/ourshop/SideTableshopdetails/SideEndTableBreadcumb'
import SideEndTableArea from '../components/ourshop/SideTableshopdetails/SideEndTableArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function SideEndTablePages() {
    return (
        <>
            <Header />
            <main>
                <SideEndTableBreadcumb />
                <SideEndTableArea />
            </main>
            <Footer />
        </>
    )
}

export default SideEndTablePages; 