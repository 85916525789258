import React from 'react';
import StudyTableCumShelfinery from '../components/ourshop/studytable/StudyTableCumShelfinery'
import StudyTableCumShelfaraey from '../components/ourshop/studytable/StudyTableCumShelfaraey'
import Header from "../components/Header";
import Footer from "../components/Footer";

function StudyTableCumShelf() {
	return (
		<>
			<Header />
			<main>
				<StudyTableCumShelfaraey />
				<StudyTableCumShelfinery />
			</main>
			<Footer />
		</>
	)
}

export default StudyTableCumShelf;
