import React from 'react';
import LoungeChairPinkBreadcumb from '../components/ourshop/Comfortersshopdetails/LoungeChairPinkBreadcumb'
import LoungeChairPinkArea from '../components/ourshop/Comfortersshopdetails/LoungeChairPinkArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function LoungeChairPinkPages() {
    return (
        <>
            <Header />
            <main>
                <LoungeChairPinkBreadcumb />
                <LoungeChairPinkArea />
            </main>
            <Footer />
        </>
    )
}

export default LoungeChairPinkPages; 