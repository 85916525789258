import React from 'react';
import WoodenShoeRackHero from '../components/ourshop/WoodenCane/WoodenShoeRackHero'
import WoodenShoeRackHome from '../components/ourshop/WoodenCane/WoodenShoeRackHome'
import Header from "../components/Header";
import Footer from "../components/Footer";

function WoodenShoeRack() {
	return (
		<>
			<Header />
			<main>
				<WoodenShoeRackHome />
				<WoodenShoeRackHero />
			</main>
			<Footer />
		</>
	)
}

export default WoodenShoeRack;
