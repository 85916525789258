import React from 'react';
import ShopBreadcumb from '../components/ourshop/ShopBreadcumb'
import WardrobeArea from '../components/ourshop/WardrobeArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function WardrobePages() {
	return (
		<>
			<Header />
			<main>
				<ShopBreadcumb />
				<WardrobeArea />
			</main>
			<Footer />
		</>
	)
}

export default WardrobePages;
