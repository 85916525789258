import React from 'react';
import SolidWoodenStoolDetailsArea from '../components/ourshop/Puffy/SolidWoodenStoolDetailsArea'
import SolidWoodenStoolArea from '../components/ourshop/Puffy/SolidWoodenStoolArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function SolidWoodenStool() {
	return (
		<>
			<Header />
			<main>
				<SolidWoodenStoolDetailsArea />
				<SolidWoodenStoolArea/>
			</main>
			<Footer />
		</>
	)
}

export default SolidWoodenStool;
