import React from 'react';
import ShopBreadcumb from '../components/ourshop/ShopBreadcumb'
import MandirArea from '../components/ourshop/MandirArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function MandirPages() {
	return (
		<>
			<Header />
			<main>
				<ShopBreadcumb />
				<MandirArea />
			</main>
			<Footer />
		</>
	)
}

export default MandirPages;
