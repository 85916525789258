import React from 'react';
import OneDoorWardrobeHero from '../components/ourshop/wardrobe/OneDoorWardrobeHero'
import OneDoorWardrobeHome from '../components/ourshop/wardrobe/OneDoorWardrobeHome'
import Header from "../components/Header";
import Footer from "../components/Footer";

function OneDoorWardrobe() {
	return (
		<>
			<Header />
			<main>
				<OneDoorWardrobeHome />
				<OneDoorWardrobeHero />
			</main>
			<Footer />
		</>
	)
}

export default OneDoorWardrobe;
