import React from 'react';
import HexagonWallShopDetailsArea from '../components/ourshop/cornershelfs/HexagonWallShopDetailsArea'
import HexagonWallShelfShopDetailsArea from '../components/ourshop/cornershelfs/HexagonWallShelfShopDetailsArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function HexagonWallShelf() {
	return (
		<>
			<Header />
			<main>
				<HexagonWallShopDetailsArea />
				<HexagonWallShelfShopDetailsArea />
			</main>
			<Footer />
		</>
	)
}

export default HexagonWallShelf;
