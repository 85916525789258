import React from 'react';
import BarCabinetBreadcumb from '../components/ourshop/Kitchenshopdetails/BarCabinetBreadcumb'
import BarCabinetArea from '../components/ourshop/Kitchenshopdetails/BarCabinetArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function BarCabinetPages() {
    return (
        <>
            <Header />
            <main>
                <BarCabinetBreadcumb />
                <BarCabinetArea />
            </main>
            <Footer />
        </>
    )
}

export default BarCabinetPages; 