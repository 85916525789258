import React from 'react';
import DressingTableWithShelvesandStorageDetailsArea from '../components/ourshop/Dressingtable/DressingTableWithShelvesandStorageDetailsArea'
import DressingTableWithShelvesandStorageArea from '../components/ourshop/Dressingtable/DressingTableWithShelvesandStorageArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function DressingTableWithShelvesandStorage() {
	return (
		<>
			<Header />
			<main>
				<DressingTableWithShelvesandStorageDetailsArea />
				<DressingTableWithShelvesandStorageArea />
			</main>
			<Footer />
		</>
	)
}

export default DressingTableWithShelvesandStorage;
