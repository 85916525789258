import React from 'react';
import IronCushionedStoolBreadcumb from '../components/ourshop/IronFurnitureshopdetails/IronCushionedStoolBreadcumb'
import IronCushionedStoolArea from '../components/ourshop/IronFurnitureshopdetails/IronCushionedStoolArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function IronCushionedStoolPages() {
    return (
        <>
            <Header />
            <main>
                <IronCushionedStoolBreadcumb />
                <IronCushionedStoolArea />
            </main>
            <Footer />
        </>
    )
}

export default IronCushionedStoolPages; 