import React from 'react';

function breederBreadCumb() {
  return (
    <section className="py-md-5 py-3 text-dark bg-white">
      <div className="container">
        <div className="row justify-content-center justify-content-md-start">
          <div className="col-md-12 mb-4 text-black text-center">
            <h2 className="fw-bold mb-md-4 faq-font-weight">Offering <span className="yellow-text">Modular Kitchen </span>
              Solutions
            </h2>
            <p className="text-justify">
              Maheshwari Furniture delivers the best-in-class modular kitchen services and design layouts that
              are customised as per the customer’s budget and requirements. Our modular kitchen solutions ensure
              that every side, drawers and corner of your kitchen is utilised effectively to provide maximum
              functionality, durability and design.
            </p>
          </div>
          <div className=" col-12 row align-items-stretch justify-content-center text-black">
            <div className="col-md-4 col-6 p-2">
              <div className="p-3 h-100">
                <div className="flex-column">
                  <div className="col-md-12 col-12 p-0 text-center mb-3">
                    <img
                      src="img/modular_kitchen_layouts/straight_modular_kitchen.png"
                      className="w-100"
                      alt="" />
                  </div>
                  <div className="col-md-12 col-12 p-0 text-center text-md-start">
                    <h1 className="font-size-18px main-slider-font-weight">Straight Modular Kitchen</h1>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-6 p-2">
              <div className="p-3 h-100">
                <div className="flex-column">
                  <div className="col-md-12 col-12 p-0 text-center mb-3">
                    <img
                      src="img/modular_kitchen_layouts/l_shaped_modular_kitchen.png"
                      className="w-100"
                      alt="" />
                  </div>
                  <div className="col-md-12 col-12 p-0 text-center text-md-start">
                    <h2 className="font-size-18px main-slider-font-weight">L-Shaped Modular Kitchen</h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-6 p-2">
              <div className="p-3 h-100">
                <div className="flex-column">
                  <div className="col-md-12 col-12 p-0 text-center mb-3">
                    <img
                      src="img/modular_kitchen_layouts/u-shaped_modular_kitchen.png"
                      className="w-100"
                      alt="" />
                  </div>
                  <div className="col-md-12 col-12 p-0 text-center text-md-start">
                    <h2 className="font-size-18px main-slider-font-weight">U-Shaped Modular Kitchen</h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-6 p-2">
              <div className="p-3 h-100">
                <div className="flex-column">
                  <div className="col-md-12 col-12 p-0 text-center mb-3">
                    <img
                      src="img/modular_kitchen_layouts/parallel_modular_kitchen.png"
                      className="w-100"
                      alt="" />
                  </div>
                  <div className="col-md-12 col-12 p-0 text-center text-md-start">
                    <h2 className="font-size-18px main-slider-font-weight">Parallel Modular Kitchen</h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-6 p-2">
              <div className="p-3 h-100">
                <div className="flex-column">
                  <div className="col-md-12 col-12 p-0 text-center mb-3">
                    <img
                      src="img/modular_kitchen_layouts/g_shaped_modular_kitchen.png"
                      className="w-100"
                      alt="" />
                  </div>
                  <div className="col-md-12 col-12 p-0 text-center text-md-start">
                    <h2 className="font-size-18px main-slider-font-weight">G-Shaped Modular Kitchen</h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-6 p-2">
              <div className="p-3 h-100">
                <div className="flex-column">
                  <div className="col-md-12 col-12 p-0 text-center mb-3">
                    <img
                      src="img/modular_kitchen_layouts/island_modular_kitchen.png"
                      className="w-100"
                      alt="" />
                  </div>
                  <div className="col-md-12 col-12 p-0 text-center text-md-start">
                    <h2 className="font-size-18px main-slider-font-weight">Island Modular Kitchen</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section >
  )
}

export default breederBreadCumb;
