import React from 'react';
import { Link } from 'react-router-dom'

function MandirArea() {
  return (
    <div className="shop-area pt-110 pb-110">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-12">
            <div className="shop-wrap">
              <h4 className="title main-slider-font-weight">All Iron Table</h4>
              <div className="row justify-content-start">
                <div className="col-lg-3 col-sm-6">
                  <div className="shop-item mb-55">
                    <div className="shop-thumb">
                      <Link to="/wall-hanging-divine-crafts"><img src="img/product/mandir1b.png" alt="" /></Link>
                    </div>
                    <div className="shop-content">
                      <span>MAHESHWARI FURNITURE</span>
                      <h4 className="title main-slider-font-weight"><Link to="/wall-hanging-divine-crafts">Wall Hanging Divine Crafts</Link></h4>
                      <div className="shop-content-bottom">
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="shop-item mb-55">
                    <div className="shop-thumb">
                      <Link to="/wooden-devotion-mandir"><img src="img/product/mandir2b.png" alt="" /></Link>
                    </div>
                    <div className="shop-content">
                      <span>MAHESHWARI FURNITURE</span>
                      <h4 className="title main-slider-font-weight"><Link to="/wooden-devotion-mandir">Wooden Devotion Mandir</Link></h4>
                      <div className="shop-content-bottom">
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="shop-item mb-55">
                    <div className="shop-thumb">
                      <Link to="/temple-wood-with-storage"><img src="img/product/mandir3b.png" alt="" /></Link>
                    </div>
                    <div className="shop-content">
                      <span>MAHESHWARI FURNITURE</span>
                      <h4 className="title main-slider-font-weight"><Link to="/temple-wood-with-storage">Temple Wood With Storage </Link></h4>
                      <div className="shop-content-bottom">
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="shop-item mb-55">
                    <div className="shop-thumb">
                      <Link to="/crafted-mandir"><img src="img/product/mandir4b.png" alt="" /></Link>
                    </div>
                    <div className="shop-content">
                      <span>MAHESHWARI FURNITURE</span>
                      <h4 className="title main-slider-font-weight"><Link to="/crafted-mandir">Crafted Mandir</Link></h4>
                      <div className="shop-content-bottom">
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="shop-item mb-55">
                    <div className="shop-thumb">
                      <Link to="/home-temple"><img src="img/product/mandir5b.png" alt="" /></Link>
                    </div>
                    <div className="shop-content">
                      <span>MAHESHWARI FURNITURE</span>
                      <h4 className="title main-slider-font-weight"><Link to="/home-temple">Home Temple</Link></h4>
                      <div className="shop-content-bottom">
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="shop-item mb-55">
                    <div className="shop-thumb">
                      <Link to="/wooden-home-temple"><img src="img/product/mandir6b.png" alt="" /></Link>
                    </div>
                    <div className="shop-content">
                      <span>MAHESHWARI FURNITURE</span>
                      <h4 className="title main-slider-font-weight"><Link to="/wooden-home-temple">Wooden Home Temple</Link></h4>
                      <div className="shop-content-bottom">
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="shop-item mb-55">
                    <div className="shop-thumb">
                      <Link to="/spacious-shelf-mandir"><img src="img/product/mandir7b.png" alt="" /></Link>
                    </div>
                    <div className="shop-content">
                      <span>MAHESHWARI FURNITURE</span>
                      <h4 className="title main-slider-font-weight"><Link to="/spacious-shelf-mandir">Spacious Shelf Mandir</Link></h4>
                      <div className="shop-content-bottom">
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="shop-item mb-55">
                    <div className="shop-thumb">
                      <Link to="/beautifully-carved-mandir"><img src="img/product/mandir8b.png" alt="" /></Link>
                    </div>
                    <div className="shop-content">
                      <span>MAHESHWARI FURNITURE</span>
                      <h4 className="title main-slider-font-weight"><Link to="/beautifully-carved-mandir">Beautifully Carved Mandir</Link></h4>
                      <div className="shop-content-bottom">
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="shop-item mb-55">
                    <div className="shop-thumb">
                      <Link to="/mdf-wooden-temple"><img src="img/product/mandir9b.png" alt="" /></Link>
                    </div>
                    <div className="shop-content">
                      <span>MAHESHWARI FURNITURE</span>
                      <h4 className="title main-slider-font-weight"><Link to="/mdf-wooden-temple">MDF Wooden Temple</Link></h4>
                      <div className="shop-content-bottom">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MandirArea;
