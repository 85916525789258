import React from 'react';
import EverydayThreeSeaterBenchHero from '../components/ourshop/WoodenCane/EverydayThreeSeaterBenchHero'
import EverydayThreeSeaterBenchHome from '../components/ourshop/WoodenCane/EverydayThreeSeaterBenchHome'
import Header from "../components/Header";
import Footer from "../components/Footer";

function EverydayThreeSeaterBench() {
	return (
		<>
			<Header />
			<main>
				<EverydayThreeSeaterBenchHome />
				<EverydayThreeSeaterBenchHero />
			</main>
			<Footer />
		</>
	)
}

export default EverydayThreeSeaterBench;
