import React from 'react';
import { Link } from 'react-router-dom'


function Find() {
  return (
    <div className="find-area">
      <div className="container custom-container">
        <div className="row">
          <div className="col-12">
            <form >
              <div className="find-wrap">
                <div className="location">
                  <i className="fas fa-route" />
                  Share Requirements
                </div>
                <div className="find-category">
                  <ul>
                    <li><Link to="/shop" aria-label=""><i className="fas fa-shopping-cart home-page-icon-font-size" aria-label="" />Choose Style</Link></li>
                    <li><Link to="/shop" aria-label=""><i className="fas fa-hammer home-page-icon-font-size" aria-label="" />Customise</Link></li>
                    <li><Link to="/shop" aria-label=""><i className=" fas fa-truck home-page-icon-font-size" aria-label="" />Delivery Delight</Link></li>
                  </ul>
                </div>
                <div className="other-find">
                  <div className="dropdown">
                    <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      Find Your Design
                    </button>
                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <Link className="dropdown-item" aria-label="" to="/categories">Beds</Link>
                      <Link className="dropdown-item" aria-label="" to="/categories">Dining Tables</Link>
                      <Link className="dropdown-item" aria-label="" to="/categories">Kitchen</Link>
                      <Link className="dropdown-item" aria-label="" to="/categories">Sofas</Link>
                      <Link className="dropdown-item" aria-label="" to="/categories">More Ideas</Link>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Find;
