import React from 'react';
import WoodenHomeTempleBreadcumb from '../components/ourshop/Mandirshopdetails/WoodenHomeTempleBreadcumb'
import WoodenHomeTempleArea from '../components/ourshop/Mandirshopdetails/WoodenHomeTempleArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function WoodenHomeTemplePages() {
    return (
        <>
            <Header />
            <main>
                <WoodenHomeTempleBreadcumb />
                <WoodenHomeTempleArea />
            </main>
            <Footer />
        </>
    )
}

export default WoodenHomeTemplePages; 