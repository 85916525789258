import React from 'react';
import TwoSeaterCurvedSofaDetailsBreadcumb from '../components/ourshop/Sofashopdetails/TwoSeaterCurvedSofaDetailsBreadcumb'
import TwoSeaterCurvedSofaDetailsArea from '../components/ourshop/Sofashopdetails/TwoSeaterCurvedSofaDetailsArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function TwoSeaterCurvedSofaDetailsPage() {
    return (
        <>
            <Header />
            <main>
                <TwoSeaterCurvedSofaDetailsBreadcumb />
                <TwoSeaterCurvedSofaDetailsArea />
            </main>
            <Footer />
        </>
    )
}

export default TwoSeaterCurvedSofaDetailsPage;