import React from 'react';
import DiningChairDetailsArea from '../components/ourshop/Diningchair/DiningChairDetailsArea'
import DiningChairArea from '../components/ourshop/Diningchair/DiningChairArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function DiningChair() {
	return (
		<>
			<Header />
			<main>
				<DiningChairDetailsArea />
				<DiningChairArea />
			</main>
			<Footer />
		</>
	)
}

export default DiningChair;
