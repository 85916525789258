import React from 'react';
import CaneWoodenChairSetHero from '../components/ourshop/WoodenCane/CaneWoodenChairSetHero'
import CaneWoodenChairSetHome from '../components/ourshop/WoodenCane/CaneWoodenChairSetHome'
import Header from "../components/Header";
import Footer from "../components/Footer";

function CaneWoodenChairSet() {
	return (
		<>
			<Header />
			<main>
				<CaneWoodenChairSetHome />
				<CaneWoodenChairSetHero />
			</main>
			<Footer />
		</>
	)
}

export default CaneWoodenChairSet;
