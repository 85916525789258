import React from 'react';
import LShapedKitchenDesignBreadcumb from '../components/ourshop/Kitchenshopdetails/LShapedKitchenDesignBreadcumb'
import LShapedKitchenDesignArea from '../components/ourshop/Kitchenshopdetails/LShapedKitchenDesignArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function LShapedKitchenDesignPages() {
    return (
        <>
            <Header />
            <main>
                <LShapedKitchenDesignBreadcumb />
                <LShapedKitchenDesignArea />
            </main>
            <Footer />
        </>
    )
}

export default LShapedKitchenDesignPages; 