import React from 'react';
import WorkplaceFurnishingsBreadcumb from '../components/ourshop/OfficeFurnitureshopdetails/WorkplaceFurnishingsBreadcumb'
import WorkplaceFurnishingsArea from '../components/ourshop/OfficeFurnitureshopdetails/WorkplaceFurnishingsArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function WorkplaceFurnishingsPages() {
    return (
        <>
            <Header />
            <main>
                <WorkplaceFurnishingsBreadcumb />
                <WorkplaceFurnishingsArea />
            </main>
            <Footer />
        </>
    )
}

export default WorkplaceFurnishingsPages; 