import React from 'react';
import BarStoolDetailsArea from '../components/ourshop/Puffy/BarStoolDetailsArea'
import BarStoolArea from '../components/ourshop/Puffy/BarStoolArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function BarStool() {
	return (
		<>
			<Header />
			<main>
				<BarStoolDetailsArea />
				<BarStoolArea/>
			</main>
			<Footer />
		</>
	)
}

export default BarStool;
