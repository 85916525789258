import React, { useEffect } from 'react';
import ShopBreadcumb from '../components/ourshop/ShopBreadcumb'
import ShopArea from '../components/ourshop/ShopArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function ShopPages(){
	useEffect(() => {
        document.title = 'Maheshwari Furniture’s Bestselling Pieces for Your Home';
    }, []);
	return(
		<>
			<Header/>
			<main>
				<ShopBreadcumb/>
				<ShopArea/>
			</main>
			<Footer/>
		</>
	)
}

export default ShopPages;
