import React from 'react';
import HomeDividersDetailsArea from '../components/ourshop/Partitions/HomeDividersDetailsArea'
import HomeDividersArea from '../components/ourshop/Partitions/HomeDividersArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function HomeDividers() {
	return (
		<>
			<Header />
			<main>
				<HomeDividersDetailsArea />
				<HomeDividersArea/>
			</main>
			<Footer />
		</>
	)
}

export default HomeDividers;
