import React from 'react';
import CornerStandShelfshop  from '../components/ourshop/cornershelfs/CornerStandShelfshop'
import CornerStandShelfshopdetiple from '../components/ourshop/cornershelfs/CornerStandShelfshopdetiple'
import Header from "../components/Header";
import Footer from "../components/Footer";

function CornerStandShelf() {
	return (
		<>
			<Header />
			<main>
				<CornerStandShelfshopdetiple/>
				<CornerStandShelfshop  />
			</main>
			<Footer />
		</>
	)
}

export default CornerStandShelf;
