import React from 'react';
import CornerShelfSetof3shop from '../components/ourshop/cornershelfs/CornerShelfSetof3shop'
import CornerShelfSetof3shopdetiple from '../components/ourshop/cornershelfs/CornerShelfSetof3shopdetiple'
import Header from "../components/Header";
import Footer from "../components/Footer";

function CornerShelfSetof3() {
	return (
		<>
			<Header />
			<main>
				<CornerShelfSetof3shopdetiple />
				<CornerShelfSetof3shop />
			</main>
			<Footer />
		</>
	)
}

export default CornerShelfSetof3;
