import React from 'react';
import MarbleDiningDetailsBreadcumb from '../components/ourshop/Diningshopdetails/MarbleDiningDetailsBreadcumb'
import MarbleDiningDetailsArea from '../components/ourshop/Diningshopdetails/MarbleDiningDetailsArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function MarbleDiningDetailsPage() {
    return (
        <>
            <Header />
            <main>
                <MarbleDiningDetailsBreadcumb />
                <MarbleDiningDetailsArea />
            </main>
            <Footer />
        </>
    )
}

export default MarbleDiningDetailsPage;