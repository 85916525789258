import React from 'react';
import ReceptionBreadcumb from '../components/ourshop/OfficeFurnitureshopdetails/ReceptionBreadcumb'
import ReceptionArea from '../components/ourshop/OfficeFurnitureshopdetails/ReceptionArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function ReceptionPages() {
    return (
        <>
            <Header />
            <main>
                <ReceptionBreadcumb />
                <ReceptionArea />
            </main>
            <Footer />
        </>
    )
}

export default ReceptionPages; 