import React from 'react';
import CornerShelfshop from '../components/ourshop/cornershelfs/CornerShelfshop'
import CornerShelfshopdetiple from '../components/ourshop/cornershelfs/CornerShelfshopdetiple'
import Header from "../components/Header";
import Footer from "../components/Footer";

function CornerShelf() {
	return (
		<>
			<Header />
			<main>
				<CornerShelfshopdetiple />
				<CornerShelfshop />
			</main>
			<Footer />
		</>
	)
}

export default CornerShelf;
