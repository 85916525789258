import React from 'react';
import WoodenSofaSetHero from '../components/ourshop/sheeshamsofa/WoodenSofaSetHero'
import WoodenSofaSetHome from '../components/ourshop/sheeshamsofa/WoodenSofaSetHome'
import Header from "../components/Header";
import Footer from "../components/Footer";

function WoodenSofaSet() {
	return (
		<>
			<Header />
			<main>
				<WoodenSofaSetHome />
				<WoodenSofaSetHero />
			</main>
			<Footer />
		</>
	)
}

export default WoodenSofaSet;
