import React from 'react';
// import { Component } from "react";
import Slider from 'react-slick'
import { Link } from 'react-router-dom'
function PrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div aria-hidden="true">
    <button type="button" className={className} onClick={onClick} aria-hidden="true" tabIndex="-1"><img src="img/icon/arrow2.png" alt="PREV"/></button>
    </div>
  );
}

function BreedServices() {
  const settings = {

    nav: true,
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: true,
    arrows: true,
    prevArrow: <PrevArrow />,
    autoplaySpeed: 4000,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          speed: 1000,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          speed: 1000,
        }
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          speed: 1000,
        }
      },
    ]
  }
  return (
    <section className="breeds-services pt-110 pb-110 breeds-services-pt services-pb">
      <div className="container" aria-hidden="true">
        <div className="row justify-content-center">
          <div className="col-xl-7 col-lg-9">
            <div className="section-title text-center mb-65">
              <div className="section-icon"><img src="img/icon/icon.png" alt="" /></div>
              <h1 className="sub-title main-slider-font-weight">Our Services</h1>
              <h2 className="title font-size main-slider-font-weight">Customized Manufacturing</h2>
              <p className="text-justify">Crafting bespoke furniture solutions that match your unique style and spatial requirements.With a commitment to excellence, we provide wholesale distribution, professional assembly, and strategic collaborations with interior designers.</p>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <Slider className="breed-services-active slick"{...settings}>
              <div className="breed-services-item"aria-hidden="true">
                <div className="thumb">
                  <img src="img/images/modular-kitchen.jpg" alt="" />
                </div>
                <div className="content" aria-hidden="true">
                  <h3 className="title main-slider-font-weight"><Link to="/modular" aria-label="Office Decor" tabIndex="-1">Modular Kitchen</Link></h3>
                </div>
              </div>
              <div className="breed-services-item" aria-hidden="true">
                <div className="thumb">
                  <img src="img/images/home-decor.jpg" alt="" />
                </div>
                <div className="content" aria-hidden="true">
                  <h3 className="title main-slider-font-weight"><Link to="" aria-label="Office Decor" tabIndex="-1">Home Decor</Link></h3>
                </div>
              </div>
              <div className="breed-services-item" aria-hidden="true">
                <div className="thumb">
                  <img src="img/images/customisation.jpg" alt="" />
                </div>
                <div className="content" aria-hidden="true">
                  <h3 className="title main-slider-font-weight"><Link to="" aria-label="Office Decor" tabIndex="-1">Customisation</Link></h3>
                </div>
              </div>
              <div className="breed-services-item" aria-hidden="true">
                <div className="thumb">
                  <img src="img/images/innovation.jpg" alt="" />
                </div>
                <div className="content" aria-hidden="true">
                  <h3 className="title main-slider-font-weight"><Link to="" aria-label="Office Decor" tabIndex="-1">Innovation</Link></h3>
                </div>
              </div>
              <div className="breed-services-item" aria-hidden="true">
                <div className="thumb">
                  <img src="img/images/maintenance.jpg" alt="" />
                </div>
                <div className="content" aria-hidden="true">
                  <h3 className="title main-slider-font-weight"><Link to="" aria-label="Office Decor" tabIndex="-1">Maintenance</Link></h3>
                </div>
              </div>
              <div className="breed-services-item" aria-hidden="true">
                <div className="thumb">
                  <img src="img/images/office-decor.jpg" alt="" />
                </div>
                <div className="content" aria-hidden="true">
                  <h3 className="title main-slider-font-weight"><Link to="" aria-label="Office Decor" tabIndex="-1">Office Decor</Link></h3>
                </div>
              </div>
            </Slider>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="breed-services-info" aria-hidden="true" style={{ backgroundImage: 'url("img/bg/bg.png")' }}>
              <h2 className="sub-title faq-font-weight">Explore, Choose, Transform</h2>
              <h3 className="title main-slider-font-weight">Diverse Collection</h3>
              <p>Discover a wide range of furniture options, meticulously curated to suit the unique needs of your home or office.</p>
              <Link to="/categories" aria-label="" className="btn main-slider-font-weight" tabIndex="-1">View All </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="breed-services-shape"><img src="img/images/breed_services_shape01.png" alt="" /></div>
      <div className="breed-services-shape shape-two"><img src="img/images/breed_services_shape02.png" alt="" /></div>
    </section>
  )
}

export default BreedServices;
