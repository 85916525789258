import React from 'react';
import StudyTablewithKeyboardTrayandSideStorageinery from '../components/ourshop/studytable/StudyTablewithKeyboardTrayandSideStorageinery'
import StudyTablewithKeyboardTrayandSideStoragearaey from '../components/ourshop/studytable/StudyTablewithKeyboardTrayandSideStoragearaey'
import Header from "../components/Header";
import Footer from "../components/Footer";

function StudyTablewithKeyboardTrayandSideStorage() {
	return (
		<>
			<Header />
			<main>
				<StudyTablewithKeyboardTrayandSideStoragearaey />
				<StudyTablewithKeyboardTrayandSideStorageinery />
			</main>
			<Footer />
		</>
	)
}

export default StudyTablewithKeyboardTrayandSideStorage;
