import React from 'react';
import WoodenCanninghome from '../components/ourshop/WoodenCanning/WoodenCanninghome'
import WoodenCanningArea from '../components/ourshop/WoodenCanning/WoodenCanningArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function WoodenCanningPages() {
	return (
		<>
			<Header />
			<main>
				<WoodenCanninghome />
				<WoodenCanningArea />
			</main>
			<Footer />
		</>
	)
}

export default WoodenCanningPages;
