import React from 'react';
import EverydaystoolHero from '../components/ourshop/WoodenCane/EverydaystoolHero'
import EverydaystoolHome from '../components/ourshop/WoodenCane/EverydaystoolHome'
import Header from "../components/Header";
import Footer from "../components/Footer";

function Everydaystool() {
	return (
		<>
			<Header />
			<main>
				<EverydaystoolHome />
				<EverydaystoolHero />
			</main>
			<Footer />
		</>
	)
}

export default Everydaystool;
