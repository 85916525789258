import React from 'react';
import FiveSeaterWoodenSofaSetwithSideStorageHero from '../components/ourshop/sheeshamsofa/FiveSeaterWoodenSofaSetwithSideStorageHero'
import FiveSeaterWoodenHome from '../components/ourshop/sheeshamsofa/FiveSeaterWoodenHome'
import Header from "../components/Header";
import Footer from "../components/Footer";

function FiveSeaterWoodenSofaSetwithSideStorage() {
	return (
		<>
			<Header />
			<main>
				<FiveSeaterWoodenHome />
				<FiveSeaterWoodenSofaSetwithSideStorageHero />
			</main>
			<Footer />
		</>
	)
}

export default FiveSeaterWoodenSofaSetwithSideStorage;
