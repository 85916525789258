import React from 'react';
import CurvedVertexDiningDetailsBreadcumb from '../components/ourshop/Diningshopdetails/CurvedVertexDiningDetailsBreadcumb'
import CurvedVertexDiningDetailsArea from '../components/ourshop/Diningshopdetails/CurvedVertexDiningDetailsArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function CurvedVertexDiningDetailsPage() {
    return (
        <>
            <Header />
            <main>
                <CurvedVertexDiningDetailsBreadcumb />
                <CurvedVertexDiningDetailsArea />
            </main>
            <Footer />
        </>
    )
}

export default CurvedVertexDiningDetailsPage;