import React from 'react';
import DiningChairMustardDetailsArea from '../components/ourshop/Diningchair/DiningChairMustardDetailsArea'
import DiningChairMustardArea from '../components/ourshop/Diningchair/DiningChairMustardArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function DiningChairMustard() {
	return (
		<>
			<Header />
			<main>
				<DiningChairMustardDetailsArea />
				<DiningChairMustardArea />
			</main>
			<Footer />
		</>
	)
}

export default DiningChairMustard;
