import React from 'react';
import SeatingSpaceBreadcumb from '../components/ourshop/OfficeFurnitureshopdetails/SeatingSpaceBreadcumb'
import SeatingSpaceArea from '../components/ourshop/OfficeFurnitureshopdetails/SeatingSpaceArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function SeatingSpacePages() {
    return (
        <>
            <Header />
            <main>
                <SeatingSpaceBreadcumb />
                <SeatingSpaceArea />
            </main>
            <Footer />
        </>
    )
}

export default SeatingSpacePages; 