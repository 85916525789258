import React from 'react';
import DoorWardrobewithMirrorHero from '../components/ourshop/wardrobe/DoorWardrobewithMirrorHero'
import DoorWardrobewithMirrorHome from '../components/ourshop/wardrobe/DoorWardrobewithMirrorHome'
import Header from "../components/Header";
import Footer from "../components/Footer";

function DoorWardrobewithMirror() {
	return (
		<>
			<Header />
			<main>
				<DoorWardrobewithMirrorHome />
				<DoorWardrobewithMirrorHero />
			</main>
			<Footer />
		</>
	)
}

export default DoorWardrobewithMirror;
