import React from 'react';
import Slider from 'react-slick'



function Hero() {
  const settings = {
    autoplay: true,
    arrows: false,
    dots: false,
    slidesToShow: 1,  
    responsive: [
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 3,
            },
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
            },
        },
    ],
};
  return (

    <section className="adoption-area-two adoption-area-two-padding">
      
      <div className="container custom-container">
        <Slider className="row adopt-active" {...settings}>
          <div className="col-xl">
            <div className="adoption-item adoption-item-padding">
              <div className="adopt-thumb-border-radius">
                <img src="img/moduler_banner/banner1.png" alt="" />
              </div>
            </div>
          </div>
          <div className="col-xl">
            <div className="adoption-item adoption-item-padding">
              <div className="adopt-thumb-border-radius">
                <img src="img/moduler_banner/banner2.png" alt="" />
              </div>
              
            </div>
          </div>
          <div className="col-xl">
            <div className="adoption-item adoption-item-padding">
              <div className="adopt-thumb-border-radius">
                <img src="img/moduler_banner/banner3.png" alt="" />
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </section>
  )
}

export default Hero;
