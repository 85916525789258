import React from 'react';
import OfficeCounterSpaceBreadcumb from '../components/ourshop/OfficeFurnitureshopdetails/OfficeCounterSpaceBreadcumb'
import OfficeCounterSpaceArea from '../components/ourshop/OfficeFurnitureshopdetails/OfficeCounterSpaceArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function OfficeCounterSpacePages() {
    return (
        <>
            <Header />
            <main>
                <OfficeCounterSpaceBreadcumb />
                <OfficeCounterSpaceArea />
            </main>
            <Footer />
        </>
    )
}

export default OfficeCounterSpacePages; 