import React from 'react';
import IronStoolBreadcumb from '../components/ourshop/IronFurnitureshopdetails/IronStoolBreadcumb'
import IronStoolArea from '../components/ourshop/IronFurnitureshopdetails/IronStoolArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function IronStoolPages() {
    return (
        <>
            <Header />
            <main>
                <IronStoolBreadcumb />
                <IronStoolArea />
            </main>
            <Footer />
        </>
    )
}

export default IronStoolPages; 