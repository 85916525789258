import React from 'react';
import TwoDoorWardrobewithMirrorHero from '../components/ourshop/wardrobe/TwoDoorWardrobewithMirrorHero'
import TwoDoorWardrobewithMirrorHome from '../components/ourshop/wardrobe/TwoDoorWardrobewithMirrorHome'
import Header from "../components/Header";
import Footer from "../components/Footer";

function TwoDoorWardrobewithMirror() {
	return (
		<>
			<Header />
			<main>
				<TwoDoorWardrobewithMirrorHome />
				<TwoDoorWardrobewithMirrorHero />
			</main>
			<Footer />
		</>
	)
}

export default TwoDoorWardrobewithMirror;
