import React from 'react';
import ForgeCoffeeTableBreadcumb from '../components/ourshop/IronFurnitureshopdetails/ForgeCoffeeTableBreadcumb'
import ForgeCoffeeTableArea from '../components/ourshop/IronFurnitureshopdetails/ForgeCoffeeTableArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function ForgeCoffeeTablePages() {
    return (
        <>
            <Header />
            <main>
                <ForgeCoffeeTableBreadcumb />
                <ForgeCoffeeTableArea />
            </main>
            <Footer />
        </>
    )
}

export default ForgeCoffeeTablePages; 