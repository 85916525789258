import React from 'react';
import { Link } from 'react-router-dom'

function ShopArea() {
  return (
    <section className="adoption-shop-area adoption-shop-area-padding">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-7 col-lg-9">
            <div className="section-title text-center mb-65">
              <h2 className="sub-title main-slider-font-weight">Best Sellers</h2>
              <h2 className="title font-size main-slider-font-weight">Express Your Unique Style</h2>
              <p className="text-justify">Explore our collection of modern and stylish furniture, designed to elevate the aesthetics of your living and working spaces.Transform your living spaces with our collection of comfortable and stylish furniture, including sofas, beds, and more.</p>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6">
            <div className="adoption-shop-item">
              <div className="adoption-shop-thumb">
                <img src="img/product/bestseller5.jpg" alt="" />
              </div>
              <div className="adoption-shop-content">
                <h2 className="title main-slider-font-weight">L-Shaped Sofas</h2>
                <div className="adoption-meta">
                  <ul>
                    <li><i className="fas fa-cog" /><a href="/#">All customized</a></li>
                    <li><i className="far fa-calendar-alt" />On time delivery</li>
                  </ul>
                </div>
                <div className="adoption-rating">
                  <ul>
                    <li className="rating">
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                    </li>
                    <li className="price">Premium<span>Quality</span></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="adoption-shop-item">
              <div className="adoption-shop-thumb">
                <img src="img/product/bestseller2.jpg" alt="" />
              </div>
              <div className="adoption-shop-content">
                <h2 className="title main-slider-font-weight">4-Seater Dining</h2>
                <div className="adoption-meta">
                  <ul>
                    <li><i className="fas fa-cog" /><a href="/#">All customized</a></li>
                    <li><i className="far fa-calendar-alt" /> On time delivery</li>
                  </ul>
                </div>
                <div className="adoption-rating">
                  <ul>
                    <li className="rating">
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                    </li>
                    <li className="price">Premium<span>Quality</span></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="adoption-shop-item">
              <div className="adoption-shop-thumb">
                <img src="img/product/bestseller4.jpg" alt="" />
              </div>
              <div className="adoption-shop-content">
                <h2 className="title main-slider-font-weight">Beds</h2>
                <div className="adoption-meta">
                  <ul>
                    <li><i className="fas fa-cog" /><a href="/#">All customized</a></li>
                    <li><i className="far fa-calendar-alt" /> On time delivery</li>
                  </ul>
                </div>
                <div className="adoption-rating">
                  <ul>
                    <li className="rating">
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                    </li>
                    <li className="price">Premium<span>Quality</span></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="adoption-shop-item">
              <div className="adoption-shop-thumb">
                <img src="img/product/bestseller1.jpg" alt="" />
              </div>
              <div className="adoption-shop-content">
                <h2 className="title main-slider-font-weight">Rocking Chairs</h2>
                <div className="adoption-meta">
                  <ul>
                    <li><i className="fas fa-cog" /><a href="/#">All customized</a></li>
                    <li><i className="far fa-calendar-alt" /> On time delivery</li>
                  </ul>
                </div>
                <div className="adoption-rating">
                  <ul>
                    <li className="rating">
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                    </li>
                    <li className="price">Premium<span>Quality</span></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="adoption-shop-item">
              <div className="adoption-shop-thumb">
                <img src="img/product/bestseller3.jpg" alt="" />
              </div>
              <div className="adoption-shop-content">
                <h2 className="title main-slider-font-weight">6-Seater Dining</h2>
                <div className="adoption-meta">
                  <ul>
                    <li><i className="fas fa-cog" /><a href="/#">All customized</a></li>
                    <li><i className="far fa-calendar-alt" /> On time delivery</li>
                  </ul>
                </div>
                <div className="adoption-rating">
                  <ul>
                    <li className="rating">
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                    </li>
                    <li className="price">Premium<span>Quality</span></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="adoption-shop-item">
              <div className="adoption-shop-thumb">
                <img src="img/product/bestseller6.jpg" alt="" />
              </div>
              <div className="adoption-shop-content">
                <h2 className="title main-slider-font-weight">Study-Tables</h2>
                <div className="adoption-meta">
                  <ul>
                    <li><i className="fas fa-cog" /><a href="/#">All customized</a></li>
                    <li><i className="far fa-calendar-alt" /> On time delivery</li>
                  </ul>
                </div>
                <div className="adoption-rating">
                  <ul>
                    <li className="rating">
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                    </li>
                    <li className="price">Premium<span>Quality</span></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ShopArea;
