import React from 'react';
import TwoSeaterSofaDetailsBreadcumb from '../components/ourshop/Sofashopdetails/TwoSeaterSofaDetailsBreadcumb'
import TwoSeaterSofaDetailsArea from '../components/ourshop/Sofashopdetails/TwoSeaterSofaDetailsArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function TwoSeaterSofaDetailsPage() {
	return (
		<>
			<Header />
			<main>
				<TwoSeaterSofaDetailsBreadcumb />
				<TwoSeaterSofaDetailsArea />
			</main>
			<Footer />
		</>
	)
}

export default TwoSeaterSofaDetailsPage;
