import React from 'react';

function AdoptionFaq() {
  return (

    <section className="faq-area faq-two-bg faq-area-padding">
      <div className="faq-two-img" />
      <div className="container">
        <div className="row justify-content-end">
          <div className="col-xl-7 col-lg-6">
            <div className="faq-wrapper">
              <div className="section-title white-title mb-35">
                <h2 className="sub-title main-slider-font-weight">Why Choose Us?</h2>
                <h2 className="title main-slider-font-weight">Experience the Difference</h2>
              </div>
              <div className="accordion" id="accordionExample">
                <div className="card">
                  <div className="card-header" id="headingOne">
                    <h2 className="mb-0">
                      <button className="btn btn-link btn-block text-left faq-font-weight" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        Quality Craftsmanship
                      </button>
                    </h2>
                  </div>
                  <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                    <div className="card-body">
                      At Maheshwari Furniture, we pride ourselves on delivering furniture pieces crafted with the utmost attention to detail and quality materials.
                      Each item in our collection is meticulously crafted to ensure durability and timeless style.
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingTwo">
                    <h2 className="mb-0">
                      <button className="btn btn-link btn-block text-left collapsed faq-font-weight" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        Wide Selection
                      </button>
                    </h2>
                  </div>
                  <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                    <div className="card-body">
                      Whether you're furnishing a cozy apartment or a spacious home, we offer a diverse range of furniture styles to suit every taste and space.
                      From modern and minimalist to classic and elegant, our collection has something for everyone.
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingThree">
                    <h2 className="mb-0">
                      <button className="btn btn-link btn-block text-left collapsed faq-font-weight" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        Customization Options
                      </button>
                    </h2>
                  </div>
                  <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                    <div className="card-body">
                      We understand that every home is unique, which is why we offer customization options to tailor our furniture to your specific needs and preferences.
                      From fabric choices to finishes, our team is dedicated to bringing your vision to life.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="faq-shape"><img src="img/images/faq_shape.png" alt="" /></div>
    </section>
  )
}

export default AdoptionFaq;
