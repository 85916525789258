import React from 'react';
import ShopBreadcumb from '../components/ourshop/ShopBreadcumb'
import SheeshamSofaArea from '../components/ourshop/SheeshamSofaArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function SheeshamSofaPages() {
	return (
		<>
			<Header />
			<main>
				<ShopBreadcumb />
				<SheeshamSofaArea />
			</main>
			<Footer />
		</>
	)
}

export default SheeshamSofaPages;
