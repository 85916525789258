import React from 'react';



function Counter() {


  return (
    <section className="counter-area counter-bg counter-bg-padding" style={{ backgroundImage: "url('img/bg/counter_bg.jpg')" }}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6 col-lg-8">
            <div className="counter-title text-center mb-65">
              <h1 className="sub-title">Why Choose Us?</h1>
              <h2 className="title font-size main-slider-font-weight">Custom Furniture Expertise at Your Service</h2>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-3 col-md-3 col-sm-6">
            <div className="counter-item">
              <h2 className="count main-slider-font-weight"><span className="odometer">500</span>+</h2>
              <p> Happy Customers</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6">
            <div className="counter-item">
              <h2 className="count main-slider-font-weight"><span className="odometer">100</span>+</h2>
              <p>Projects completed</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6">
            <div className="counter-item">
              <h2 className="count main-slider-font-weight"><span className="odometer">30</span>+</h2>
              <p>Skilled Team</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6">
            <div className="counter-item">
              <h2 className="count main-slider-font-weight"><span className="odometer">10</span>+</h2>
              <p>Best Advisors</p>
            </div>
          </div>
        </div>
      </div>
    </section>

  )
}

export default Counter;
