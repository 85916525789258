import React from 'react';
import ShopBreadcumb from '../components/ourshop/ShopBreadcumb'
import StudyTableArea from '../components/ourshop/StudyTableArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function StudyTablePages() {
	return (
		<>
			<Header />
			<main>
				<ShopBreadcumb />
				<StudyTableArea />
			</main>
			<Footer />
		</>
	)
}

export default StudyTablePages;
