import React from 'react';
import ShopBreadcumb from '../components/ourshop/ShopBreadcumb'
import SofasArea from '../components/ourshop/SofasArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function SofasPages(){
	return(
		<>
			<Header/>
			<main>
				<ShopBreadcumb/>
				<SofasArea/>
			</main>
			<Footer/>
		</>
	)
}

export default SofasPages;
