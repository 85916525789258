import React from 'react';
import { Link } from 'react-router-dom'

function DressingTableArea() {
  return (
    <div className="shop-area pt-110 pb-110">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-12">
            <div className="shop-wrap">
              <h4 className="title main-slider-font-weight">All Table</h4>
              <div className="row justify-content-start">
                <div className="col-lg-3 col-sm-6">
                  <div className="shop-item mb-55">
                    <div className="shop-thumb">
                      <Link to="/dressing-table-with-storage"><img src="img/product/dressing_table1b.png" alt="" /></Link>
                    </div>
                    <div className="shop-content">
                      <span>MAHESHWARI FURNITURE</span>
                      <h4 className="title main-slider-font-weight"><Link to="/dressing-table-with-storage">Dressing Table With Storage</Link></h4>
                      <div className="shop-content-bottom">
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="shop-item mb-55">
                    <div className="shop-thumb">
                      <Link to="/dressing-table-with-shelves-and-storage"><img src="img/product/dressing_table2b.png" alt="" /></Link>
                    </div>
                    <div className="shop-content">
                      <span>MAHESHWARI FURNITURE</span>
                      <h4 className="title main-slider-font-weight"><Link to="/dressing-table-with-shelves-and-storage">Dressing Table With Shelves and Storage</Link></h4>
                      <div className="shop-content-bottom">
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="shop-item mb-55">
                    <div className="shop-thumb">
                      <Link to="/wood-dressing-table-with-shelf"><img src="img/product/dressing_table3b.png" alt="" /></Link>
                    </div>
                    <div className="shop-content">
                      <span>MAHESHWARI FURNITURE</span>
                      <h4 className="title main-slider-font-weight"><Link to="/wood-dressing-table-with-shelf">Wood Dressing Table With Shelf</Link></h4>
                      <div className="shop-content-bottom">
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="shop-item mb-55">
                    <div className="shop-thumb">
                      <Link to="/wood-dressing-table"><img src="img/product/dressing_table4b.png" alt="" /></Link>
                    </div>
                    <div className="shop-content">
                      <span>MAHESHWARI FURNITURE</span>
                      <h4 className="title main-slider-font-weight"><Link to="/wood-dressing-table">Wood Dressing Table</Link></h4>
                      <div className="shop-content-bottom">
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="shop-item mb-55">
                    <div className="shop-thumb">
                      <Link to="/dressing-table-with-shelf"><img src="img/product/dressing_table5b.png" alt="" /></Link>
                    </div>
                    <div className="shop-content">
                      <span>MAHESHWARI FURNITURE</span>
                      <h4 className="title main-slider-font-weight"><Link to="/dressing-table-with-shelf">Dressing Table With Shelf</Link></h4>
                      <div className="shop-content-bottom">
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="shop-item mb-55">
                    <div className="shop-thumb">
                      <Link to="/dressing-table"><img src="img/product/dressing_table6b.png" alt="" /></Link>
                    </div>
                    <div className="shop-content">
                      <span>MAHESHWARI FURNITURE</span>
                      <h4 className="title main-slider-font-weight"><Link to="/dressing-table">Dressing Table</Link></h4>
                      <div className="shop-content-bottom">
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="shop-item mb-55">
                    <div className="shop-thumb">
                      <Link to="/dressing-table-with-stool-storage"><img src="img/product/dressing_table7b.png" alt="" /></Link>
                    </div>
                    <div className="shop-content">
                      <span>MAHESHWARI FURNITURE</span>
                      <h4 className="title main-slider-font-weight"><Link to="/dressing-table-with-stool-storage">Dressing Table With Stool Storage</Link></h4>
                      <div className="shop-content-bottom">
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="shop-item mb-55">
                    <div className="shop-thumb">
                      <Link to="/sheesham-dressing-table"><img src="img/product/dressing_table8b.png" alt="" /></Link>
                    </div>
                    <div className="shop-content">
                      <span>MAHESHWARI FURNITURE</span>
                      <h4 className="title main-slider-font-weight"><Link to="/sheesham-dressing-table">Sheesham Dressing Table</Link></h4>
                      <div className="shop-content-bottom">
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="shop-item mb-55">
                    <div className="shop-thumb">
                      <Link to="/dressing-table-with-drawers"><img src="img/product/dressing_table9b.png" alt="" /></Link>
                    </div>
                    <div className="shop-content">
                      <span>MAHESHWARI FURNITURE</span>
                      <h4 className="title main-slider-font-weight"><Link to="/dressing-table-with-drawers">Dressing Table With Drawers</Link></h4>
                      <div className="shop-content-bottom">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DressingTableArea;
