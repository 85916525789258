import React from 'react';
import ReactDOM from 'react-dom';
import $ from 'jquery'

import App from './App';


ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
  document.getElementById('root'),
  function(){
    $('#preloader').delay(0).fadeOut();
  }
);

