import React from 'react';
import WineBarUnitBreadcumb from '../components/ourshop/Kitchenshopdetails/WineBarUnitBreadcumb'
import WineBarUnitArea from '../components/ourshop/Kitchenshopdetails/WineBarUnitArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function WineBarUnitPages() {
    return (
        <>
            <Header />
            <main>
                <WineBarUnitBreadcumb />
                <WineBarUnitArea />
            </main>
            <Footer />
        </>
    )
}

export default WineBarUnitPages;