import React from 'react';
import StudyTablewithDrawerinery from '../components/ourshop/studytable/StudyTablewithDrawerinery'
import StudyTableCumShelfaraeyaraey from '../components/ourshop/studytable/StudyTableCumShelfaraeyaraey'
import Header from "../components/Header";
import Footer from "../components/Footer";

function StudyTablewithDrawer() {
	return (
		<>
			<Header />
			<main>
				<StudyTableCumShelfaraeyaraey />
				<StudyTablewithDrawerinery />
			</main>
			<Footer />
		</>
	)
}

export default StudyTablewithDrawer;
