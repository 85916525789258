import React from 'react';
import WallMountedBarCabinetBreadcumb from '../components/ourshop/Kitchenshopdetails/WallMountedBarCabinetBreadcumb'
import WallMountedBarCabinetArea from '../components/ourshop/Kitchenshopdetails/WallMountedBarCabinetArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function WallMountedBarCabinetPages() {
    return (
        <>
            <Header />
            <main>
                <WallMountedBarCabinetBreadcumb />
                <WallMountedBarCabinetArea />
            </main>
            <Footer />
        </>
    )
}

export default WallMountedBarCabinetPages;