import React from 'react';
import Slider from 'react-slick'
import { Link } from 'react-router-dom'

function PrevArrow(props) {
  const { className, onClick } = props;
  return (
    <button type='button' className={className} onClick={onClick}><img src="img/icon/arrow2.png" alt='prev' /></button>
  );
}
function NextArrow(props) {
  const { className, onClick } = props;
  return (
    <button type='button' className={className} onClick={onClick}><img src="img/icon/arrow2.png" alt='next' /></button>
  );
}

function AdoptionPuppies() {
  const settings = {

    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: false,
    arrows: true,
    autoplaySpeed: 3000,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          speed: 1000,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
          speed: 1000,
        }
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          speed: 1000,
        }
      },
    ]
  }
  return (

    <section className="adoption-area-two pt-110 pb-110 adoption-area-two-padding">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-7 col-lg-9">
            <div className="section-title text-center mb-65">
              <div className="section-icon"><img src="img/icon/icon.png" alt="" /></div>
              <h2 className="sub-title main-slider-font-weight">Crafting Quality</h2>
              <h2 className="title font-size main-slider-font-weight">Our Journey to Your Home</h2>
              <p className="text-justify">At Maheshwari Furniture, we believe furniture is more than just an object, it's an embodiment of your style and story. We are passionate about crafting pieces that seamlessly integrate into your space, reflecting your unique personality.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="container custom-container">
        <Slider className="row adopt-active" {...settings}>
          <div className="col-xl">
            <div className="adoption-item">
              <div className="adopt-thumb" aria-hidden="true">
                <img src="img/images/step1.png" alt="" />
                <span className="status">1</span>
              </div>
              <div className="adopt-content">
                <h3 className="title main-slider-font-weight"><Link to="/breeder-details" tabIndex="-1">Collaborative Design</Link></h3>
                <p className="text-justify">We begin by collaborating with you to understand your needs, preferences, and lifestyle. Our skilled designers collaborate with you to translate your vision into initial sketches and material samples.</p>
              </div>
            </div>
          </div>
          <div className="col-xl">
            <div className="adoption-item">
              <div className="adopt-thumb" aria-hidden="true">
                <img src="img/images/step2.png" alt="" />
                <span className="status">2</span>
              </div>
              <div className="adopt-content">
                <h3 className="title main-slider-font-weight"><Link to="/breeder-details" tabIndex="-1">Sustainable Sourcing</Link></h3>
                <p className="text-justify">We prioritize using ethically sourced materials, ensuring our furniture reflects our values. We source materials from trusted partners, maintaining complete transparency about their origin and composition. </p>
              </div>
            </div>
          </div>
          <div className="col-xl">
            <div className="adoption-item">
              <div className="adopt-thumb" aria-hidden="true">
                <img src="img/images/step3.png" alt="" />
                <span className="status">3</span>
              </div>
              <div className="adopt-content">
                <h3 className="title main-slider-font-weight"><Link to="/breeder-details" tabIndex="-1">Expert Craftsmanship</Link></h3>
                <p className="text-justify">We seamlessly blend traditional methods with modern tools and technology, ensuring precision engineering and efficient production while preserving the unique character of each piece.</p>
              </div>
            </div>
          </div>
          <div className="col-xl">
            <div className="adoption-item">
              <div className="adopt-thumb" aria-hidden="true">
                <img src="img/images/step4.png" alt="" />
                <span className="status">4</span>
              </div>
              <div className="adopt-content">
                <h3 className="title main-slider-font-weight"><Link to="/breeder-details" tabIndex="-1"> Quality Control</Link></h3>
                <p className="text-justify">Every piece undergoes rigorous inspections throughout the production process, guaranteeing its structural integrity and flawless finish. So to ensure your furniture meets the highest quality standards.</p>
              </div>
            </div>
          </div>
          <div className="col-xl">
            <div className="adoption-item">
              <div className="adopt-thumb" aria-hidden="true">
                <img src="img/images/step5.png" alt="" />
                <span className="status">5</span>
              </div>
              <div className="adopt-content">
                <h3 className="title main-slider-font-weight"><Link to="/breeder-details" tabIndex="-1">Safe & Secure Delivery</Link></h3>
                <p className="text-justify">We carefully package your furniture using sustainable and protective materials, ensuring it arrives safely within the promised timeframe and in pristine condition at your doorstep.</p>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </section>
  )
}

export default AdoptionPuppies;
