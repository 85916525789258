import React from 'react';
import WallHangingDivineCraftsBreadcumb from '../components/ourshop/Mandirshopdetails/WallHangingDivineCraftsBreadcumb'
import WallHangingDivineCraftsArea from '../components/ourshop/Mandirshopdetails/WallHangingDivineCraftsArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function WallHangingDivineCraftsPages() {
    return (
        <>
            <Header />
            <main>
                <WallHangingDivineCraftsBreadcumb />
                <WallHangingDivineCraftsArea />
            </main>
            <Footer />
        </>
    )
}

export default WallHangingDivineCraftsPages; 