import React from 'react';
import WoodenCornerShelfshopdetiple from '../components/ourshop/cornershelfs/WoodenCornerShelfshopdetiple'
import WoodenCornerShelfshop from '../components/ourshop/cornershelfs/WoodenCornerShelfshop'
import Header from "../components/Header";
import Footer from "../components/Footer";

function WoodenCornerShelf() {
	return (
		<>
			<Header />
			<main>
			<WoodenCornerShelfshopdetiple />
				<WoodenCornerShelfshop />
			</main>
			<Footer />
		</>
	)
}

export default WoodenCornerShelf;
