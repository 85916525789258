import React from 'react';
import { Link } from 'react-router-dom'

function KitchenArea() {
  return (
    <div className="shop-area pt-110 pb-110">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-12">
            <div className="shop-wrap">
              <h4 className="title main-slider-font-weight">All Kitchen</h4>
              <div className="row justify-content-start">
                <div className="col-lg-3 col-sm-6">
                  <div className="shop-item mb-55">
                    <div className="shop-thumb">
                      <Link to="/sliding-wardrobe"><img src="img/product/kitchen1b.png" alt="" /></Link>
                    </div>
                    <div className="shop-content">
                      <span>MAHESHWARI FURNITURE</span>
                      <h4 className="title main-slider-font-weight"><Link to="/sliding-wardrobe">Sliding Wardrobe</Link></h4>
                      <div className="shop-content-bottom">
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="shop-item mb-55">
                    <div className="shop-thumb">
                      <Link to="/straight-kitchen-design"><img src="img/product/kitchen2b.png" alt="" /></Link>
                    </div>
                    <div className="shop-content">
                      <span>MAHESHWARI FURNITURE</span>
                      <h4 className="title main-slider-font-weight"><Link to="/straight-kitchen-design">Straight Kitchen Design</Link></h4>
                      <div className="shop-content-bottom">
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="shop-item mb-55">
                    <div className="shop-thumb">
                      <Link to="/bar-cabinet"><img src="img/product/kitchen3b.png" alt="" /></Link>
                    </div>
                    <div className="shop-content">
                      <span>MAHESHWARI FURNITURE</span>
                      <h4 className="title main-slider-font-weight"><Link to="/bar-cabinet">Bar Cabinet</Link></h4>
                      <div className="shop-content-bottom">
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="shop-item mb-55">
                    <div className="shop-thumb">
                      <Link to="/wall-mounted-bar-cabinet"><img src="img/product/kitchen4b.png" alt="" /></Link>
                    </div>
                    <div className="shop-content">
                      <span>MAHESHWARI FURNITURE</span>
                      <h4 className="title main-slider-font-weight"><Link to="/wall-mounted-bar-cabinet">Wall Mounted Bar Cabinet</Link></h4>
                      <div className="shop-content-bottom">
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="shop-item mb-55">
                    <div className="shop-thumb">
                      <Link to="/wine-bar-unit"><img src="img/product/kitchen5b.png" alt="" /></Link>
                    </div>
                    <div className="shop-content">
                      <span>MAHESHWARI FURNITURE</span>
                      <h4 className="title main-slider-font-weight"><Link to="/wine-bar-unit">Wine Bar Unit</Link></h4>
                      <div className="shop-content-bottom">
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="shop-item mb-55">
                    <div className="shop-thumb">
                      <Link to="/simple-kitchen-design"><img src="img/product/kitchen6b.png" alt="" /></Link>
                    </div>
                    <div className="shop-content">
                      <span>MAHESHWARI FURNITURE</span>
                      <h4 className="title main-slider-font-weight"><Link to="/simple-kitchen-design">Simple Kitchen Design</Link></h4>
                      <div className="shop-content-bottom">
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="shop-item mb-55">
                    <div className="shop-thumb">
                      <Link to="/island-kitchen-design"><img src="img/product/kitchen7b.png" alt="" /></Link>
                    </div>
                    <div className="shop-content">
                      <span>MAHESHWARI FURNITURE</span>
                      <h4 className="title main-slider-font-weight"><Link to="/island-kitchen-design">Island Kitchen Design</Link></h4>
                      <div className="shop-content-bottom">
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="shop-item mb-55">
                    <div className="shop-thumb">
                      <Link to="/l-shaped-kitchen-design"><img src="img/product/kitchen8b.png" alt="" /></Link>
                    </div>
                    <div className="shop-content">
                      <span>MAHESHWARI FURNITURE</span>
                      <h4 className="title main-slider-font-weight"><Link to="/l-shaped-kitchen-design">L-Shaped Kitchen</Link></h4>
                      <div className="shop-content-bottom">
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="shop-item mb-55">
                    <div className="shop-thumb">
                      <Link to="/modular-kitchen-design"><img src="img/product/kitchen9b.png" alt="" /></Link>
                    </div>
                    <div className="shop-content">
                      <span>MAHESHWARI FURNITURE</span>
                      <h4 className="title main-slider-font-weight"><Link to="/modular-kitchen-design">Modular Kitchen Design</Link></h4>
                      <div className="shop-content-bottom">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default KitchenArea;
