import React from 'react';
import SixSeaterDiningSetDetailsBreadcumb from '../components/ourshop/Diningshopdetails/SixSeaterDiningSetDetailsBreadcumb'
import SixSeaterDiningSetDetailsArea from '../components/ourshop/Diningshopdetails/SixSeaterDiningSetDetailsArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function SixSeaterDiningSetDetailsPage() {
    return (
        <>
            <Header />
            <main>
                <SixSeaterDiningSetDetailsBreadcumb />
                <SixSeaterDiningSetDetailsArea />
            </main>
            <Footer />
        </>
    )
}

export default SixSeaterDiningSetDetailsPage;