import React from 'react';
import HighBackOfficeChairBreadcumb from '../components/ourshop/OfficeChairshopdetails/HighBackOfficeChairBreadcumb'
import HighBackOfficeChairArea from '../components/ourshop/OfficeChairshopdetails/HighBackOfficeChairArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function HighBackOfficeChairPages() {
    return (
        <>
            <Header />
            <main>
                <HighBackOfficeChairBreadcumb />
                <HighBackOfficeChairArea />
            </main>
            <Footer />
        </>
    )
}

export default HighBackOfficeChairPages; 