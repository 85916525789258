import React from 'react';
import WoodDressingTableWithShelfDetailsArea from '../components/ourshop/Dressingtable/WoodDressingTableWithShelfDetailsArea'
import WoodDressingTableWithShelfArea from '../components/ourshop/Dressingtable/WoodDressingTableWithShelfArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function WoodDressingTableWithShelf() {
	return (
		<>
			<Header />
			<main>
				<WoodDressingTableWithShelfDetailsArea />
				<WoodDressingTableWithShelfArea />
			</main>
			<Footer />
		</>
	)
}

export default WoodDressingTableWithShelf;
