import React from 'react';
import ThreeDoorMultiUtilityWardrobeHero from '../components/ourshop/wardrobe/ThreeDoorMultiUtilityWardrobeHero'
import ThreeDoorMultiUtilityWardrobeHome from '../components/ourshop/wardrobe/ThreeDoorMultiUtilityWardrobeHome'
import Header from "../components/Header";
import Footer from "../components/Footer";

function ThreeDoorMultiUtilityWardrobe() {
	return (
		<>
			<Header />
			<main>
				<ThreeDoorMultiUtilityWardrobeHome />
				<ThreeDoorMultiUtilityWardrobeHero />
			</main>
			<Footer />
		</>
	)
}

export default ThreeDoorMultiUtilityWardrobe;
