import React from 'react';
import { Link } from 'react-router-dom'

function WoodenCanningArea() {
  return (
    <div className="shop-area pt-110 pb-110">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-12">
            <div className="shop-wrap">
              <h4 className="title main-slider-font-weight">All Wooden Cane</h4>
              <div className="row justify-content-start">
                <div className="col-lg-3 col-sm-6">
                  <div className="shop-item mb-55">
                    <div className="shop-thumb">
                      <Link to="/seesham-cane-vintage-bed"><img src="img/woodencanningimage/bed_a.png" alt="" /></Link>
                    </div>
                    <div className="shop-content">
                      <span>MAHESHWARI FURNITURE</span>
                      <h4 className="title main-slider-font-weight"><Link to="/seesham-cane-vintage-bed">Seesham Cane Vintage Bed</Link></h4>
                      <div className="shop-content-bottom">
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="shop-item mb-55">
                    <div className="shop-thumb">
                      <Link to="/center-table-with-storage"><img src="img/woodencanningimage/centre_table_a.png" alt="" /></Link>
                    </div>
                    <div className="shop-content">
                      <span>MAHESHWARI FURNITURE</span>
                      <h4 className="title main-slider-font-weight"><Link to="/center-table-with-storage">Center Table With Storage</Link></h4>
                      <div className="shop-content-bottom">
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="shop-item mb-55">
                    <div className="shop-thumb">
                      <Link to="/cane-handwork-cabinet"><img src="img/woodencanningimage/dressing_table_a.png" alt="" /></Link>
                    </div>
                    <div className="shop-content">
                      <span>MAHESHWARI FURNITURE</span>
                      <h4 className="title main-slider-font-weight"><Link to="/cane-handwork-cabinet">Cane Handwork Cabinet</Link></h4>
                      <div className="shop-content-bottom">
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="shop-item mb-55">
                    <div className="shop-thumb">
                      <Link to="/wooden-podium-table"><img src="img/woodencanningimage/podium_a.png" alt="" /></Link>
                    </div>
                    <div className="shop-content">
                      <span>MAHESHWARI FURNITURE</span>
                      <h4 className="title main-slider-font-weight"><Link to="/wooden-podium-table">Wooden Podium Table</Link></h4>
                      <div className="shop-content-bottom">
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="shop-item mb-55">
                    <div className="shop-thumb">
                      <Link to="/everyday-stool"><img src="img/woodencanningimage/canin_stool_a.png" alt="" /></Link>
                    </div>
                    <div className="shop-content">
                      <span>MAHESHWARI FURNITURE</span>
                      <h4 className="title main-slider-font-weight"><Link to="/everyday-stool">Everyday Stool</Link></h4>
                      <div className="shop-content-bottom">
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="shop-item mb-55">
                    <div className="shop-thumb">
                      <Link to="/homeage-wooden-chair"><img src="img/woodencanningimage/chair_a.png" alt="" /></Link>
                    </div>
                    <div className="shop-content">
                      <span>MAHESHWARI FURNITURE</span>
                      <h4 className="title main-slider-font-weight"><Link to="/homeage-wooden-chair">Homeage Wooden Chair</Link></h4>
                      <div className="shop-content-bottom">
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="shop-item mb-55">
                    <div className="shop-thumb">
                      <Link to="/cane-wooden-chair-set"><img src="img/woodencanningimage/table_set_a.png" alt="" /></Link>
                    </div>
                    <div className="shop-content">
                      <span>MAHESHWARI FURNITURE</span>
                      <h4 className="title main-slider-font-weight"><Link to="/cane-wooden-chair-set">Cane Wooden Chair Set</Link></h4>
                      <div className="shop-content-bottom">
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="shop-item mb-55">
                    <div className="shop-thumb">
                      <Link to="/wooden-shoe-rack"><img src="img/woodencanningimage/wardrob_a.png" alt="" /></Link>
                    </div>
                    <div className="shop-content">
                      <span>MAHESHWARI FURNITURE</span>
                      <h4 className="title main-slider-font-weight"><Link to="/wooden-shoe-rack">Wooden Shoe Rack</Link></h4>
                      <div className="shop-content-bottom">
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="shop-item mb-55">
                    <div className="shop-thumb">
                      <Link to="/six-seater-dining-sets"><img src="img/woodencanningimage/dining_a.png" alt="" /></Link>
                    </div>
                    <div className="shop-content">
                      <span>MAHESHWARI FURNITURE</span>
                      <h4 className="title main-slider-font-weight"><Link to="/six-seater-dining-sets">Six Seater Dining Set</Link></h4>
                      <div className="shop-content-bottom">
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="shop-item mb-55">
                    <div className="shop-thumb">
                      <Link to="/everyday-three-seater-bench"><img src="img/woodencanningimage/seating_table_a.png" alt="" /></Link>
                    </div>
                    <div className="shop-content">
                      <span>MAHESHWARI FURNITURE</span>
                      <h4 className="title main-slider-font-weight"><Link to="/everyday-three-seater-bench">Everyday Three Seater Bench</Link></h4>
                      <div className="shop-content-bottom">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WoodenCanningArea;
