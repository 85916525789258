import React from 'react';
import DiningChairWithoutFabricDetailsArea from '../components/ourshop/Diningchair/DiningChairWithoutFabricDetailsArea'
import DiningChairWithoutFabricArea from '../components/ourshop/Diningchair/DiningChairWithoutFabricArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function DiningChairWithoutFabric() {
	return (
		<>
			<Header />
			<main>
				<DiningChairWithoutFabricDetailsArea />
				<DiningChairWithoutFabricArea />
			</main>
			<Footer />
		</>
	)
}

export default DiningChairWithoutFabric;
