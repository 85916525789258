import React from 'react';
import SofaCumBedHero from '../components/ourshop/sheeshamsofa/SofaCumBedHero'
import SofaCumBedHome from '../components/ourshop/sheeshamsofa/SofaCumBedHome'
import Header from "../components/Header";
import Footer from "../components/Footer";

function SofaCumBed() {
	return (
		<>
			<Header />
			<main>
				<SofaCumBedHome />
				<SofaCumBedHero />
			</main>
			<Footer />
		</>
	)
}

export default SofaCumBed;
