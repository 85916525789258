import React from 'react';
import LShapedSofaDetailsBreadcumb from '../components/ourshop/Sofashopdetails/LShapedSofaDetailsBreadcumb'
import LShapedSofaDetailsArea from '../components/ourshop/Sofashopdetails/LShapedSofaDetailsArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function LShapedSofaDetailsPage() {
    return (
        <>
            <Header />
            <main>
                <LShapedSofaDetailsBreadcumb />
                <LShapedSofaDetailsArea />
            </main>
            <Footer />
        </>
    )
}

export default LShapedSofaDetailsPage;