import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'
// import $ from 'jquery'



function ThreeDoorWardrobeHero() {


  // const [nav1, setnav1] = useState(null);
  // const [nav2,setNav2] = useState(null);
  const [val, setVal] = useState(1);
  const increase = () => {
    setVal(val + 1);
  }
  const decrease = () => {
    setVal(val - 1);
  }

  const handleActive = (e) => {
    e.preventDefault();

    document.querySelectorAll('.shop-details-dimension ul li').forEach(el => {
      el.classList.remove('active');
    })
    e.target.parentNode.classList = 'active';
  }
  const colorActive = (e) => {
    e.preventDefault();

    document.querySelectorAll('.shop-details-color ul li').forEach(el => {
      el.classList.remove('active');
    })
    e.target.classList += ' active';
  }
  const settings = {

    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: false,
    arrows: false,
    autoplaySpeed: 3000,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          speed: 1000,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
          speed: 1000,
        }
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          speed: 1000,
        }
      },
    ]
  }

  return (

    <section className="shop-details-area pt-110 pb-50">
      <div className="container">
        <div className="shop-details-wrap">
          <div className="row">
            <div className="col-7">
              <div className="shop-details-img-wrap">
                <div className="tab-content" id="myTabContent">
                  <div className="tab-pane show active" id="item-one" role="tabpanel" aria-labelledby="item-one-tab">
                    <div className="shop-details-img">
                    <img src="img/product/wardrobe1a.png" alt="" />
                    </div>
                  </div>
                  <div className="tab-pane" id="item-two" role="tabpanel" aria-labelledby="item-two-tab">
                    <div className="shop-details-img">
                      <img src="img/product/shop_details02.jpg" alt="" />
                    </div>
                  </div>
                  <div className="tab-pane" id="item-three" role="tabpanel" aria-labelledby="item-three-tab">
                    <div className="shop-details-img">
                      <img src="img/product/shop_details03.jpg" alt="" />
                    </div>
                  </div>
                  <div className="tab-pane" id="item-four" role="tabpanel" aria-labelledby="item-four-tab">
                    <div className="shop-details-img">
                      <img src="img/product/shop_details04.jpg" alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="shop-details-nav-wrap">
              </div>
            </div>
            <div className="col-5">
              <div className="shop-details-content">
                <h2 className="title main-slider-font-weight">3 Door Wardrobe</h2>
                <div className="shop-details-price">
                  <h5 className="stock-status main-slider-font-weight">- IN Stock</h5>
                </div>
                <p>The domestic dog is a doiated dendant of the wolf. The dog derived from an ancient, extinct wolf, and the modern grey wolf is the dog's nearest living relative.</p>
                <div className="shop-details-bottom">
                  <ul>
                    <li className="sd-share">
                      <span className="title main-slider-font-weight">Explore more :</span>
                      <a href="https://www.facebook.com/maheshwarifurniture"><i className="fab fa-facebook-f" /></a>
                      <a href="https://www.facebook.com/Maheshwari2019?mibextid=qWsEUC"><i className=" fas fa-flag" /></a>
                      <a href="https://www.instagram.com/maheshwarifurniture.online"><i className="fab fa-instagram" /></a>
                      <a href="https://www.youtube.com/@maheshwarifurniture"><i className="fab fa-youtube" /></a>
                      <a href="https://wa.me/919414045241"><i className="fab fa-whatsapp" /></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="related-products-wrap">
          <h2 className="title main-slider-font-weight">Related Products</h2>
          <Slider className="row related-product-active"{...settings}>
            <div className="col-lg">
              <div className="shop-item mb-55">
                <div className="shop-thumb">
                  <Link to="/3-door-multi-utility-wardrobe"><img src="img/product/wardrobe1.jpg" alt="" /></Link>
                </div>
                <div className="shop-content">
                  <span>MAHESHWARI FURNITURE</span>
                  <h4 className="title main-slider-font-weight"><Link to="/3-door-multi-utility-wardrobe">3 Door Multi Utility Wardrobe</Link></h4>
                  <div className="shop-content-bottom">
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg">
              <div className="shop-item mb-55">
                <div className="shop-thumb">
                  <Link to="/2-door-wardrobe-with-mirror"><img src="img/product/wardrobe2.jpg" alt="" /></Link>
                </div>
                <div className="shop-content">
                  <span>MAHESHWARI FURNITURE</span>
                  <h4 className="title main-slider-font-weight"><Link to="/2-door-wardrobe-with-mirror">2 Door Wardrobe with Mirror</Link></h4>
                  <div className="shop-content-bottom">
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg">
              <div className="shop-item mb-55">
                <div className="shop-thumb">
                  <Link to="/1-door-wardrobe"><img src="img/product/wardrobe3.jpg" alt="" /></Link>
                </div>
                <div className="shop-content">
                  <span>MAHESHWARI FURNITURE</span>
                  <h4 className="title main-slider-font-weight"><Link to="/1-door-wardrobe">1 Door Wardrobe</Link></h4>
                  <div className="shop-content-bottom">
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg">
              <div className="shop-item mb-55">
                <div className="shop-thumb">
                  <Link to="/boho-small-size-wardrobe"><img src="img/product/wardrobe4.jpg" alt="" /></Link>
                </div>
                <div className="shop-content">
                  <span>MAHESHWARI FURNITURE</span>
                  <h4 className="title main-slider-font-weight"><Link to="/boho-small-size-wardrobe">Boho Small Size Wardrobe</Link></h4>
                  <div className="shop-content-bottom">
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg">
              <div className="shop-item mb-55">
                <div className="shop-thumb">
                  <Link to="/square-table-with-4-stools"><img src="img/product/wardrobe6.jpg" alt="" /></Link>
                </div>
                <div className="shop-content">
                  <span>MAHESHWARI FURNITURE</span>
                  <h4 className="title main-slider-font-weight"><Link to="/square-table-with-4-stools">3 Door Multi Utility Wardrobe</Link></h4>
                  <div className="shop-content-bottom">
                  </div>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </section>
  )
}
export default ThreeDoorWardrobeHero;
