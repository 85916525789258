import React from 'react';
import OliveGreenSofaDetailsBreadcumb from '../components/ourshop/Sofashopdetails/OliveGreenSofaDetailsBreadcumb'
import OliveGreenSofaDetailsArea from '../components/ourshop/Sofashopdetails/OliveGreenSofaDetailsArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function OliveGreenSofaDetailsPage() {
    return (
        <>
            <Header />
            <main>
                <OliveGreenSofaDetailsBreadcumb />
                <OliveGreenSofaDetailsArea />
            </main>
            <Footer />
        </>
    )
}

export default OliveGreenSofaDetailsPage;