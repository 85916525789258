import React from 'react';
import OfficeTableBreadcumb from '../components/ourshop/OfficeCountershopdetails/OfficeTableBreadcumb'
import OfficeTableArea from '../components/ourshop/OfficeCountershopdetails/OfficeTableArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function OfficeTablePages() {
    return (
        <>
            <Header />
            <main>
                <OfficeTableBreadcumb />
                <OfficeTableArea />
            </main>
            <Footer />
        </>
    )
}

export default OfficeTablePages; 