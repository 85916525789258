import React from 'react';
import FullyUpholsteredBedDetailsBreadcumb from '../components/ourshop/Bedshopdetails/FullyUpholsteredBedDetailsBreadcumb'
import FullyUpholsteredBedDetailsArea from '../components/ourshop/Bedshopdetails/FullyUpholsteredBedDetailsArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function FullyUpholsteredBedDetailsPage() {
    return (
        <>
            <Header />
            <main>
                <FullyUpholsteredBedDetailsBreadcumb />
                <FullyUpholsteredBedDetailsArea />
            </main>
            <Footer />
        </>
    )
}

export default FullyUpholsteredBedDetailsPage; 