import React from 'react';
import BedSideTableBreadcumb from '../components/ourshop/SideTableshopdetails/BedSideTableBreadcumb'
import BedSideTableArea from '../components/ourshop/SideTableshopdetails/BedSideTableArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function BedSideTablePages() {
    return (
        <>
            <Header />
            <main>
                <BedSideTableBreadcumb />
                <BedSideTableArea />
            </main>
            <Footer />
        </>
    )
}

export default BedSideTablePages;