import React from 'react';
import StudyTablewithBookShelfinery from '../components/ourshop/studytable/StudyTablewithBookShelfinery'
import StudyTablewithBookShelfaraey from '../components/ourshop/studytable/StudyTablewithBookShelfaraey'
import Header from "../components/Header";
import Footer from "../components/Footer";

function StudyTablewithBookShelf() {
	return (
		<>
			<Header />
			<main>
				<StudyTablewithBookShelfaraey />
				<StudyTablewithBookShelfinery />
			</main>
			<Footer />
		</>
	)
}

export default StudyTablewithBookShelf;
