import React from 'react';
import PrintedWoodenStoolDetailsArea from '../components/ourshop/Puffy/PrintedWoodenStoolDetailsArea'
import PrintedWoodenStoolArea from '../components/ourshop/Puffy/PrintedWoodenStoolArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function PrintedWoodenStool() {
	return (
		<>
			<Header />
			<main>
				<PrintedWoodenStoolDetailsArea />
				<PrintedWoodenStoolArea/>
			</main>
			<Footer />
		</>
	)
}

export default PrintedWoodenStool;
