import React from 'react';
import ShopBreadcumb from '../components/ourshop/ShopBreadcumb'
import CenterTableArea from '../components/ourshop/CenterTableArea'
import Header from "../components/Header";
import Footer from "../components/Footer";

function CenterTablePages() {
	return (
		<>
			<Header />
			<main>
				<ShopBreadcumb />
				<CenterTableArea />
			</main>
			<Footer />
		</>
	)
}

export default CenterTablePages;
